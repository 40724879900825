import axios from 'axios';

const api = axios.create({
    baseURL: 'https://forecast-rxjbzjhxpa-rj.a.run.app/'
});

export const localAPI = axios.create({
    baseURL: 'http://127.0.0.1:8080/'
});

export const createStudy = (studyForm, user_id, completion, errorOccurred) => {
    let formData = new FormData();
    formData.append("owner", user_id);
    formData.append("name", studyForm.name);
    formData.append("description", studyForm.description);
    formData.append("tags", studyForm.tags);
    api.post('user/create_study/', formData).then(response => {
        completion(response.data);
    }).catch(error => {
        errorOccurred(error.name);
    });
}

export const seriesCSVtoJSON = (files, user_id, study_id, completion, errorOccurred) => {
    let formData = new FormData();
    formData.append("owner", user_id);
    formData.append("type", "NA");
    formData.append("study_id", study_id);
    files.forEach((file, i) => {
        formData.append(`file${i}`, file);
    })
    api.post('upload/', formData).then(response => {
        completion(response.data);
    }).catch(error => {
        errorOccurred(error.name);
    });
}

export const getSeriesObject = (series_id, completion, errorOccurred) => {
    let formData = new FormData();
    formData.append("series_id", series_id);
    api.post('user/get_series/', formData).then(response => {
        completion(response.data);
    }).catch(error => {
        errorOccurred(error.name);
    });
}

export const runAnalysis = (data, completion, errorOccurred) => {
    let formData = new FormData();
    // console.log(data)
    // Object.keys(data).forEach(key => {
    //     formData.append(key, data[key]);
    // })
    
    api.post('forecast/', data).then(response => {
        completion(response.data);
    }).catch(error => {
        errorOccurred(error.name);
    });
}

export default api;