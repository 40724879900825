import React, { useState, useEffect } from 'react';
import { CompleteSeriesPlot, 
    CompleteSeriesPlotDates,
    CompleteSeriesAggregatedYearlyPlot,
    CompleteSeriesAggregatedMonthlyPlot,
    CompleteSeriesAggregatedDailyPlot,
    CompleteSeriesAggregatedHourlyPlot,
    CompleteSeriesMeanMinMaxDailyPlot,
    CompleteSeriesMeanMinMaxHourlyPlot } from 'components/Plots/CompleteSeriesPlot';
import { YearlySeasonalitySeriesPlot, 
    YearlySeasonalityAggregatedMonthlyPlot,
    YearlySeasonalityMeanMinMaxMonthlyPlot,
    YearlySeasonalityAggregatedDailyPlot,
    YearlySeasonalityMeanMinMaxDailyPlot,
    YearlySeasonalityAggregatedHourlyPlot } from 'components/Plots/YearlySeriesPlot';
import { MonthlySeasonalitySeriesPlot,
    MonthlySeasonalityAggregatedDailyPlot,
    MonthlySeasonalityAggregatedHourlyPlot } from 'components/Plots/MonthlySeriesPlot';
import { WeeklySeasonalitySeriesPlot,
    WeeklySeasonalityAggregatedDailyPlot,
    WeeklySeasonalityAggregatedHourlyPlot } from 'components/Plots/WeeklySeriesPlot';
import { DailySeasonalitySeriesPlot,
    DailySeasonalityAggregatedHourlyPlot } from 'components/Plots/DailySeriesPlot';
import { useHistory } from 'react-router-dom';
import GraphSelector from 'components/UI/GraphSelector';
import Button from 'components/UI/Button';
import Card from 'components/UI/Card';
import * as actionTypes from 'store/actions';
import * as moment from 'moment';
import { connect } from 'react-redux';
import seriesJSON from 'assets/examples/serie_diaria.json';
import { getSeriesObject } from 'apis/api';
import { series_as_dict } from 'components/Plots/EChartsHelper'
import { toast } from 'react-toastify';

const SeriesIndividualVisualization = (props) => {
    const [dates, setDates] = useState(null);
    const [originalSeries, setOriginalSeries] = useState(null);
    const [selectedSeries, setSelectedSeries] = useState(null);
    const [selectedGraph, setSelectedGraph] = useState(null);
    const [selectedAggregation, setSelectedAggregation] = useState(null);
    const [selectedAggregationType, setSelectedAggregationType] = useState(null);
    const [showVisualization, setShowVisualization] = useState(false);
    const [fixedGraph, setFixedGraph] = useState(null);

    useEffect(() => {
        const errorOccurred = (message) => {
            const options = {
                autoClose: false,
                hideProgressBar: false,
                position: toast.POSITION.TOP_RIGHT,
            };
            toast.error(message, options);
        }
        const completion = (data) => {
            const series_dict = series_as_dict(props.seriesMeta.name, data.series.timestamps, data.series.values);
            setSelectedSeries(series_dict);
            setOriginalSeries(series_dict);
        }
        getSeriesObject(props.seriesMeta.series_id, completion, errorOccurred);
    }, [])

    const cropSelectedSeries = (startDate, endDate) => {
        const croppedSeries = originalSeries.series.filter(point => {
            const ts = moment(point["timestamp"]);
            return startDate <= ts && ts <= endDate;
        });
        setDates([startDate.valueOf(), endDate.valueOf()]);
        setSelectedGraph(null);
        setSelectedAggregation(null);
        setSelectedAggregationType(null);
        setSelectedSeries({...selectedSeries, series: croppedSeries});
    }

    const granularity = props.seriesMeta.granularity;
    const graphs = selectedSeries ? [
        <CompleteSeriesPlot series={selectedSeries.series} chartsAux={granularity} />,
        <CompleteSeriesAggregatedHourlyPlot series={selectedSeries.series} chartsAux={granularity} />,
        <CompleteSeriesMeanMinMaxHourlyPlot series={selectedSeries.series} chartsAux={granularity} />,
        <CompleteSeriesAggregatedDailyPlot series={selectedSeries.series} chartsAux={granularity} />,
        <CompleteSeriesMeanMinMaxDailyPlot series={selectedSeries.series} chartsAux={granularity} />,
        <CompleteSeriesAggregatedMonthlyPlot series={selectedSeries.series} chartsAux={granularity} />,
        <CompleteSeriesAggregatedYearlyPlot series={selectedSeries.series} chartsAux={granularity} />,        
        <YearlySeasonalitySeriesPlot series={selectedSeries.series} chartsAux={granularity} />,
        <YearlySeasonalityAggregatedHourlyPlot series={selectedSeries.series} chartsAux={granularity} />,
        <YearlySeasonalityAggregatedDailyPlot series={selectedSeries.series} chartsAux={granularity} />,
        <YearlySeasonalityMeanMinMaxDailyPlot series={selectedSeries.series} chartsAux={granularity} />,
        <YearlySeasonalityAggregatedMonthlyPlot series={selectedSeries.series} chartsAux={granularity} />,
        <YearlySeasonalityMeanMinMaxMonthlyPlot series={selectedSeries.series} chartsAux={granularity} />,
        <MonthlySeasonalitySeriesPlot series={selectedSeries.series} chartsAux={granularity} />,
        <MonthlySeasonalityAggregatedHourlyPlot series={selectedSeries.series} chartsAux={granularity} />,
        <MonthlySeasonalityAggregatedDailyPlot series={selectedSeries.series} chartsAux={granularity} />,
        <WeeklySeasonalitySeriesPlot series={selectedSeries.series} chartsAux={granularity} />,
        <WeeklySeasonalityAggregatedHourlyPlot series={selectedSeries.series} chartsAux={granularity} />,
        <WeeklySeasonalityAggregatedDailyPlot series={selectedSeries.series} chartsAux={granularity} />,
        <DailySeasonalitySeriesPlot series={selectedSeries.series} chartsAux={granularity} />,
        <DailySeasonalityAggregatedHourlyPlot series={selectedSeries.series} chartsAux={granularity} />
    ] : null; 

    return (
        <>
        {
            originalSeries && originalSeries.series && selectedSeries && selectedSeries.series ?
            <div>
                <CompleteSeriesPlotDates 
                    series={originalSeries.series} 
                    title={`Visualização da série ${selectedSeries.name}`}
                    dates={dates}
                    selectDateHandle={cropSelectedSeries} />
                <Button type="button" style={{marginLeft: 20}} onClick={() => setShowVisualization(!showVisualization) }>Mais opções de visualização</Button>
            </div> : null
        }
        {
            showVisualization && selectedSeries && selectedSeries.series ? 
            <GraphSelector
                chartsAux={granularity}
                selectedGraph={selectedGraph}
                setSelectedGraph={setSelectedGraph}
                selectedAggregation={selectedAggregation}
                setSelectedAggregation={setSelectedAggregation}
                selectedAggregationType={selectedAggregationType}
                setSelectedAggregationType={setSelectedAggregationType}
                setFixedGraph={setFixedGraph}
                graphs={graphs} 
            /> : null
        }
        {
            fixedGraph ? 
            <div style={{marginTop: 20}}>
                <span className="x-button" 
                        style={{float: "right"}}
                        onClick={() => {
                            setFixedGraph(null);
                        }}>{'\u2715'}
                </span>
                <h2 style={{marginTop: 0, marginBottom: 0}}>Gráfico fixado</h2>
                <p>Apenas um gráfico pode ser fixado por vez.</p>
                { graphs[fixedGraph] }
            </div> : null
        }
        </>
    );
}

const mapStateToProps = state => {
    return {
        charts: state.charts
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setSeries: (series) => dispatch({type: actionTypes.STORE_SERIES, new_series: series}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SeriesIndividualVisualization);