import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px',
    borderWidth: 2,
    borderRadius: 20,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    marginTop: '10px',
    cursor: 'pointer'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const FileUploader = ({ field, form, ...props }) => {
    const handleUpload = files => {
        props.completion(files);
    }

    const handleReject = rejectedFiles => {
        rejectedFiles.forEach(file => {
            file.errors.forEach(message => {
                toast.error(mapErrorCodeToMessage(message.code))
            });
        })
    }

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        accept: ['.csv'],
        maxSize: 10 * 1024 * 1024,
        maxFiles: 0,
        onDropAccepted: handleUpload,
        onDropRejected: handleReject
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    return (
        <div className="container">
            <div {...getRootProps({style})}>
                <input {...getInputProps()}
                        name={props.uploadName}
                        type="file" 
                />
                {
                    <p>Arraste ou clique para selecionar</p>
                }
            </div>
        </div>
    );
}

const mapErrorCodeToMessage = code => {
    switch (code) {
        case "file-invalid-type":
            return "Formato de arquivo inválido - por favor, envie um arquivo PNG ou JPEG."
        case "file-too-large":
            return "Arquivo grande demais - por favor, envie um arquivo com tamanho de no máximo 500 kB"
        default:
            return "Arquivo inválido"
    }
};

export default FileUploader;