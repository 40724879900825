import { defaultOptions, series_as_list, jsonDataToCSV, ChartTitle } from './EChartsHelper';
import { aggregateDays, aggregateHours } from './DailySeriesPlot';
import ReactECharts from 'echarts-for-react';
import * as moment from 'moment';

// Monthly Plot
function convertMonthGranularity(series) {
    let seriesData = [];
    let current = [];
    let currentMonth = moment(series[0]['timestamp']).month();
    for (let i = 0; i < series.length; ++i) {
        if (moment(series[i]['timestamp']).month() != currentMonth) {
            seriesData.push(current);
            current = [];
            currentMonth = moment(series[i]['timestamp']).month();
        }
        current.push(series[i]);
    }
    seriesData.push(current);
    return seriesData;
}

function aggregateMonths(series) {
    const monthSeries = convertMonthGranularity(series);
    const aggregatedMonthSeries = monthSeries.map(series => {
        return {timestamp: series[0]["timestamp"].slice(0,7), ...series.reduce((prev, current) => {
                return {value: prev["value"] + current["value"]};
            })
        };
    });
    return aggregatedMonthSeries;
}

function aggregateDailyMeanMonths(series) {
    const monthlyGranularity = convertMonthGranularity(series);
    const dailyMeanSeries = monthlyGranularity.map((monthSeries, index) => {
        const monthAggregatedDays = aggregateDays(monthSeries);
        const sumMonthAggregatedDays = monthAggregatedDays.reduce((prev, cur) => {
            return {...prev, value: prev["value"] + cur["value"]};
        });
        sumMonthAggregatedDays["value"] /= monthAggregatedDays.length;
        return sumMonthAggregatedDays;
    });
    const dailyMinSeries = monthlyGranularity.map((monthSeries, index) => {
        const monthAggregatedDays = aggregateDays(monthSeries);
        return monthAggregatedDays.reduce((prev, cur) => {
            return {...prev, value: Math.min(prev["value"], cur["value"])};
        })
    });
    const dailyMaxSeries = monthlyGranularity.map((monthSeries, index) => {
        const monthAggregatedDays = aggregateDays(monthSeries);
        return monthAggregatedDays.reduce((prev, cur) => {
            return {...prev, value: Math.max(prev["value"], cur["value"])};
        })
    });
    return [dailyMeanSeries, dailyMinSeries, dailyMaxSeries];
}

const MonthlySeasonalitySeriesPlot = (props) => {
    let firstYearMonth = props.series[0]['timestamp'].slice(0, 7);
    const monthSeries = convertMonthGranularity(props.series).map(series => series.map(point => {
        return {...point, timestamp: firstYearMonth+point["timestamp"].slice(7)}
    }));
    
    const seriesDict = monthSeries.map((series, index) => {
        return {
            data: series_as_list(series),
            type: "line",
            showSymbol: false,
            symbol: "none",
            silent: true,
            animation: false,
            lineStyle: {
                width: 1,
                opacity: 0.3
            },
            emphasis: {
                lineStyle: {
                    width: 1
                }
            }
        };
    });
    const opt = {...defaultOptions(props), ...{
        tooltip: {
            trigger: 'none',
            triggerOn: 'click',
            axisPointer: {
                show: true,
                type: 'cross',
                lineStyle: {
                    type: 'dashed',
                    width: 1
                },
                label: {
                    formatter: function(params) {
                        if (params["axisDimension"] === "x")
                            return moment(params["value"]).format('[Dia] DD [às] h:mm');
                        return params["value"].toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2});
                    }
                }
            },
        },
        xAxis: {
            type: "time",
            boundaryGap: false,
            axisLabel: {
                formatter: function(ts) {
                    return moment(ts).format("[Dia] DD [às] h:mm");
                }
            }
        },
        series: seriesDict
    }}
    
    return (
        <div style={{width: "100%"}}>
            <ChartTitle 
                title="Curvas mensais" 
                csvName="curvas_mensais.csv"
                data={jsonDataToCSV(monthSeries, seriesDict.map(series => series.name))} />
            <ReactECharts option={opt} 
                          style={{height: '300px'}} 
                          opts={{locale: 'PTBR'}} >
            </ReactECharts>
        </div>
    );
}

// Monthly Plot Aggregated Daily
const MonthlySeasonalityAggregatedDailyPlot = (props) => {
    let firstYearMonth = props.series[0]['timestamp'].slice(0, 7);
    const monthlySeries = convertMonthGranularity(props.series).map(series => series.map(point => {
        return {...point, timestamp: firstYearMonth+point["timestamp"].slice(7)}
    }));
    const monthlyAggregatedSeries = monthlySeries.map(series => aggregateDays(series));
    
    const seriesDict = monthlyAggregatedSeries.map((series, index) => {
        return {
            data: series_as_list(series),
            type: "line",
            showSymbol: false,
            symbol: "none",
            silent: true,
            animation: false,
            lineStyle: {
                width: 1,
                opacity: 0.3
            },
            emphasis: {
                lineStyle: {
                    width: 1
                }
            }
        };
    });
    const opt = {...defaultOptions(props), ...{
        tooltip: {
            trigger: 'none',
            triggerOn: 'click',
            axisPointer: {
                show: true,
                type: 'cross',
                lineStyle: {
                    type: 'dashed',
                    width: 1
                },
                label: {
                    formatter: function(params) {
                        if (params["axisDimension"] === "x")
                            return moment(params["value"]).format('[Dia] DD');
                        return params["value"].toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2});
                    }
                }
            },
        },
        xAxis: {
            type: "time",
            boundaryGap: false,
            axisLabel: {
                formatter: function(ts) {
                    return moment(ts).format("[Dia] DD");
                }
            }
        },
        series: seriesDict
    }}
    
    return (
        <div style={{width: "100%"}}>
            <ChartTitle 
                title="Curvas mensais agregadas diariamente" 
                csvName="curvas_mensais_agregadas_diariamente.csv"
                data={jsonDataToCSV(monthlyAggregatedSeries, seriesDict.map(series => series.name))} />
            <ReactECharts option={opt} 
                          style={{height: '300px'}} 
                          opts={{locale: 'PTBR'}} >
            </ReactECharts>
        </div>
    );
}

// Monthly Plot Aggregated Hourly
const MonthlySeasonalityAggregatedHourlyPlot = (props) => {
    let firstYearMonth = props.series[0]['timestamp'].slice(0, 7);
    const monthlySeries = convertMonthGranularity(props.series).map(series => series.map(point => {
        return {...point, timestamp: firstYearMonth+point["timestamp"].slice(7)}
    }));
    const monthlyAggregatedSeries = monthlySeries.map(series => aggregateHours(series));
    
    const seriesDict = monthlyAggregatedSeries.map((series, index) => {
        return {
            data: series_as_list(series),
            type: "line",
            showSymbol: false,
            symbol: "none",
            silent: true,
            animation: false,
            lineStyle: {
                width: 1,
                opacity: 0.3
            },
            emphasis: {
                lineStyle: {
                    width: 1
                }
            }
        };
    });
    const opt = {...defaultOptions(props), ...{
        tooltip: {
            trigger: 'none',
            triggerOn: 'click',
            axisPointer: {
                show: true,
                type: 'cross',
                lineStyle: {
                    type: 'dashed',
                    width: 1
                },
                label: {
                    formatter: function(params) {
                        if (params["axisDimension"] === "x")
                            return moment(params["value"]).format('[Dia] DD [às] h:mm');
                        return params["value"].toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2});
                    }
                }
            },
        },
        xAxis: {
            type: "time",
            boundaryGap: false,
            axisLabel: {
                formatter: function(ts) {
                    return moment(ts).format("[Dia] DD [às] h:mm");
                }
            }
        },
        series: seriesDict
    }}
    
    return (
        <div style={{width: "100%"}}>
            <ChartTitle 
                title="Curvas mensais agregadas de hora em hora" 
                csvName="curvas_mensais_agregadas_de_hora_em_hora.csv"
                data={jsonDataToCSV(monthlyAggregatedSeries, seriesDict.map(series => series.name))} />
            <ReactECharts option={opt} 
                          style={{height: '300px'}} 
                          opts={{locale: 'PTBR'}} >
            </ReactECharts>
        </div>
    );
}

export { convertMonthGranularity,
         aggregateMonths, 
         aggregateDailyMeanMonths,
         MonthlySeasonalitySeriesPlot, 
         MonthlySeasonalityAggregatedDailyPlot, 
         MonthlySeasonalityAggregatedHourlyPlot };