import React from "react";
import ReactTooltip from "react-tooltip";
import { BsFillInfoCircleFill } from "react-icons/bs";

function guidGenerator() {
    var S4 = function() {
       return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    };
    return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
}

const Info = (props) => {
    const id = guidGenerator();
    return (
        <div 
            className="tooltip" 
            disabled={props.disabled}
            style={{marginLeft:'10px', display: 'inline-block', fontSize:'smaller', ...props.style}}> 
            <span className="tooltip" style={{marginLeft:"10px"}} data-tip data-for={id}>
                {
                    props.icon ? props.icon : <BsFillInfoCircleFill/>
                }
            </span>
            <ReactTooltip id={id} backgroundColor="rgb(128, 128, 128)" multiline={true} place="top" type="dark" effect="solid">
                {props.children}
            </ReactTooltip>
        </div>
    );
};

export default Info;