import React from 'react';

const Card = (props) => (
    <div className="card" style={props.style}>
        <h2 className="first-label" 
            style={{marginBottom: 0, 
                    display: props.inline ? "inline-block" : ""}}>
            {props.title}
        </h2>
        {props.children}
    </div>
);

export default Card;