import { cloneElement, useState, useEffect, useRef } from 'react';
import Button from 'components/UI/Button';
import Card from 'components/UI/Card';
import Histogram from 'components/Plots/Histogram';
import QQPlot from 'components/Plots/QQPlot';
import StatsTable from 'components/UI/StatsTable';
import { ForecastPlot } from 'components/Plots/ForecastPlot';
import { series_as_dict } from 'components/Plots/EChartsHelper';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { runAnalysis } from 'apis/api';
import Loader from 'react-loader-spinner';
import { CSVLink } from 'react-csv';
import FullscreenModal, { CloseModalButton } from 'components/UI/FullscreenModal';
import { CompareTwoForecastsPlot, CompareAllForecastPlot } from 'components/Plots/ComparativePlots';
import { forecast_historic_csv, forecast_csv, forecast_quantiles_csv } from 'components/Plots/PlotHelper';
import * as actionTypes from 'store/actions';

function mapModelName(name) {
    switch (name) {
        case 'ARIMA':
            return 'ARIMA';
        case 'ETS':
            return 'ETS';
        case 'Naive':
            return 'Naive';
        case 'Seasonal Naive':
            return 'Seasonal Naive';
        case 'Neural Network Autoregression':
            return 'Neural Network Autoregression';
        default:
            return '';
    }
}

function mapStatName(name) {
    switch (name) {
        case 'RMSE':
            return 'rmse';
        case 'MAE':
            return 'mae';
        case 'MAPE':
            return 'mape';
        case 'CRPS':
            return 'crps';
        case 'Winkler 95%':
            return 'winkler95'
        default:
            return '';
    }
}

function mapStatId(id) {
    switch (id) {
        case 'rmse':
            return 'RMSE';
        case 'mae':
            return 'MAE';
        case 'mape':
            return 'MAPE';
        case 'crps':
            return 'CRPS';
        case 'winkler95':
            return 'Winkler 95%'
        default:
            return '';
    }
}

const sliderBaseSettings = {
    className: "center",
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    draggable: false,
    appendDots: dots => {
        const mappedDots = dots.map((dot, index) => {
            const children = dot.props.children;
            return <>
                {cloneElement(children, {className: dot.props.className, key: index})}
            </>;
        });
        return (<div key={Math.floor(Math.random() * 10000)} style={{display: "flex", justifyContent: "center", height: "1.7em", position: "absolute", top: -10}}> {mappedDots} </div>)
    }
}

const getPlotInfo = (name, series, modelData) => {
    if (!name) { return {}; }
    const timestamps = Array.isArray(modelData.timestamps) ? modelData.timestamps : [modelData.timestamps];
    const mean = Array.isArray(modelData.mean) ? modelData.mean : [modelData.mean];
    const lower80 = Array.isArray(modelData.lower["80%"]) ? modelData.lower["80%"] : [modelData.lower["80%"]];
    const lower95 = Array.isArray(modelData.lower["95%"]) ? modelData.lower["95%"] : [modelData.lower["95%"]];
    const upper80 = Array.isArray(modelData.upper["80%"]) ? modelData.upper["80%"] : [modelData.upper["80%"]];
    const upper95 = Array.isArray(modelData.upper["95%"]) ? modelData.upper["95%"] : [modelData.upper["95%"]];

    const lastHistoricTimestamp = series.timestamps[series.timestamps.length-1];
    const lastHistoricValue = series.values[series.values.length-1];
    const historicSeries = series_as_dict("Historic", series.timestamps, series.values);
    const forecastSeries = series_as_dict("Forecast", [lastHistoricTimestamp, ...timestamps], [lastHistoricValue, ...mean]);
    const lower80Series = series_as_dict("Lower 80% prediction interval", [lastHistoricTimestamp, ...timestamps], [lastHistoricValue, ...lower80]);
    const lower95Series = series_as_dict("Lower 95% prediction interval", [lastHistoricTimestamp, ...timestamps], [lastHistoricValue, ...lower95]);
    const upper80Series = series_as_dict("Upper 80% prediction interval", [lastHistoricTimestamp, ...timestamps], [lastHistoricValue, ...upper80]);
    const upper95Series = series_as_dict("Upper 95% prediction interval", [lastHistoricTimestamp, ...timestamps], [lastHistoricValue, ...upper95]);

    return {
        name: name,
        historic: historicSeries, 
        forecast: forecastSeries,
        lower80: lower80Series,
        lower95: lower95Series,
        upper80: upper80Series,
        upper95: upper95Series
    }
}

const ModelCard = props => {
    const informationCriterionHeader = ["AIC", "AICc", "BIC"];
    const informationCriterionData = [props.modelData.aic, props.modelData.aicc, props.modelData.bic];
    const informationCriterionTable = [informationCriterionHeader, informationCriterionData];
    const trainStatsHeader = ["RMSE", "MAE", "MAPE"].filter(el => el);
    const trainStatsData = [props.modelData.train_stats.rmse, props.modelData.train_stats.mae, props.modelData.train_stats.mape].filter(el => el);
    const trainStatsTable = [trainStatsHeader, trainStatsData];
    const testStatsHeader = ["RMSE", "MAE", "MAPE", "CRPS", "Winkler 95%"].filter(el => el);
    const testStatsData = [props.modelData.test_stats.rmse, props.modelData.test_stats.mae, props.modelData.test_stats.mape, props.modelData.test_stats.crps, props.modelData.test_stats.winkler95].filter(el => el);
    const testStatsTable = [testStatsHeader, testStatsData];
    const plotInfo = getPlotInfo(props.title, props.series, props.modelData);
    const analysisName = props.name.toLowerCase().split(' ').join('_');
    const csvName = props.title.toLowerCase().split(' ').join('_');
    return (
        <Card title={props.title + `${props.modelData.method}`} style={{marginTop: 30, marginBottom: 20, marginLeft: 40, marginRight: 40}}>
            <CSVLink
                data={forecast_quantiles_csv(plotInfo)} 
                filename={`${analysisName}_${csvName}_forecast.csv`}
                className="blue-button"
                style={{
                        float: "right", 
                        marginTop: "-30px"
                }}
            ><strong>{"\u2913"}</strong>CSV de previsões do modelo</CSVLink> 
            <ForecastPlot {...plotInfo} />
            <Histogram 
                title="Histograma de resíduos"
                series={props.modelData.residuals}
            />
            <QQPlot 
                series={props.modelData.residuals}
            />
            <StatsTable 
                filename="Estatísticas do modelo X"
                tableData={trainStatsTable}
                tableClassName="table table-striped table-hover"
                title="Estatísticas de desempenho do modelo dentro da amostra"
            />
            <StatsTable 
                style={{marginTop: 60}}
                filename="Estatísticas do modelo X"
                tableData={testStatsTable}
                tableClassName="table table-striped table-hover"
                title="Estatísticas de desempenho do modelo fora da amostra"
            />
            {
                props.modelData.aic !== "-" &&
                <StatsTable 
                    style={{marginTop: 60}}
                    filename="Estatísticas do modelo X"
                    tableData={informationCriterionTable}
                    tableClassName="table table-striped table-hover"
                    title="Critérios de informação do modelo"
                />
            }
        </Card>
    );
}

const AnalysesComparison = (props) => {
    const sliderRef = useRef();
    const models = ["ARIMA", "ETS", "Naive", "Seasonal Naive", "Neural Network Autoregression"];
    const trainStats = ["RMSE", "MAE", "MAPE"];
    const testStats = ["RMSE", "MAE", "MAPE", "CRPS", "Winkler 95%"];
    const [selectTrainStats, setSelectTrainStats] = useState(mapStatName(trainStats[0]));
    const [selectTestStats, setSelectTestStats] = useState(mapStatName(testStats[0]));
    
    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }
    const dependentSeriesIds = props.analysesResults.map(analysis => analysis?.results?.dependentSeries?.id).filter(el => el).filter(onlyUnique);
    const dependentSeriesNames = props.analysesResults.map(analysis => {
        if (analysis?.results?.dependentSeries)
            return {
                [analysis.results.dependentSeries.id]: analysis.results.dependentSeries.name
            }
        return null
    }).filter(el => el)?.reduce((prev, current) => { return {...prev, ...current}; }, []);
    
    const [selectedSeriesId, setSelectedSeriesId] = useState(dependentSeriesIds[0]);
    const filteredAnalysisResults = props.analysesResults.filter(analysis => {
        return analysis?.results?.dependentSeries?.id === selectedSeriesId;
    });

    const modelKeys = filteredAnalysisResults.map(analysisResults => analysisResults?.results ? Object.keys(analysisResults.results).map(key => {
            if (models.includes(key)) {
                return key;
            }
        }).filter(el => el)
    : []).flat(Infinity);
    const uniqueModelKeys = [...new Set(modelKeys)];
    const analysesComparisonHeader = ["Modelo", ...filteredAnalysisResults.map(analysisResults => analysisResults?.results ? analysisResults.results.name : "Indisponível")]
    const comparativeTrainStatsData = uniqueModelKeys.map(key => {
        const stats = filteredAnalysisResults.map(analysisResults => {
            if (analysisResults.results)
                return key in analysisResults.results ? analysisResults.results[key].train_stats[selectTrainStats] : "-";
            else
                return '-';
        });
        return [mapModelName(key), ...stats];
    });
    const analysesTrainStatsComparisonTableData = [analysesComparisonHeader, ...comparativeTrainStatsData];

    const comparativeTestStatsData = uniqueModelKeys.map(key => {
        const stats = filteredAnalysisResults.map(analysisResults => {
            if (analysisResults.results)
                return key in analysisResults.results ? analysisResults.results[key].test_stats[selectTestStats] : "-";
            else
                return '-';
        });
        return [mapModelName(key), ...stats];
    });
    const analysesTestStatsComparisonTableData = [analysesComparisonHeader, ...comparativeTestStatsData];

    return (
        <>
        {
            dependentSeriesNames.length === 0 ? 
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <h2>Ainda não há resultados de análises.</h2>
            </div> :
            <div>
                <div>
                    <h3>Escolha a série dependente para comparação entre análises</h3>
                    <select value={selectedSeriesId} onChange={(e) => { 
                        setSelectedSeriesId(e.target.value);
                        sliderRef?.current?.slickGoTo(0); 
                    }}>
                    {
                        dependentSeriesIds.map(id => {
                            return <option key={id} value={id}>{dependentSeriesNames[id]}</option>
                        })
                    }
                    </select>
                </div>
                <h2>Estatísticas comparativas de análises que utilizam a série dependente {dependentSeriesNames[selectedSeriesId]}</h2>
                <Card style={{marginTop: 30, marginBottom: 20, marginLeft: 40, marginRight: 40}}>
                    <h2 className="first-label">Estatísticas comparativas de avaliação do modelo dentro da amostra</h2>
                    
                    <h3 style={{marginBottom: 0}}>Escolha a métrica</h3>
                    <select value={selectTrainStats} onChange={(e) => { 
                            setSelectTrainStats(e.target.value);
                        }}>
                        {
                            trainStats.map((trainStat, index) => {
                                return <option key={index} value={mapStatName(trainStat)}>{trainStat}</option>
                            })
                        }
                    </select>
                    <StatsTable 
                        filename="Estatísticas comparativas das análises"
                        tableData={analysesTrainStatsComparisonTableData}
                        tableClassName="table table-striped table-hover"
                        title={`Estatísticas de avaliação do modelo dentro da amostra - comparando ${mapStatId(selectTrainStats)}`}
                        style={{marginTop: 30}}
                    />
                </Card>
                <Card style={{marginTop: 30, marginBottom: 20, marginLeft: 40, marginRight: 40}}>
                    <h2 className="first-label">Estatísticas comparativas de avaliação do modelo fora da amostra</h2>
                    <h3 style={{marginBottom: 0, marginTop: 0}}>Escolha a métrica</h3>
                    <select value={selectTestStats} onChange={(e) => {
                            setSelectTestStats(e.target.value);
                        }}>
                        {
                            testStats.map((testStat, index) => {
                                return <option key={index} value={mapStatName(testStat)}>{testStat}</option>
                            })
                        }
                    </select>
                    <StatsTable 
                        filename="Estatísticas comparativas das análises"
                        tableData={analysesTestStatsComparisonTableData}
                        tableClassName="table table-striped table-hover"
                        title={`Estatísticas de avaliação do modelo fora da amostra - comparando ${mapStatId(selectTestStats)}`}
                        style={{marginTop: 30}}
                    />
                </Card>
            </div>
        }
        </>
    );
}

const ExportForecasts = (props) => {
    const [forecastExport, setForecastExport] = useState("historicforecast");

    const handleChange = e => {
        setForecastExport(e.target.value);
    }

    function getPlotData() {
        switch (forecastExport) {
            case "historicforecast":
                return forecast_historic_csv(props.plotInfo);
            case "forecast":
                return forecast_csv(props.plotInfo);
            default:
                return [];
        }
    }
    const name = props.name.toLowerCase().split(' ').join('_');
    return (
        <div>
            <h2>Exportação das previsões de {props.name}</h2>
            <Card>
                <div style={{display: "flex", flexDirection: "column", marginBottom: 30}}>
                    <h3 style={{marginTop: 0}}>Selecione o formato de exportação</h3>
                    <label for="historicforecast"><input type="radio" value="historicforecast" name="forecastexport" onChange={handleChange} />Histórico + previsão pontual</label>
                    <label for="forecast"><input type="radio" value="forecast" name="forecastexport" onChange={handleChange} />Apenas previsão pontual</label>
                </div>
                <CSVLink
                    data={getPlotData()} 
                    filename={`${name}_forecast.csv`}
                    className="blue-button"
                ><strong>{"\u2913"}</strong>CSV de previsões</CSVLink> 
            </Card>
            <Button onClick={() => props.closeModal()}>Voltar</Button>
        </div>
    );
}

const AnalysisInformation = (props) => {
    const sliderRef = useRef();
    const [selectValue, setSelectValue] = useState(props.analyses[0].id);
    const [selectedAnalysis, setSelectedAnalysis] = useState(props.analyses[0].id);
    const [selectedComparisonAttr, setSelectedComparisonAttr] = useState("forecast");
    const [selectedGraph1, setSelectedGraph1] = useState("");
    const [selectedGraph2, setSelectedGraph2] = useState("");
    const models = ["ARIMA", "ETS", "Naive", "Seasonal Naive", "Neural Network Autoregression"];
    const comparativeTrainStatsHeader = ["Modelo", "RMSE", "MAE", "MAPE"];
    const index = props.analysesResults.findIndex(element => element.id === selectedAnalysis);
    const output = props.analysesResults[index].results ? props.analysesResults[index].results : {};    
    const comparisonAttrs = ["upper95", "upper80", "forecast", "lower80", "lower95"];
    const comparisonAttrNames = {
        forecast: "Média", 
        lower95: "5-quantil", 
        lower80: "20-quantil", 
        upper80: "80-quantil", 
        upper95: "95-quantil"
    };
    const modelKeys = Object.keys(output).map(key => {
        if (models.includes(key)) {
            return key;
        }
    }).filter(el => el);
    
    const comparativeTrainStatsData = modelKeys.map(key => {
        const modelData = output[key];
        return [mapModelName(key), modelData.train_stats.rmse, modelData.train_stats.mae, modelData.train_stats.mape]
    });
    const comparativeTestStatsHeader = ["Modelo", "RMSE", "MAE", "MAPE", "CRPS", "Winkler 95%"];
    const comparativeTestStatsData = modelKeys.map(key => {
        const modelData = output[key];
        return [mapModelName(key), modelData.test_stats.rmse, modelData.test_stats.mae, modelData.test_stats.mape, modelData.test_stats.crps, modelData.test_stats.winkler95]
    });
    const comparativeTrainStatsTable = [comparativeTrainStatsHeader, ...comparativeTrainStatsData];
    const comparativeTestStatsTable = [comparativeTestStatsHeader, ...comparativeTestStatsData];
    const settings = {
        ...sliderBaseSettings,
        customPaging: function(i) {
            const name = (i === 0) ? "Resumo" : mapModelName(modelKeys[i-1]);
            return (
                <div style={{borderBottomLeftRadius: i === 0 ? "0.5em" : 0, borderTopLeftRadius: i === 0 ? "0.5em" : 0,
                    borderBottomRightRadius: i === modelKeys.length ? "0.5em" : 0, borderTopRightRadius: i === modelKeys.length ? "0.5em" : 0,
                    paddingLeft: 10, paddingRight: 10, textAlign: "center", cursor: "pointer", border: "1.5px solid black",
                    fontSize: "1.17em", color: "rgba(0, 11, 67, 1)", fontWeight: "bold"}}>
                        {name}
                </div>
            );
        },
    };

    const allPlotInfos = modelKeys.map(key => getPlotInfo(key, output.dependentSeries, output[key]));
    return (<>
        <div>
            <select value={selectValue} onChange={(e) => { 
                setSelectedAnalysis(e.target.value);
                setSelectValue(e.target.value);
                
                sliderRef?.current?.slickGoTo(0); 
            }}>
            {
                props.analyses.map(analysis => {
                    return <option key={analysis.id} value={analysis.id}>{analysis.name}</option>
                })
            }
            </select>
        </div>
        {
            modelKeys.length > 0 &&
            <div style={{display: "flex", justifyContent: "space-between", marginBottom: 20}}>
                <h2>Estatísticas de {output.name}</h2>
                <FullscreenModal buttonClass="blue-button" button="Download de CSV das previsões" style={{marginTop: 30}}>
                    <ExportForecasts plotInfo={allPlotInfos} name={props.analyses[index].name} />
                </FullscreenModal>
            </div>
        }
        {
            modelKeys.length > 0 ?
            <Slider {...settings} ref={sliderRef}>
                <div>
                    <Card style={{marginTop: 30, marginBottom: 20, marginLeft: 40, marginRight: 40}}>
                        <StatsTable 
                            filename="Estatísticas do modelo X"
                            tableData={comparativeTrainStatsTable}
                            tableClassName="table table-striped table-hover"
                            title="Estatísticas comparativas dos modelos dentro da amostra"
                        />
                    </Card>
                    <Card style={{marginTop: 30, marginBottom: 20, marginLeft: 40, marginRight: 40}}>
                        <StatsTable 
                            filename="Estatísticas do modelo X"
                            tableData={comparativeTestStatsTable}
                            tableClassName="table table-striped table-hover"
                            title="Estatísticas comparativas dos modelos fora da amostra"
                        />
                    </Card>
                    <Card style={{marginTop: 30, marginBottom: 20, marginLeft: 40, marginRight: 40}}>
                        <h2 className="first-label">Comparação de previsão pontual entre todos os modelos</h2>

                        <h3 style={{marginBottom: 0}}>Escolha as curvas de previsão</h3>
                        <select value={selectedComparisonAttr} onChange={(e) => { 
                            setSelectedComparisonAttr(e.target.value);
                        }}>
                            {
                                comparisonAttrs.map((attr, index) => {
                                    return <option key={index} value={attr}>{comparisonAttrNames[attr]}</option>
                                })
                            }
                        </select>
                        <CompareAllForecastPlot forecastInfo={allPlotInfos} comparisonAttr={selectedComparisonAttr} comparisonAttrName={comparisonAttrNames[selectedComparisonAttr]} />
                    </Card>
                    <Card style={{marginTop: 30, marginBottom: 20, marginLeft: 40, marginRight: 40}}>
                        <h2 className="first-label">Comparação de previsão pontual e intervalos de predição entre dois modelos</h2>
                        
                        <h3 style={{marginBottom: 0}}>Escolha os modelos a serem comparados</h3>
                        <div className="AdvancedSelectContainer">
                            <select value={selectedGraph1} onChange={(e) => { 
                                setSelectedGraph1(e.target.value);
                            }}>
                                <option key={-1} value={""}>Selecione um modelo</option>
                                {
                                    modelKeys.filter(key => key !== selectedGraph2).map((modelKey, index) => {
                                        return <option key={index} value={modelKey}>{modelKey}</option>
                                    })
                                }
                            </select>
                            <select value={selectedGraph2} onChange={(e) => { 
                                setSelectedGraph2(e.target.value);
                            }}>
                                <option key={-1} value={""}>Selecione um modelo</option>
                                {
                                    modelKeys.filter(key => key !== selectedGraph1).map((modelKey, index) => {
                                        return <option key={index} value={modelKey}>{modelKey}</option>
                                    })
                                }
                            </select>
                        </div>
                        {
                            selectedGraph1 && selectedGraph2 && 
                            <CompareTwoForecastsPlot 
                                model1={getPlotInfo(selectedGraph1, output.dependentSeries, output[selectedGraph1])}
                                model2={getPlotInfo(selectedGraph2, output.dependentSeries, output[selectedGraph2])} 
                            />
                        }
                    </Card>
                </div>
                {
                    modelKeys.map((model, i) => (
                        <ModelCard key={mapModelName(model)} title={mapModelName(model)} modelData={output[model]} series={output.dependentSeries} name={props.analyses[index].name} />
                    ))
                }
            </Slider> : 
            <div>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    {
                        (props.analyses[index].dependentSeries.length === 0 || props.analyses[index].models.length === 0) &&
                        <h2 style={{marginTop: 30}}>Análise incompleta. Por favor, retornar à tela de análises para preenchê-la.</h2>
                    }
                    {
                        !props.analysesResults[index].hasRun &&
                        <>
                            { !(props.analyses[index].dependentSeries.length === 0 || props.analyses[index].models.length === 0) && <h2 style={{marginTop: 30}}>Clique no botão abaixo para rodar a análise.</h2> }
                            <Button type="button" disabled={props.analyses[index].dependentSeries.length === 0 || props.analyses[index].models.length === 0}
                                    style={{marginTop: 10}}
                                    onClick={() => {
                                        props.analysesResults[index].hasRun = true;
                                        props.triggerRerender();
                                        runAnalysis(props.analyses[index], (data) => {
                                                    props.analysesResults[index].results = data;
                                                    props.setAnalysesResults(props.analysesResults);
                                                    props.triggerRerender();
                                        }, () => {});
                                    }}>
                                Rodar análise
                            </Button>
                        </>
                    }
                    {
                    props.analysesResults[index].hasRun && props.analysesResults[index].results === null &&
                        <>
                        <h2 style={{marginTop: 30}}>Sua análise está em execução. Por favor aguarde.</h2>
                        <Loader 
                            type="Oval" 
                            color="rgba(0, 11, 67, 1)"
                            height={40} 
                            width={40}
                            style={{marginLeft: "20px", marginTop: "30px"}} /> 
                        </>
                    }
                </div>
            </div>
        }
    </>);
}

const AnalysesResults = (props) => {
    const history = useHistory();
    const [compareAnalysis, setCompareAnalysis] = useState(false);
    
    return (
        <div>
            <div style={{display: "flex", justifyContent: "center"}}>
                <Button onClick={() => setCompareAnalysis(false)} className={!compareAnalysis ? "Button ButtonActive" : "Button"} style={{borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRight: "1px solid rgba(254, 121, 56, 1)"}}>Resultados de análises</Button>
                <Button onClick={() => setCompareAnalysis(true)} className={compareAnalysis ? "Button ButtonActive" : "Button"} style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeft: "1px solid rgba(254, 121, 56, 1)"}}>Comparativo entre análises</Button>
            </div>
            {
                !compareAnalysis ?
                <div>
                    <h2>Resultados de análises</h2>
                    <h3>Escolha uma análise abaixo</h3>
                </div> :
                <h2>Comparativo entre análises</h2>
            }
            {
                <div>
                    {
                        !compareAnalysis ?
                        <AnalysisInformation {...props} /> :
                        <AnalysesComparison analysesResults={props.analysesResults} />
                    }
                    <div>
                        <Button 
                            type="button"
                            style={{float: "left"}}
                            onClick={() => {
                                history.push(props.prevPage);
                            }}>
                                Voltar
                        </Button>
                    </div>
                </div>
            }
        </div>
    );
};

const mapStateToProps = state => {
    return {
        series: state.series,
        output: state.output,
        analyses: state.analyses,
        analysesResults: state.analysesResults,
        rerender: state.rerender
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setAnalysesResults: (results) => dispatch({type: actionTypes.STORE_ANALYSES_RESULTS, analysesResults: results}),
        triggerRerender: () => dispatch({type: actionTypes.TRIGGER_RERENDER})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AnalysesResults);