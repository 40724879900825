import { BrowserRouter as Router } from 'react-router-dom';
import Layout from 'pages/Layout';
import 'moment/locale/pt-br';
import moment from 'moment';
import RouteChangeTracker from 'helper/GAHelper';
import ReactGA from 'react-ga';
moment.locale('pt-br');


const TRACKING_ID = "UA-197285142-2";
ReactGA.initialize(TRACKING_ID);
// ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <Router>
      <RouteChangeTracker />
      <Layout />
    </Router>
  );
}

export default App;
