import { defaultOptions, values_as_list, jsonDataToCSV, ChartTitle } from './EChartsHelper';
import { aggregateDays, aggregateHours } from './DailySeriesPlot';
import ReactECharts from 'echarts-for-react';
import * as moment from 'moment';

// Weekly Plot
function convertWeekGranularity(series) {
    let seriesData = [];
    let current = [];
    let currentTime = moment(series[0]['timestamp'].slice(0, 10)).week();
    for (let i = 0; i < series.length; ++i) {
        if (moment(series[i]['timestamp'].slice(0, 10)).week() != currentTime) {
            seriesData.push(current);
            current = [];
            currentTime = moment(series[i]['timestamp'].slice(0, 10)).week();
        }
        current.push(series[i]);
    }
    seriesData.push(current);
    return seriesData;
}

const WeeklySeasonalitySeriesPlot = (props) => {
    const weeklySeries = convertWeekGranularity(props.series); 
    const seriesDict = weeklySeries.map((series, index) => {
        return {
            data: values_as_list(series),
            type: "line",
            showSymbol: false,
            symbol: "none",
            silent: true,
            animation: false,
            lineStyle: {
                width: 1,
                opacity: 0.3
            },
            emphasis: {
                lineStyle: {
                    width: 1
                }
            }
        };
    });
    const week = [
        'Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'
    ]
    const timestamps = weeklySeries[1].map(series => `${series['timestamp'].slice(11, 16)}`);
    const timestampsWeek = weeklySeries[1].map(series => `${week[moment(series['timestamp'].slice(0, 10)).weekday()]}`);
    const opt = {...defaultOptions(props), ...{
        animation: false,
        force: {
            layoutAnimation: false
        },
        tooltip: {
            trigger: 'none',
            triggerOn: 'click',
            axisPointer: {
                show: true,
                type: 'cross',
                lineStyle: {
                    type: 'dashed',
                    width: 1
                }
            }
        },
        xAxis: [
            {
                data: timestamps,
                boundaryGap: false,
                type: 'category'
            },
            {
                data: timestampsWeek,
                boundaryGap: false,
                type: 'category'
            },
        ],
        series: seriesDict
    }}
    
    return (
        <div style={{width: "100%"}}>
            <ChartTitle 
                title="Curvas semanais" 
                csvName="curvas_semanais.csv"
                data={jsonDataToCSV(weeklySeries, seriesDict.map(series => series.name))} />
            <ReactECharts option={opt} 
                          style={{height: '300px'}} 
                          opts={{locale: 'PTBR'}} >
            </ReactECharts>
        </div>
    );
}

// Weekly Plot Aggregated Daily
const WeeklySeasonalityAggregatedDailyPlot = (props) => {
    const weeklySeries = convertWeekGranularity(props.series); 
    const weeklyAggregatedSeries = weeklySeries.map(series => aggregateDays(series));
    const seriesDict = weeklyAggregatedSeries.map((series, index) => {
        return {
            data: values_as_list(series),
            type: "line",
            showSymbol: false,
            symbol: "none",
            silent: true,
            animation: false,
            lineStyle: {
                width: 1,
                opacity: 0.3
            },
            emphasis: {
                lineStyle: {
                    width: 1
                }
            }
        };
    });
    const week = [
        'Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'
    ]
    const timestampsWeek = weeklyAggregatedSeries[1].map(series => `${week[moment(series['timestamp'].slice(0, 10)).weekday()]}`);
    const opt = {...defaultOptions(props), ...{
        animation: false,
        force: {
            layoutAnimation: false
        },
        tooltip: {
            trigger: 'none',
            triggerOn: 'click',
            axisPointer: {
                show: true,
                type: 'cross',
                lineStyle: {
                    type: 'dashed',
                    width: 1
                }
            }
        },
        xAxis: [
            {
                data: timestampsWeek,
                boundaryGap: false,
                type: 'category'
            }
        ],
        series: seriesDict
    }}
    
    return (
        <div style={{width: "100%"}}>
            <ChartTitle 
                title="Curvas semanais agregadas diariamente" 
                csvName="curvas_semanais_agregadas_diariamente.csv"
                data={jsonDataToCSV(weeklyAggregatedSeries, seriesDict.map(series => series.name))} />
            <ReactECharts option={opt} 
                          style={{height: '300px'}} 
                          opts={{locale: 'PTBR'}} >
            </ReactECharts>
        </div>
    );
}

// Weekly Plot Aggregated Hourly
const WeeklySeasonalityAggregatedHourlyPlot = (props) => {
    const weeklySeries = convertWeekGranularity(props.series); 
    const weeklyAggregatedSeries = weeklySeries.map(series => aggregateHours(series));
    const seriesDict = weeklyAggregatedSeries.map((series, index) => {
        return {
            data: values_as_list(series),
            type: "line",
            showSymbol: false,
            symbol: "none",
            silent: true,
            animation: false,
            lineStyle: {
                width: 1,
                opacity: 0.3
            },
            emphasis: {
                lineStyle: {
                    width: 1
                }
            }
        };
    });
    const week = [
        'Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'
    ]    
    const timestamps = weeklyAggregatedSeries[1].map(series => `${series['timestamp'].slice(11, 16)}:00`);
    const timestampsWeek = weeklyAggregatedSeries[1].map(series => `${week[moment(series['timestamp'].slice(0, 10)).weekday()]}`);
    const opt = {...defaultOptions(props), ...{
        animation: false,
        force: {
            layoutAnimation: false
        },
        tooltip: {
            trigger: 'none',
            triggerOn: 'click',
            axisPointer: {
                show: true,
                type: 'cross',
                lineStyle: {
                    type: 'dashed',
                    width: 1
                }
            }
        },
        xAxis: [
            {
                data: timestamps,
                boundaryGap: false,
                type: 'category'
            },
            {
                data: timestampsWeek,
                boundaryGap: false,
                type: 'category'
            },
        ],
        series: seriesDict
    }}
    
    return (
        <div style={{width: "100%"}}>
            <ChartTitle 
                title="Curvas semanais agregadas de hora em hora" 
                csvName="curvas_semanais_agregadas_de_hora_em_hora.csv"
                data={jsonDataToCSV(weeklyAggregatedSeries, seriesDict.map(series => series.name))} />
            <ReactECharts option={opt} 
                          style={{height: '300px'}} 
                          opts={{locale: 'PTBR'}} >
            </ReactECharts>
        </div>
    );
}

export { convertWeekGranularity, 
        WeeklySeasonalitySeriesPlot,
        WeeklySeasonalityAggregatedDailyPlot,
        WeeklySeasonalityAggregatedHourlyPlot };