import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import Card from 'components/UI/Card';
import ReactTooltip from 'react-tooltip';

const StatsTable = (props) => {
    const [sortIndex, setSortIndex] = useState(null);
    const [sortOrder, setSortOrder] = useState(true);
    const tableHead = props.tableData[0];
    const tableBody = props.tableData.slice(1);

    if (sortIndex)
        tableBody.sort((a, b) => sortOrder ? a[sortIndex] - b[sortIndex] : b[sortIndex] - a[sortIndex]);

    const updateSort = (index) => {
        if (index === sortIndex)
            setSortOrder(!sortOrder);
        else
            setSortOrder(true);
        setSortIndex(index);
    }

    return (
        <div style={props.style}>
            <h3 className="first-label" style={{marginBottom: 0, display: "inline-block"}}>{props.title}</h3>
            <span className="tooltip" style={{marginLeft:"10px"}}data-tip data-for='Stats'> {"\u24D8"} </span>
            {/* <ReactTooltip id='Stats' backgroundColor="rgb(128, 128, 128)" multiline={true} place="top" effect="solid">
                <p style={{color:"white"}}>Como nem todo usuário da ferramenta estará necessariamente familiarizado com os testes estatísticos,<br/> acrescentamos uma nota agregada deles para indicar de forma simplificada o resultado dos diagnósticos.<br/>Essa nota, entre 0 e 10, depende dos resultados dos testes de normalidade, independência e homoscedasticidade<br/> dos resíduos padronizados. Ela é calculada a partir da soma das seguintes parcelas:</p>
                <ul>
                    <li style={{color:"white"}}><strong>Teste Jarque-Bera de Normalidade: 4 pontos.</strong> A rejeição da hipótese no nível de significância de 2.5% <br/>(p-valor {"<"} 0.025) implica em 0 pontos. Se ela passa no nível de 2.5%, mas não passa no de 5% <br/> (0.025 {"<"} p-valor {"<"} 0.05), o resultado é considerado intermediário e o teste recebe 2 pontos.<br/> Por fim, caso não seja rejeitada no nível de 5% (p-valor {">"} 0.05), o teste recebe os 4 pontos totais.</li><br/>
                    <li style={{color:"white"}}><strong>Teste Ljung-Box de Independência: 4 pontos.</strong> Segue o mesmo critério do teste Jarque-Bera, podendo receber <br/> 0, 2 ou 4 pontos dependendo do resultado.</li> <br/>
                    <li style={{color:"white"}}><strong>Teste H de Homoscedasticidade: 2 pontos.</strong> Se não for rejeitado no nível de 5%, o teste recebe os 2 pontos <br/> totais. Caso contrário, 0.</li>
                </ul>
            </ReactTooltip> */}
            
            <CSVLink
                data={props.tableData} 
                filename={props.filename}
                className="blue-button"
                style={{float: 'right'}}><strong>{"\u2913"}</strong> Arquivo CSV</CSVLink>
            <table className={props.tableClassName} style={{marginTop: 35}}>
                <thead>
                    <tr>
                    {
                        tableHead.map((head, j) => {
                            let suffix = ""
                            if (j === sortIndex)
                                suffix = sortOrder ? "\u2193" : "\u2191";
                            return <th key={head}><a style={{cursor: "pointer"}} onClick={() => updateSort(j)}>{head} {suffix}</a></th>
                        })
                    }
                    </tr>
                </thead>
                <tbody>
                {
                    tableBody.map((row, i) => {
                        return (
                            <tr key={`row${i}`}>
                                {
                                    row.map((col, j) => {
                                        let cell = col;
                                        if (typeof(col) == "number")
                                            if (cell >= 10000)
                                                cell = col.toExponential([2])
                                            else
                                                cell = col.toFixed(2);
                                        return (
                                            <td key={`row${i}col${j}`}>
                                                { cell }
                                            </td>
                                        );
                                    })
                                }
                            </tr>
                        );
                    })
                }
                </tbody>
            </table>
        </div>
    );
};

export default StatsTable;