import React, { useState } from 'react';
import { connect, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import series from 'assets/examples/series.csv';
import Modal from 'components/UI/Modal';
import SeriesIndividualVisualization from 'components/UI/SeriesIndividualVisualization';
import Button from 'components/UI/Button';
import SeriesTable from 'components/UI/SeriesTable';
import Calendar from 'components/UI/Calendar';
import moment from 'moment';
import { compareDates, mapGranularityTimeFormat, dateToString} from 'components/Plots/EChartsHelper';
import * as actionTypes from 'store/actions';
import { connect as connectRedux } from 'react-redux';
import { useEffect } from 'react';

export const validation = yup.object().shape({
    series: yup
        .object()
        .required("Arquivo CSV da série é obrigatório.")
})

const SeriesSelectSubForm = (props) => {
    let [csvData, setCsvData] = useState("");
    let [modalText, setModalText] = useState([]);
    const[selectedId, setSelectedId] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [visualizeSeries, setVisualizeSeries] = useState(null);
    const [loading, setLoading] = useState(false);
    const currentAnalysis = props.formik.values.analyses[props.index];
    const [selectedSeries, setSelectedSeries] = useState([...currentAnalysis.explanatorySeries]);

    const dependentSeriesId = currentAnalysis.dependentSeries.map(s => s.seriesMeta.series_id);
    const filteredSeries = props.series.filter(s => !dependentSeriesId.includes(s.seriesMeta.series_id)).filter(s => s.seriesMeta.granularity.granularity === currentAnalysis.dependentSeries[0].seriesMeta.granularity.granularity);

    const selectedSeriesIds = selectedSeries.map(s => s.seriesMeta.series_id);
    const selectedArray = filteredSeries.map(s => selectedSeriesIds.includes(s.seriesMeta.series_id));

    const falseArray = [...Array(props.series.length)].map(_ => false);
    const [checked, setChecked] = useState(selectedArray);
    const dependentSeriesGranularity = currentAnalysis.dependentSeries.length > 0 ? currentAnalysis.dependentSeries[0].seriesMeta.granularity : { granularity: 10 };
    const granularityFormat = currentAnalysis.dependentSeries.length > 0 ? mapGranularityTimeFormat(currentAnalysis.dependentSeries[0].seriesMeta.granularity.granularity):"lll";
    const [predAndEstDates, setPredAndEstDates] = useState({
        granularity: dependentSeriesGranularity,
        startEstimation: currentAnalysis.startEstimation,
        endEstimation: currentAnalysis.endEstimation,
        endForecast: currentAnalysis.endForecast
    });
    const [updateDatesInfo, setUpdateDatesInfo] = useState(null);
    const [openDatesModal, setOpenDatesModal] = useState(false);
    const [dateModalText, setDatesModalText] = useState([]);
    const [incompatibleDateError, setIncompatibleDatesError] = useState(false);
    const [seriesIdsToDelete,setSeriesIdsToDelete] = useState([]);

    let [auxPredAndEstDates, setAuxPredAndEstDates] = useState(null);

    useEffect(() => {
        setPredAndEstDates({
            granularity: dependentSeriesGranularity,
            startEstimation: currentAnalysis.startEstimation,
            endEstimation: currentAnalysis.endEstimation,
            endForecast: currentAnalysis.endForecast
        });
    }, [currentAnalysis.startEstimation, currentAnalysis.endEstimation, currentAnalysis.endForecast]);

    fetch(series).then((response) => {
        return response.text();
    }).then((data) => {
        setCsvData(data);
    });

    const toggleSelection = (id) => {
        let selected = [...selectedSeries];
        let find = selected.findIndex(series => series.seriesMeta.series_id === id);
        let findGeneral = filteredSeries.findIndex(series => series.seriesMeta.series_id === id);
        let checkedArray = [...checked];
        checkedArray[findGeneral] = !checkedArray[findGeneral];
        setChecked(checkedArray);

        if (find > -1) {
            selected.splice(find, 1); // deselect series
        } else {
            selected.push(filteredSeries.find(series => series.seriesMeta.series_id === id)); // select series
        }

        setSelectedSeries(selected);
        props.triggerRerender();
    }

    const select = (id) => {
        let selected = [...selectedSeries];
        let find = selected.findIndex(series => series.seriesMeta.series_id === id);

        if (find == -1) {
            const seriesChecked = filteredSeries.find(series => series.seriesMeta.series_id === id).seriesMeta;
            const changesEstimationBeginning = compareDates(seriesChecked.start_date, predAndEstDates.startEstimation) === 1;
            const changesForecastEnding = compareDates(seriesChecked.end_date, predAndEstDates.endForecast) === -1;
            auxPredAndEstDates = {...predAndEstDates}
            if (changesEstimationBeginning || changesForecastEnding) {
                // Open modal to check if user wants that
                if (changesEstimationBeginning) {
                    auxPredAndEstDates.startEstimation = moment(seriesChecked.start_date).toDate();
                    modalText.push(<p>Estimação: essa séria altera o início do período de estimação de <strong>{dateToString(dependentSeriesGranularity.granularity, predAndEstDates.startEstimation)}</strong> para <strong>{dateToString(dependentSeriesGranularity.granularity, auxPredAndEstDates.startEstimation)}</strong></p>);
                } 
                if (changesForecastEnding) {
                    auxPredAndEstDates.endForecast = moment(seriesChecked.end_date).toDate();
                    modalText.push(<p>Previsão: essa séria altera o fim do período de previsão de <strong>{dateToString(dependentSeriesGranularity.granularity, predAndEstDates.endForecast)}</strong> para <strong>{dateToString(dependentSeriesGranularity.granularity, auxPredAndEstDates.endForecast)}</strong></p>);
                }
                setModalText(modalText);
                setAuxPredAndEstDates(auxPredAndEstDates);
                setSelectedId(id);
                setModalOpen(true);
            } else {
                toggleSelection(id);
            }
        } else {
            toggleSelection(id);
        }

    }

    const selectAll = () => {
        let selected = [...Array(filteredSeries.length)].map(_ => true);
        setChecked(selected);
        setSelectedSeries([...filteredSeries]);
    }

    const clearSelection = () => { 
        setChecked(falseArray);
        setSelectedSeries([]);
    }

    const checkIncompatibleDates = (date, type) => {
        const dependentSeries = currentAnalysis.dependentSeries;
        if (dependentSeries.length === 0) return false;
        const granularity = dependentSeries[0].seriesMeta.granularity.granularity;
    
        if (type === "startEstimation" && compareDates(predAndEstDates.endEstimation, date) < 1) {
            dateModalText.push(<p> A data escolhida para o início do período de estimação ({dateToString(granularity,date)}) é igual ou posterior ao fim desse período ({dateToString(granularity,predAndEstDates.endEstimation)}) </p>);
            return true; 
        }

        if (type === "endEstimation" && (compareDates(predAndEstDates.startEstimation, date) > -1 || compareDates(predAndEstDates.endForecast , date) < 1)) {
            if (compareDates(predAndEstDates.startEstimation, date) > -1) {
                dateModalText.push(<p> A data escolhida para o fim do período de estimação ({dateToString(granularity,date)}) é igual ou anterior ao início desse período ({dateToString(granularity,predAndEstDates.startEstimation)}) </p>);
            } else {
                dateModalText.push(<p> A data escolhida para o fim do período de estimação ({dateToString(granularity,date)}) é igual ou posterior ao fim do período de previsão ({dateToString(granularity,predAndEstDates.endForecast)}) </p>);
            }
            return true; 
        }
        
        if (type === "endForecast" && (compareDates(predAndEstDates.endEstimation, date) > -1 )) {
            dateModalText.push(<p> A data escolhida para o fim do período de previsão ({dateToString(granularity,date)}) é igual ou anterior ao fim do período de estimação({dateToString(granularity,currentAnalysis.endEstimation)}) </p>);
            return true; 
        }
    }

    const checkIncompatibleSeries = (date, type) => {
        const dependentSeries = currentAnalysis.dependentSeries;
        const explanatorySeries = selectedSeries;
        const idsToDelete = [];
        if (dependentSeries.length === 0) return idsToDelete;
        const granularity = dependentSeries[0].seriesMeta.granularity.granularity;
        
        dependentSeries.forEach(el => {
            if (type === "endForecast") return;
            if (compareDates(el.seriesMeta.start_date, date) === 1) {
                idsToDelete.push(el.seriesMeta.series_id);
                dateModalText.push(<p> A data escolhida ({dateToString(granularity,date)}) é anterior ao início da série dependente: {el.seriesMeta.name} ({dateToString(granularity,el.seriesMeta.start_date)}) </p>);
            }

            if (compareDates(el.seriesMeta.end_date,date) === -1) {
                idsToDelete.push(el.seriesMeta.series_id);
                dateModalText.push(<p> A data escolhida ({dateToString(granularity,date)}) é posterior ao fim da série dependente: {el.seriesMeta.name} ({dateToString(granularity,el.seriesMeta.end_date)}) </p>);
            }
        });

        if (idsToDelete.length !== 0) {
            dateModalText.push(<h4>Essa alteração de datas não pode ser feita pois resultaria na exclusão da série dependente</h4>);
            setIncompatibleDatesError(true);
            return idsToDelete;
        }

        explanatorySeries.forEach(el => {
            if (compareDates(el.seriesMeta.start_date,date) === 1) {
                idsToDelete.push(el.seriesMeta.series_id);
                dateModalText.push(<p> A data escolhida ({dateToString(granularity,date)}) é anterior ao início da série explicativa: {el.seriesMeta.name} ({dateToString(granularity,el.seriesMeta.start_date)}) </p>);
            }

            if (compareDates(el.seriesMeta.end_date,date) === -1) {
                idsToDelete.push(el.seriesMeta.series_id);
                dateModalText.push(<p> A data escolhida ({dateToString(granularity,date)}) é posterior ao fim da série explicativa: {el.seriesMeta.name} ({dateToString(granularity,el.seriesMeta.end_date)}) </p>);
            }
        });
        return idsToDelete;
    }

    return (
        <>
            <h2>{props.title}</h2>
            <p>Selecione uma ou mais séries explicativas</p>
            <SeriesTable title="Séries disponíveis" isExplanatorySelection algorithmDates={predAndEstDates} select={select} checked={checked} seriesArray={filteredSeries} setVisualizeSeries={setVisualizeSeries}>
                <Button type="button" onClick={() => clearSelection()} style={{marginLeft: 30}}>Limpar seleção</Button>
                <div style={{display: "flex", justifyContent:"space-between", paddingRight:10, paddingLeft:10}}>
                    <div className="AdvancedParametersItem">
                        <h3 style={{marginBottom: 0}}>Início da estimação: </h3>
                        <Calendar 
                            value={predAndEstDates.startEstimation}
                            setValue={(date) => {
                                if (checkIncompatibleDates(date,"startEstimation")) { // TODO: se há algum erro nas explicativas após alteração da data
                                    setDatesModalText(dateModalText);
                                    setIncompatibleDatesError(true);
                                    setOpenDatesModal(true);
                                    return;
                                } 

                                const delIds = checkIncompatibleSeries(date,"startEstimation");
                                if (delIds.length !== 0) {
                                    setDatesModalText(dateModalText);
                                    setSeriesIdsToDelete(delIds);
                                    setUpdateDatesInfo(["startEstimation", date]);
                                    setOpenDatesModal(true);
                                    return; 
                                } 
                                
                                predAndEstDates.startEstimation = moment(date).toDate();
                                setPredAndEstDates(predAndEstDates);
                                props.triggerRerender();
                            }}
                            granularity={predAndEstDates.granularity}
                        />
                        {/* <p>{moment(predAndEstDates.startEstimation).format(granularityFormat)}</p> */}
                    </div>
                    <div className="AdvancedParametersItem">
                        <h3 style={{marginBottom: 0}}>Fim da estimação: </h3>
                        <Calendar 
                            value={predAndEstDates.endEstimation}
                            setValue={(date) => {
                                if (checkIncompatibleDates(date,"endEstimation")) { // TODO: se há algum erro nas explicativas após alteração da data
                                    setDatesModalText(dateModalText);
                                    setIncompatibleDatesError(true);
                                    setOpenDatesModal(true);
                                    return;
                                } 

                                const delIds = checkIncompatibleSeries(date,"endEstimation");
                                if (delIds.length !== 0) {
                                    setDatesModalText(dateModalText);
                                    setSeriesIdsToDelete(delIds);
                                    setUpdateDatesInfo(["endEstimation", date]);
                                    setOpenDatesModal(true);
                                    return; 
                                } 
                                
                                predAndEstDates.endEstimation = moment(date).toDate();
                                setPredAndEstDates(predAndEstDates);
                                props.triggerRerender();
                            }}
                            granularity={predAndEstDates.granularity}
                        />
                        {/* <p>{moment(predAndEstDates.endEstimation).format(granularityFormat)}</p> */}
                    </div>
                    <div className="AdvancedParametersItem"> 
                        <h3 style={{marginBottom: 0}}>Fim da previsão: </h3>
                        <Calendar 
                            value={predAndEstDates.endForecast}
                            setValue={(date) => {
                                if (checkIncompatibleDates(date,"endForecast")) { // TODO: se há algum erro nas explicativas após alteração da data
                                    setDatesModalText(dateModalText);
                                    setIncompatibleDatesError(true);
                                    setOpenDatesModal(true);
                                    return;
                                } 

                                const delIds = checkIncompatibleSeries(date,"endForecast");
                                if (delIds.length !== 0) {
                                    setDatesModalText(dateModalText);
                                    setSeriesIdsToDelete(delIds);
                                    setUpdateDatesInfo(["endForecast", date]);
                                    setOpenDatesModal(true);
                                    return; 
                                } 
                                
                                predAndEstDates.endForecast = moment(date).toDate();
                                setPredAndEstDates(predAndEstDates);
                                props.triggerRerender();
                            }}
                            granularity={predAndEstDates.granularity}
                        />
                        {/* <p>{moment(predAndEstDates.endForecast).format(granularityFormat)}</p> */}
                    </div> 
                </div>
            </SeriesTable>
            <div>
                <Button type="button" onClick={() => {
                    let currentValues = props.formik.values.analyses[props.index][props.name];
                    props.formik.setFieldValue(`analyses[${props.index}][${props.name}]`, [...selectedSeries]);
                    props.formik.setFieldValue(`analyses[${props.index}][startEstimation]`, predAndEstDates.startEstimation);
                    props.formik.setFieldValue(`analyses[${props.index}][endEstimation]`, predAndEstDates.endEstimation);
                    props.formik.setFieldValue(`analyses[${props.index}][endForecast]`, predAndEstDates.endForecast);
                    setTimeout(() => {
                        setLoading(false);
                        props.formik.validateForm();
                        setSelectedSeries([]);
                        setChecked(selectedArray);
                        props.closeModal();
                    }, 10);
                }}>Selecionar</Button>
                <Button type="button" style={{marginLeft: 30}} onClick={() => {
                    setSelectedSeries([]);
                    setChecked(selectedArray);
                    predAndEstDates.startEstimation = currentAnalysis.startEstimation;
                    predAndEstDates.endEstimation = currentAnalysis.endEstimation;
                    predAndEstDates.endForecast = currentAnalysis.endForecast;
                    setPredAndEstDates(predAndEstDates);
                    props.closeModal();
                }}>Cancelar</Button>
            </div>
            {
                <Modal className="Modal BigModal" show={visualizeSeries !== null} modalClosed={() => setVisualizeSeries(null)}>
                    {
                        visualizeSeries && <SeriesIndividualVisualization seriesMeta={visualizeSeries} />
                    }
                </Modal>
            } 
            
            <Modal className="Modal SmallModal" show={modalOpen}>
                <h3 className="first-label"> Alteração de Datas </h3>
                <span>{modalText}</span>
                <Button onClick={()=>{
                    setModalText([]);
                    setModalOpen(false);
                    setPredAndEstDates(auxPredAndEstDates);
                    toggleSelection(selectedId);
                }} >Concordo</Button>
                <Button style={{marginLeft: 20}} onClick={() => setModalOpen(false)}>Cancelar</Button>
            </Modal>

            <Modal className="Modal SmallModal" show={openDatesModal}>
                <h3 className="first-label"> Alteração de Datas </h3>
                <div>{dateModalText}</div>
                {
                    incompatibleDateError &&
                    <Button onClick={()=>{
                        setDatesModalText([]);
                        setOpenDatesModal(false);
                        setIncompatibleDatesError(false);
                        props.triggerRerender();
                    }} >Entendi</Button>

                }
                { !incompatibleDateError &&
                    <>
                        <Button onClick={()=>{
                            setDatesModalText([]);
                            setOpenDatesModal(false);
                            seriesIdsToDelete.forEach(id => toggleSelection(id));
                            predAndEstDates[updateDatesInfo[0]] = moment(updateDatesInfo[1]).toDate();
                            setPredAndEstDates(predAndEstDates);
                            setSeriesIdsToDelete([]);
                            props.triggerRerender();
                        }} >Concordo</Button>
                        <Button style={{marginLeft: 20}} onClick={() => {
                            setOpenDatesModal(false);
                            setDatesModalText([]);
                            setSeriesIdsToDelete([]);
                            setIncompatibleDatesError(false);
                        }}>Cancelar</Button>
                    </>
                }
            </Modal>
        </>
    );
};

const mapStateToProps = state => {
    return {
        rerender: state.rerender
    }
};

const mapDispatchToProps = dispatch => {
    return {
        triggerRerender: () => dispatch({type: actionTypes.TRIGGER_RERENDER}),
    }
}

export default connectRedux(mapStateToProps, mapDispatchToProps)(connect(SeriesSelectSubForm));
