import React, { useState } from 'react';
import { connect, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import SelectField from 'components/UI/SelectField';
import ModelsTable from 'components/UI/ModelsTable';
import { ARIMA, ETS, Naive, SeasonalNaive, NeuralNetworkAutoregression } from 'helper/ModelHelper';

export const validation = yup.object().shape({
    series: yup
        .object()
        .required("Arquivo CSV da série é obrigatório.")
})

const ModelSelectionSubForm = (props) => {
    const [loading, setLoading] = useState(false);
    const options = [
        { value: ARIMA, label: 'ARIMA' },
        { value: ETS, label: 'ETS' },
        { value: Naive, label: 'Naive' },
        { value: SeasonalNaive, label: 'Seasonal Naive' },
        { value: NeuralNetworkAutoregression, label: 'Neural Network Autoregression'}
    ]

    return (
        <>
            <h2>{props.title}</h2>
            <div style={{minHeight: 300}}>
                {
                    !props.disabled && 
                    <>
                        <label className="first-label" htmlFor={props.fieldPrefix + "models"}>Modelos</label>
                        <Field name={props.fieldPrefix + "models"} component={SelectField} options={options} props={{placeholder: "Selecione um ou mais modelos", models: true}} />
                        <ErrorMessage name={props.fieldPrefix + "models"} component="div" className={"error"} />
                    </>
                }
                
                <ModelsTable title="Modelos escolhidos" 
                    modelsArray={props.analysisValues.models} 
                    analysisIndex={props.analysisIndex} 
                    setModelsArray={(modelsArray) => {
                        props.formik.setFieldValue(props.fieldPrefix + "models", modelsArray)
                        props.formik.validateForm();
                    }} 
                    style={{marginTop: 20}}
                    disabled={props.disabled} />
            </div>
        </>
    );
};

export default connect(ModelSelectionSubForm);