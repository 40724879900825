import React, { useState, useEffect } from "react";
import { AiFillWarning, AiFillCheckCircle } from "react-icons/ai";
import { VscError } from "react-icons/vsc"
import { BsGraphUp } from "react-icons/bs";
import Info from "components/UI/Info";
import { mapGranularityTimeFormat, dateToString, compareDates } from 'components/Plots/EChartsHelper';


const SeriesStatus = (props) => {

    const statusSymbol = () => {
        if (props.hasNoPrediction || props.hasNoEstimation) return <VscError color="red"/>;
        if (props.cutsPredictionBeginning || props.cutsPredictionEnd || props.cutsEstimationBeginning || props.cutsEstimationEnd) return <AiFillWarning  color="orange"/>;
        return <AiFillCheckCircle  color="green"/>
    }

    const generateText = () => {
        const granularity = props.seriesInfo.granularity.granularity;
        let text = [];

        if (props.hasNoEstimation) {
            if (compareDates(props.seriesInfo.start_date, props.dependentInfo.endEstimation) > -1) {
                text.push(<p style={{color:'white'}}> A série começa em {dateToString(granularity, props.seriesInfo.end_date)} que é igual ou posterior ao fim do período de estimação, {dateToString(granularity, props.dependentInfo.endEstimation)}</p>)
            } else {
                text.push(<p style={{color:'white'}}> A série começa em {dateToString(granularity, props.seriesInfo.end_date)} que é igual ou anterior ao fim do período de estimação {dateToString(granularity, props.dependentInfo.endEstimation)}</p>)
            }
        } else  {
            if (props.cutsEstimationBeginning){ 
                text.push(<p style={{color:'white'}}> A série começa em {dateToString(granularity, props.seriesInfo.start_date)} que é posterior ao início do período de estimação {dateToString(granularity, props.dependentInfo.startEstimation)}</p>)
            }

            if (props.cutsEstimationEnd) { 
                text.push(<p style={{color:'white'}}> A série termina em {dateToString(granularity, props.seriesInfo.end_date)} que é anterior ao fim do período de estimação {dateToString(granularity, props.dependentInfo.endEstimation)}</p>)
            } 
        }

        if (props.hasNoPrediction) {
            if (compareDates(props.seriesInfo.end_date, props.dependentInfo.endEstimation) < 1) {
                text.push(<p style={{color:'white'}}>A série termina em {dateToString(granularity, props.seriesInfo.end_date)} que é igual ou anterior ao fim do período de estimação {dateToString(granularity, props.dependentInfo.endEstimation)}</p>)
            } else {
                text.push(<p style={{color:'white'}}>A série começa em {dateToString(granularity, props.seriesInfo.start_date)} que é igual ou posterior ao fim do período de previsão {dateToString(granularity, props.dependentInfo.endForecast)}</p>)
            }
        } else {
            if (props.cutsPredictionBeginning){ 
                text.push(<p style={{color:'white'}}> A série começa em {dateToString(granularity, props.seriesInfo.start_date)} que é posterior ao fim do período de estimação {dateToString(granularity, props.dependentInfo.endEstimation)}</p>)
            }

            if (props.cutsPredictionEnd) { 
                text.push(<p style={{color:'white'}}> A série termina em {dateToString(granularity, props.seriesInfo.end_date)} que é anterior ao fim do período de previsão {dateToString(granularity, props.dependentInfo.endForecast)}</p>)
            }
        }

        if (text.length === 0) {
            text.push(<p style={{color:'white'}}>A série cobre todo o <br/> período de estimação e previsão</p>);
        }
        return text
    }

    return (
        <Info icon={statusSymbol()} style={{float: "right", verticalAlign: "middle"}}>
            <p style={{color: "white"}}>{generateText()}</p>
        </Info>
    );
};

export default SeriesStatus