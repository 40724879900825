import { useRef } from 'react';
import { defaultOptions, series_as_list, jsonDataToCSV, ChartTitle } from './EChartsHelper';
import { aggregateYears } from './YearlySeriesPlot';
import { aggregateMonths } from './MonthlySeriesPlot';
import { aggregateHours, aggregateDays, aggregateMeanDays, aggregateMeanHours } from './DailySeriesPlot';
import Button from 'components/UI/Button';
import ReactECharts from 'echarts-for-react';
import * as moment from 'moment';

// Complete plot 
const CompleteSeriesPlot = (props) => {
    const opt = defaultOptions(props);
    const title = props.title ? props.title : "Série completa";
    return (
        <div style={{width: "100%"}}>
            <h2 className="first-label" style={{marginBottom: 0}}>{title}</h2>
            <ReactECharts option={opt} 
                          style={{height: '300px'}} 
                          opts={{renderer: 'svg', 
                          locale: 'PTBR'}} >
            </ReactECharts>
        </div>
    );
}

// Complete plot with date selection
const CompleteSeriesPlotDates = (props) => {
    const echartsRef = useRef(null);
    const dataZoom = props.dates ? [
        {
            type: 'inside',
            startValue: props.dates[0],
            endValue: props.dates[1]
        }, {
            startValue: props.dates[0],
            endValue: props.dates[1]
        }
    ] : [
        {
            type: 'inside',
            start: 0,
            end: 100
        }, {
            start: 0,
            end: 100
        }
    ];
    const opt = {...defaultOptions(props), dataZoom: dataZoom}
    
    return (
        <>
            <div style={{width: "100%"}}>
                <ChartTitle 
                    title="Série completa" 
                    csvName="serie_completa.csv"
                    // data={jsonDataToCSV([props.series], ["timestamps", "values"])} 
                />
                <ReactECharts option={opt} 
                            ref={echartsRef}
                            style={{height: '300px'}} 
                            opts={{renderer: 'svg', 
                            locale: 'PTBR'}} >
                </ReactECharts>
            </div>
            <Button type="button" style={{marginTop: 20}} onClick={() => {
                if (echartsRef.current) {
                    const instance = echartsRef.current.getEchartsInstance();
                    const startDate = moment(instance.getOption().dataZoom[0].startValue);
                    const endDate = moment(instance.getOption().dataZoom[0].endValue);
                    
                    props.selectDateHandle(startDate, endDate);
                }
            }}>Selecionar intervalo</Button>
        </>
    );
}

// Aggregated Yearly Plot
const CompleteSeriesAggregatedYearlyPlot = (props) => {
    const aggregatedYears = aggregateYears(props.series);
    const opt = {...defaultOptions(props), ...{
        series: {
            data: series_as_list(aggregatedYears),
            type: "line",
            showSymbol: false
        },
        tooltip: {
            trigger: 'axis',
            formatter: function(params) {
                return `${moment(params[0].axisValue).format("YYYY")}<br />
                        ${params[0].marker} ${params[0].seriesName}: <strong>${params[0].value[1].toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</strong>`;
            }
        }
    }}
    return (
        <div style={{width: "100%"}}>
            <ChartTitle 
                title="Série completa agregada anualmente" 
                csvName="serie_completa_agregada_anualmente.csv"
                data={jsonDataToCSV([aggregatedYears], ["timestamps", "values"])} />
            <ReactECharts option={opt} 
                          style={{height: '300px'}} 
                          opts={{renderer: 'svg', 
                          locale: 'PTBR'}} >
            </ReactECharts>
        </div>
    );
}

// Aggregated Monthly Plot
const CompleteSeriesAggregatedMonthlyPlot = (props) => {
    const aggregatedMonths = aggregateMonths(props.series);
    const opt = {...defaultOptions(props), ...{
        series: {
            data: series_as_list(aggregatedMonths),
            type: "line",
            showSymbol: false
        },
        tooltip: {
            trigger: 'axis',
            formatter: function(params) {
                return `${moment(params[0].axisValue).format("ll")}<br />
                        ${params[0].marker} ${params[0].seriesName}: <strong>${params[0].value[1].toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</strong>`;
            }
        }
    }}
    return (
        <div style={{width: "100%"}}>
            <ChartTitle 
                title="Série completa agregada mensalmente" 
                csvName="serie_completa_agregada_mensalmente.csv"
                data={jsonDataToCSV([aggregatedMonths], ["timestamps", "values"])} />
            <ReactECharts option={opt} 
                          style={{height: '300px'}} 
                          opts={{renderer: 'svg', 
                          locale: 'PTBR'}} >
            </ReactECharts>
        </div>
    );
}

// Aggregated Daily Plot
const CompleteSeriesAggregatedDailyPlot = (props) => {
    const aggregatedDays = aggregateDays(props.series);
    const opt = {...defaultOptions(props), ...{
        series: {
            data: series_as_list(aggregatedDays),
            type: "line",
            showSymbol: false
        },
        tooltip: {
            trigger: 'axis',
            formatter: function(params) {
                return `${moment(params[0].axisValue).format("ll")}<br />
                        ${params[0].marker} ${params[0].seriesName}: <strong>${params[0].value[1].toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</strong>`;
            }
        },
    }}
    return (
        <div style={{width: "100%"}}>
            <ChartTitle 
                title="Série completa agregada diariamente" 
                csvName="serie_completa_agregada_diariamente.csv"
                data={jsonDataToCSV([aggregatedDays], ["timestamps", "values"])} />
            <ReactECharts option={opt} 
                          style={{height: '300px'}} 
                          opts={{renderer: 'svg', 
                          locale: 'PTBR'}} >
            </ReactECharts>
        </div>
    );
}

// Aggregated Hourly Plot
const CompleteSeriesAggregatedHourlyPlot = (props) => {
    const aggregatedHours = aggregateHours(props.series);
    const opt = {...defaultOptions(props), ...{
        series: {
            data: series_as_list(aggregatedHours),
            type: "line",
            showSymbol: false,
            lineStyle: {
                width: 1
            },
            emphasis: {
                lineStyle: {
                    width: 1
                }
            },
        },
        tooltip: {
            trigger: 'axis',
            formatter: function(params) {
                return `${moment(params[0].axisValue).format("lll")}<br />
                        ${params[0].marker} ${params[0].seriesName}: <strong>${params[0].value[1].toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</strong>`;
            }
        },
    }}
    return (
        <div style={{width: "100%"}}>
            <ChartTitle 
                title="Série completa agregada de hora em hora" 
                csvName="serie_completa_agregada_de_hora_em_hora.csv"
                data={jsonDataToCSV([aggregatedHours], ["timestamps", "values"])} />
            <ReactECharts option={opt} 
                          style={{height: '300px'}} 
                          opts={{renderer: 'svg', 
                          locale: 'PTBR'}} >
            </ReactECharts>
        </div>
    );
}

// Mean Std Daily Plot
const CompleteSeriesMeanMinMaxDailyPlot = (props) => {
    const [meanSeries, minSeries, maxSeries] = aggregateMeanDays(props.series);
    const seriesDict = [
        {
            name: 'Máximo',
            type: 'line',
            data: series_as_list(maxSeries),
            z: -1,
            lineStyle: {
                width: 1.0,
                // type: 'dashed'
            },
            symbol: 'none'
        }, 
        {
            name: "Média diária",
            lineStyle: {
                width: 1.5,
            },
            data: series_as_list(meanSeries),
            type: "line",
            showSymbol: false
        }, {
            name: 'Mínimo',
            type: 'line',
            data: series_as_list(minSeries),
            z: -1,
            lineStyle: {
                width: 1.0,
                // type: 'dashed'
            },
            symbol: 'none'
        }
    ];
    const opt = {...defaultOptions(props), ...{
        series: seriesDict,
        legend: {
            data: seriesDict.map(series => series.name),
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                animation: false,
                label: {
                    backgroundColor: '#ccc',
                    borderColor: '#aaa',
                    borderWidth: 1,
                    shadowBlur: 0,
                    shadowOffsetX: 0,
                    shadowOffsetY: 0,
                    color: '#222',
                    formatter: function(params) {
                        if (params["axisDimension"] === "x")
                            return moment(params["value"]).format('ll');
                        return params["value"].toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2});
                    }
                }
            },
            formatter: function(params) {
                const tooltipStrings = params.map(obj => obj.value ? `${obj.marker} ${obj.seriesName}: <strong>${obj.value[1].toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</strong><br />` : "").join("");
                return `${moment(params[0].axisValue).format("ll")}<br />` + tooltipStrings;
            }
        },
    }}
    return (
        <div style={{width: "100%"}}>
            <ChartTitle 
                title={`Estatísticas de ${props.chartsAux.unit_delta} em ${props.chartsAux.unit_delta} ${props.chartsAux.unit_name} da série completa diária`}
                csvName="estatísticas_de_30_em_30_minutos_diariamente_da_série_completa.csv"
                data={jsonDataToCSV([meanSeries, minSeries, maxSeries], ["Média", "Mínimo", "Máximo"])} />
            <ReactECharts option={opt} 
                          style={{height: '300px'}} 
                          opts={{renderer: 'svg', 
                          locale: 'PTBR'}} >
            </ReactECharts>
        </div>
    );
}

// Mean Std Hourly Plot
const CompleteSeriesMeanMinMaxHourlyPlot = (props) => {
    const [meanSeries, minSeries, maxSeries] = aggregateMeanHours(props.series);
    const seriesDict = [
        {
            name: 'Máximo',
            type: 'line',
            data: series_as_list(maxSeries),
            z: -1,
            symbol: 'none'
        }, {
            name: 'Mínimo',
            type: 'line',
            data: series_as_list(minSeries),
            z: -1,
            symbol: 'none'
        },
        {
            name: "Média",
            data: series_as_list(meanSeries),
            type: "line",
            showSymbol: false
        }
    ];
    const opt = {...defaultOptions(props), ...{
        series: seriesDict,
        legend: {
            data: seriesDict.map(series => series.name),
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                animation: false,
                label: {
                    backgroundColor: '#ccc',
                    borderColor: '#aaa',
                    borderWidth: 1,
                    shadowBlur: 0,
                    shadowOffsetX: 0,
                    shadowOffsetY: 0,
                    color: '#222',
                    formatter: function(params) {
                        if (params["axisDimension"] === "x")
                            return moment(params["value"]).format('ll');
                        return params["value"].toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2});
                    }
                }
            },
            formatter: function(params) {
                const tooltipStrings = params.map(obj => obj.value ? `${obj.marker} ${obj.seriesName}: <strong>${obj.value[1].toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</strong><br />` : "").join("");
                return `${moment(params[0].axisValue).format("ll")}<br />` + tooltipStrings;
            }
        },
    }}
    return (
        <div style={{width: "100%"}}>
            <ChartTitle 
                title={`Estatísticas de ${props.chartsAux.unit_delta} em ${props.chartsAux.unit_delta} ${props.chartsAux.unit_name} da série completa a cada hora`}
                csvName="estatísticas_de_30_em_30_minutos_diariamente_da_série_completa.csv"
                data={jsonDataToCSV([meanSeries, minSeries, maxSeries], ["Média", "Mínimo", "Máximo"])} />
            <ReactECharts option={opt} 
                          style={{height: '300px'}} 
                          opts={{renderer: 'svg', 
                          locale: 'PTBR'}} >
            </ReactECharts>
        </div>
    );
}

export { CompleteSeriesPlot, 
        CompleteSeriesPlotDates,
        CompleteSeriesAggregatedYearlyPlot,
        CompleteSeriesAggregatedMonthlyPlot,
        CompleteSeriesAggregatedDailyPlot,
        CompleteSeriesAggregatedHourlyPlot,
        CompleteSeriesMeanMinMaxDailyPlot,
        CompleteSeriesMeanMinMaxHourlyPlot };