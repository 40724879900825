import React, { useState, useRef, useEffect } from 'react';
import SeriesInputSubForm, { validation } from 'components/Forms/SeriesInputSubForm';
import Button from 'components/UI/Button';
import { Redirect, useHistory } from 'react-router-dom';
import { Formik, Form, validateYupSchema } from 'formik';
import * as yup from 'yup';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as actionTypes from 'store/actions';
import { connect } from 'react-redux';
import FullscreenModal from 'components/UI/FullscreenModal';
import SeriesTable from 'components/UI/SeriesTable';
import Modal from 'components/UI/Modal'
import SeriesIndividualVisualization from 'components/UI/SeriesIndividualVisualization';

const SeriesInput = (props) =>  {
    const formikRef = useRef();
    const [submitted, setSubmitted] = useState(false);
    const [nextPage, setNextPage] = useState(null);
    const [visualizeSeries, setVisualizeSeries] = useState(null);

    const history = useHistory();

    const initialValues = {
        series: props.series ? props.series : [],
    };

    useEffect(() => {}, [formikRef])

    const validationSchema = yup.object().shape({
        series: validation,
        explanatorySeries: validation
    });

    const updateSeriesState = (newSeries) => {
        formikRef?.current?.setFieldValue("series", newSeries);  
        props.setSeries(newSeries);
    }

    return (submitted ? <Redirect push to={nextPage} /> : 
        <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            // validationSchema={validationSchema}
            validateOnChange={true}
            validateOnBlur={false}
            onSubmit={(values) => {
                props.setSeries(values.series);
                props.setCharts(values.charts);
                setSubmitted(true);
            }}
            enableReinitialize
        >
            { ({ values, isValid }) => 
            <Form>
                <ToastContainer style={{zIndex:12000}}/>
                <div style={{display: "flex", justifyContent: "center", marginTop: 30}}>
                    {/* <FullscreenModal button="Importar da base de dados"></FullscreenModal> */}
                    <FullscreenModal button="Importar de arquivos CSV" style={{marginLeft: 30}}>
                        <SeriesInputSubForm name={'series'} type="-" title="Importação de séries"/>
                    </FullscreenModal>
                </div>
                <div style={{marginTop: 30}}>    
                    <SeriesTable title="Séries importadas" seriesArray={formikRef?.current?.values?.series} setSeriesArray={updateSeriesState} setVisualizeSeries={setVisualizeSeries} />
                </div>
                {
                    <Modal className="Modal BigModal" show={visualizeSeries !== null} modalClosed={() => setVisualizeSeries(null)}>
                        {
                            visualizeSeries && <SeriesIndividualVisualization seriesMeta={visualizeSeries} />
                        }
                    </Modal>
                }
                <div style={{marginTop: 30}}>
                    {!isValid && <p style={{color: 'rgb(255, 116, 39)'}}>{'\u26A0'} Atenção: há campos que ainda não foram preenchidos</p>}
                    <div>
                        <Button 
                            type="button"
                            onClick={() => {
                                history.push(props.prevPage);
                            }}>
                                Voltar
                        </Button>
                        <Button 
                            type="submit" 
                            style={{float: 'right', marginLeft: 30}}
                            disabled={values.series.length === 0}
                            onClick={() => {
                                setNextPage(props.nextPage)
                            }}>
                                Criação de análises
                        </Button>
                    </div>
                </div>
            </Form>
            } 
        </Formik>
    );
}

const mapStateToProps = state => {
    return {
        series: state.series
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setSeries: (series) => dispatch({type: actionTypes.STORE_SERIES, new_series: series}),
        setCharts: (charts) => dispatch({type: actionTypes.STORE_CHARTS_AUX, new_charts: charts}),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SeriesInput);
