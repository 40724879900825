import React, {useEffect, useRef} from 'react';
import { values_as_list } from './EChartsHelper';
import ReactECharts from 'echarts-for-react';
import * as quantile from 'distributions-normal-quantile';

function getPlotData(name, timestamps, values, attr={}) {
    const plot = {
        data: timestamps.map((el,i)=>[el, values[i]]),
        emphasis: {
            lineStyle: {
                width: 1
            }
        },
        animation: false,
        type: 'line',
        name: name,
        showSymbol: false,
        lineStyle: { color: 'rgba(0, 11, 113, 1)'},
        itemStyle: { color: 'rgba(0, 11, 113, 1)'},
        ...attr
    };

    return plot;
}

function getQQPlotData(series, large=false) {
    const maxSize = 1000;
    const seriesSize = series.length;
    const asc = array => array.sort((a, b) => a - b)
    .filter((_, i) => {
        return i % Math.ceil(seriesSize/maxSize) === 0;
    });
    const sum = array => array.reduce((a, b) => a + b, 0);
    const mean = array => sum(array) / array.length;
    const std = (array) => {
        const arraybar = mean(array);
        const diffArray = array.map(a => (a - arraybar) ** 2);
        return Math.sqrt(sum(diffArray) / (array.length - 1));
    };

    const values = series;
    const sampleMean = mean(values);
    const sampleStd = std(values);
    const zValues = asc(values).map(val => (val-sampleMean)/sampleStd);

    const N = zValues.length;
    const N4 = Math.floor(N/4);
    const quants = zValues.map((_, i) => (i+0.5) / N);
    const theorethicalValues = quantile(quants);

    const angularCoef = (zValues[3*N4]-zValues[N4])/(theorethicalValues[3*N4]-theorethicalValues[N4]);
    const linearCoef = zValues[N4] - angularCoef * theorethicalValues[N4];

    const qqline = x => angularCoef * x + linearCoef;
    
    const x0 = Math.min(...theorethicalValues);
    const x1 = Math.max(...theorethicalValues);

    const linex = [x0, x1];
    const liney = linex.map(x => qqline(x));

    return [
        getPlotData(
            "qq-plot",
            theorethicalValues,
            zValues,
            {
                type: 'scatter',
                mode: 'markers',
                marker: {
                    size: large ? 20 : 8
                },
                animation: !large,
                showlegend: false,
            }
        ),
        getPlotData(
            "qq-line",
            linex,
            liney,
            {   
                line: {
                    width: large ? 4 : 2,
                    color: 'rgba(0, 11, 113, 1)'
                },
                showlegend: false,
                animation: !large,
                hovertemplate: undefined,
                hoverinfo: 'skip',
            }
        )
    ]
}

const QQPlot = (props) => {
    const echartsRef = useRef();
    useEffect(() => {
        if (echartsRef.current) {
            const instance = echartsRef.current.getEchartsInstance();
           
            const dataUrl = instance.getDataURL({
                type: "jpeg",
                width:'700px',
                height: '200px',
                backgroundColor:'#fff',
                excludeComponents:['toolbox']
            });
            
            if (props.getReportImage) { 
                props.getReportImage( { data: dataUrl, format: 'jpg' });
            } 
        }
    }, [echartsRef]);

    const data = getQQPlotData(props.series);

    return (
        <>
            <h3>{props.title}</h3>
            <ReactECharts
                style={props.report ? {width: 1000, height:300}: {}}
                ref={echartsRef}
                option = {{
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            animation: false,
                            label: {
                                backgroundColor: '#ccc',
                                borderColor: '#aaa',
                                borderWidth: 1,
                                shadowBlur: 0,
                                shadowOffsetX: 0,
                                shadowOffsetY: 0,
                                color: '#222',
                            },
                            formatter: function(params) {
                                return params["value"].toLocaleString('pt-BR', {minimumFractionDigits: 3, maximumFractionDigits: 3});
                            }
                        },
                        formatter: function(params) {
                            const tooltipStrings = params.map(obj => obj.value ? `${obj.marker} ${obj.seriesName}: <strong>${obj.value[1].toLocaleString('pt-BR', {minimumFractionDigits: 3, maximumFractionDigits: 3})}</strong><br />` : "").join("");
                            return `${params[0].axisValue.toLocaleString('pt-BR', {minimumFractionDigits: 3, maximumFractionDigits: 3})}<br />` + tooltipStrings;
                        }
                    },
                    animation: false,
                    force: {
                        layoutAnimation: false
                    },
                    grid: { top: 30, right: 50, bottom: 50, left: 50 },
                    toolbox: {
                        right: 30,
                        feature: {
                            dataZoom: {},
                            saveAsImage: {}
                        }
                    },
                    xAxis : {
                        name: "Quantil teórico",
                        nameLocation: "middle",
                        boundaryGap: false,
                        nameTextStyle: {
                            padding: [15, 15, 15, 15]
                        },
                    },
                    yAxis: props.yAxis ? props.yAxis : {
                            scale: true,
                            name: 'Quantil amostral',
                            nameLocation: "middle",
                            nameTextStyle: {
                                padding: [15, 15, 15, 15]
                            },
                    },
                    legend: {
                        type: props.report ? 'plain' : 'scroll',
                        itemWidth: 10,
                        left: 'center',
                        data: data.map(series => series.name)
                    },
                    series: data,
                }} 
            />
        </>
    );
}

export default QQPlot;