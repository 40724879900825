import { useState, useEffect } from 'react';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import trash from 'assets/images/trash.svg';
import { connect } from 'react-redux';
import * as actionTypes from 'store/actions';
import { ToastContainer, toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import Card from 'components/UI/Card';
import Button from 'components/UI/Button';
import { useHistory } from 'react-router-dom';
import Collapsible from 'components/UI/Collapsible';
import Modal from 'components/UI/Modal';
import * as moment from 'moment';
import api from 'apis/api';

const ReportCard = (props) => {
    const history = useHistory();
    const [openDeleteModal, setOpenDeleteModal] = useState(false)

    const getReportData = () => {
        // let formData = new FormData();
        // formData.append("study_id", props.id);
        // formData.append("user_id", props.user_id);
        // api.post("algorithms/retrieve_study", formData, {
        //     headers: {
        //         'Content-Type': 'multipart/form-data'
        //     }
        // }).then(response => {
        //     parseResponseData(response.data);
        //     props.setFrontier(response.data.historic_frontier);
        //     props.setExplanatory(response.data.explanatory_variables);
        //     props.setParameters(response.data.parameters);
        //     props.setOutput(response.data.output);
        //     history.push('/study-results');
        // }).catch(error => {
        //     const options = {
        //         autoClose: false,
        //         hideProgressBar: false,
        //         position: toast.POSITION.TOP_RIGHT,
        //     };
        //     toast.error("Houve um erro ao consultar o estudo", options);
        // });
    }
    const deleteStudy = () => {
        // let formData = new FormData();
        // formData.append("study_id", props.id);
        // formData.append("user_id", props.user_id);
        // api.post("user/delete_study/", formData, {
        //     headers: {
        //         'Content-Type': 'multipart/form-data'
        //     }
        // }).then(response => {
        //     toast.success("Estudo excluído com sucesso");
        //     setOpenDeleteModal(false);
        //     props.onRemove(props.id);
        // }).catch(error => {
        //     const options = {
        //         autoClose: false,
        //         hideProgressBar: false,
        //         position: toast.POSITION.TOP_RIGHT,
        //     };
        //     toast.error("Houve um erro ao excluir o estudo", options);
        // });
    }
    return (
        <Card className="cardhover">
            <Modal show={openDeleteModal} modalClosed={() => setOpenDeleteModal(false)}>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <h2 style={{color: "rgba(254, 57, 36, 1)"}}>Tem certeza que deseja excluir esse estudo?</h2>
                    <div>
                        <Button onClick={() => setOpenDeleteModal(false)} type="button" className="blue-button">Cancelar</Button>
                        <Button onClick={deleteStudy} type="button" className="red-button" style={{marginLeft: 20}}>Excluir estudo</Button>
                    </div>
                </div>
            </Modal>
            <div style={{display: "flex", flexDirection: "column"}}>
                <div>
                    <img style={{float: "right", width: 25, height: 25, cursor: "pointer"}} 
                            src={trash} 
                            alt="Apagar estudo"
                            onClick={() => {setOpenDeleteModal(true)}}/> 
                    <h2 style={{marginTop: 0}}>{props.title}</h2>
                </div>
                <Collapsible trigger="Parâmetros do estudo" tagName="h3">
                    <ul>
                        {/* <li>Tipo do contrato: <strong>{props.parameters.contract}</strong></li>
                        <li>Data de fim da simulação: <strong>{moment(props.parameters.endDate, "YYYY-MM").format("MM/YYYY")}</strong></li>
                        <li>Valor da tarifa (em R$/MW): <strong>{props.parameters.taxValue}</strong></li>
                        <li>Regime tarifário: <strong>{props.parameters.taxRegime}</strong></li>
                        <li>Perfil de risco: <strong>{mapRiskProfileString(props.parameters.risk)}</strong></li>
                        <li>Considerar penalidade de sobrecontratação: <strong>{props.parameters.admitsUnder ? "sim" : "não"}</strong></li> */}
                    </ul>
                </Collapsible>
                <div>
                    <Button style={{float: "right"}} onClick={getReportData}>Editar estudo</Button>
                    <h3 style={{marginBottom: 0}}>{props.date}</h3>
                </div>
            </div>
        </Card>
    );
};

const MyStudies = (props) => {
    const [studyList, setStudyList] = useState([]);
    const [loading, setLoading] = useState(true);

    function handleRemove(id) {
        const newList = studyList.filter((item) => item.study_id !== id);
        setStudyList(newList);
    }    

    useEffect(() => {
        if (props.user_id) {
            // let formData = new FormData();
            // formData.append("user_id", props.user_id);
            // api.post("user/get_studies/", formData, {
            //     headers: {
            //         'Content-Type': 'multipart/form-data'
            //     }
            // }).then(response => {
            //     setStudyList(response.data.studies);
            //     setLoading(false);
            // }).catch(error => {
            //     const options = {
            //         autoClose: false,
            //         hideProgressBar: false,
            //         position: toast.POSITION.TOP_RIGHT,
            //     };
            //     toast.error("Houve um erro ao consultar sua lista de estudos", options);
            // });
        }
    }, [props.user_id])
    
    studyList.sort((a, b) => moment(a.study_metadata.date) <= moment(b.study_metadata.date) ? 1 : -1);
    const cards = studyList.map(report => {
        return <ReportCard 
                            // title={report.study_metadata.name} 
                            // date={moment(report.study_metadata.date).format("LL")} 
                            // parameters={report.study_metadata.parameters}
                            // id={report.study_id} key={report.study_id} 
                            onRemove={handleRemove} {...props} 
                />
    });
    return (
        <div>
            <ToastContainer />
            <h2>Meus estudos</h2>
            { (!loading && cards.length === 0) ? <h2>Você ainda não possui nenhum estudo salvo.</h2> : cards }
            {
                loading && <Loader type="Oval" 
                color="#f86e2e"
                height={40} 
                width={40}  />
            }
        </div>
    );
}

// const mapStateToProps = state => {
//     return {
//         user_id: state.user_id
//     }
// };

// const mapDispatchToProps = dispatch => {
//     return {
//         setFrontier: (frontier) => dispatch({type: actionTypes.STORE_FRONTIER, frontier: frontier}),
//         setExplanatory: (explanatory) => dispatch({type: actionTypes.STORE_EXPLANATORY, explanatory: explanatory}),
//         setParameters: (parameters) => dispatch({type: actionTypes.STORE_PARAMETERS, parameters: parameters}),
//         setOutput: (output) => dispatch({type: actionTypes.STORE_OUTPUT, output: output}),
//     }
// }

// export default withAuthenticationRequired(connect(mapStateToProps, mapDispatchToProps)(MyStudies), {
//     onRedirecting: () => <Loader type="Oval" 
//                                 color="#f86e2e"
//                                 height={40} 
//                                 width={40}  />,
// });

export default MyStudies;