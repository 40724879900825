import React from 'react';
import { Link } from "react-router-dom";
import logo from 'assets/images/logo.svg';
import { useAuth0 } from '@auth0/auth0-react';

const Navbar = () => {
    const { isAuthenticated, isLoading, loginWithRedirect, logout } = useAuth0();
    
    return (
        <div className="Navbar">
            <ul>
                <li><Link to='/' style={{margin: 0, padding: 0}}><img style={{height: '30px', marginTop: 15, marginBottom: 10}} src={logo} alt="" /></Link></li>
                {/* {
                    !isLoading && 
                    (!isAuthenticated ? 
                        <li className="Navitem" style={{float: "right"}} onClick={() => loginWithRedirect()}><span>Log in</span></li> :
                        (
                            <>
                                <li className="Navitem" style={{float: "right"}} onClick={() => logout({ returnTo: window.location.origin })}><span>Log out</span></li>
                            </>
                        )
                    )
                } */}
            </ul>
        </div>
    );
};

export default Navbar;