import { useState } from 'react';
import Calendar from 'components/UI/Calendar';
import moment from 'moment';
import { calculateStartDate } from 'components/UI/FormikCalendar';
import { CSVLink } from 'react-csv';
import Button from 'components/UI/Button';

const mapGranularityToName = (granularity) => {
    switch (granularity.granularity) {
        case 2:
            return "day";
        case 4:
            return "month";
        case 5:
            return "year";
    }
}

const mapGranularityTimeFormat = (granularity) => {
    switch (granularity.granularity) {
        case 2:
            return "YYYY-MM-DD";
        case 4:
            return "YYYY-MM";
        case 5:
            return "YYYY";
    }
}

var enumerateDaysBetweenDates = function(startDate, endDate, granularity) {
    var dates = [];

    var currDate = moment(startDate).startOf(mapGranularityToName(granularity));
    var lastDate = moment(endDate).startOf(mapGranularityToName(granularity));

    dates.push(currDate.clone().toDate());
    while(currDate.add(1, `${mapGranularityToName(granularity)}s`).diff(lastDate) <= 0) {
        dates.push(currDate.clone().toDate());
    }

    return dates;
};

function randn_bm() {
    var u = 0, v = 0;
    while(u === 0) u = Math.random(); //Converting [0,1) to (0,1)
    while(v === 0) v = Math.random();
    return Math.sqrt( -2.0 * Math.log( u ) ) * Math.cos( 2.0 * Math.PI * v );
}

const generateRandomWalk = (initialValue, size) => {
    let currentValue = initialValue;
    const randomWalk = [];
    for (let i = 0; i < size; ++i) {
        currentValue += randn_bm();
        randomWalk.push(currentValue);
    }
    return randomWalk;
}

const generateExampleCSV = (startDate, endDate, granularity) => {
    const header = ["Timestamps", "Série 1", "Série 2"];
    const allDates = enumerateDaysBetweenDates(startDate, endDate, granularity);
    const timestamps = allDates.map(date => moment(date).format(mapGranularityTimeFormat(granularity)))
    const series1 = generateRandomWalk(100, timestamps.length);
    const series2 = generateRandomWalk(100, timestamps.length);
    const values = [series1, series2];
    
    const rows = timestamps.map((ts, index) => [ts, ...values.map(v => v[index])]);
    const table = [header, ...rows];

    const csvContent = "data:text/csv;charset=utf-8," + table.map(e => e.join(",")).join("\n");

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "exemplo.csv");
    document.body.appendChild(link);

    link.click();
}

const ExampleCSVComponent = (props) => {
    const granularityArray = [
        {
            granularity: 2,
            unit_name: "dia",
            unit_delta: "1",
            frequency: 365
        },
        {
            granularity: 4,
            unit_name: "mês",
            unit_delta: "1",
            frequency: 12
        },
        {
            granularity: 5,
            unit_name: "ano",
            unit_delta: "1",
            frequency: 1
        }
    ]
    const [granularityIndex, setGranularityIndex] = useState(0);
    const [startDate, setStartDate] = useState(moment().toDate());
    const endDateInitialValue = calculateStartDate(moment().toDate(), granularityArray[granularityIndex], true, 365);
    const [endDate, setEndDate] = useState(endDateInitialValue);

    return (
        <div>
            <h3 style={{marginBottom: 0}}>Selecione a granularidade do CSV de exemplo</h3>
            <select value={granularityIndex} onChange={e => {
                setGranularityIndex(e.target.value);
            }}>
                <option key={"diária"} value={0}>Diária</option>
                <option key={"mensal"} value={1}>Mensal</option>
                <option key={"anual"} value={2}>Anual</option>
            </select>
            <div className="AdvancedParametersContainer" style={{marginBottom: 30}}>
                <div className="AdvancedParametersItem">
                    <h3 style={{marginBottom: 0}}>Data de início</h3>
                    <Calendar 
                        value={startDate}
                        setValue={(date) => {
                            setStartDate(moment(date).toDate());
                        }}
                        granularity={granularityArray[granularityIndex]}
                    />
                </div>
                <div className="AdvancedParametersItem">
                    <h3 style={{marginBottom: 0}}>Data de fim</h3>
                    <Calendar 
                        value={endDate}
                        setValue={(date) => {
                            setEndDate(moment(date).toDate());
                        }}
                        isPrev
                        granularity={granularityArray[granularityIndex]}
                    />
                </div>
            </div>
            {/* <CSVLink
                enclosingCharacter={'p'}
                data={generateExampleCSV(startDate, endDate, granularityArray[granularityIndex])} 
                filename={"serie_exemplo.csv"}
                className="Button"
                style={{marginTop: 30, textDecoration: "none"}}
                onClick={() => props.closeModal()}
            ><strong>{"\u2913"}</strong> Exemplo CSV</CSVLink> */}
            <Button type="button" onClick={() => generateExampleCSV(startDate, endDate, granularityArray[granularityIndex])}><strong>{"\u2913"}</strong> Exemplo CSV</Button>
            <Button type="button" style={{marginLeft: 30}} onClick={() => props.closeModal()}>Cancelar</Button>
        </div>
    );
}

export default ExampleCSVComponent;