import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import moment from "moment";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import { date } from "yup/lib/locale";
import pt from 'date-fns/locale/pt';
registerLocale('pt', pt)

export const calculateStartDate = (startDate, granularity, isPrev, stepsAhead=1) => {
    let date = moment(startDate).toDate();
    if (isPrev) {
        switch (granularity) {
            case 5:
                date = moment(date).add(stepsAhead, 'years').toDate();
                break;
            case 4:
                date = moment(date).add(stepsAhead, 'months').toDate();
                break
            default:
                date = moment(date).add(stepsAhead, 'days').toDate();
                break
        }
    }
    return date
}

const FormikCalendar = (props) => {
    const formatDate = (granularity) => {
        const id = granularity.granularity
        switch (id) {
            case 0:
                return "dd/MM/yyyy HH:mm";
            case 1:
                return "dd/MM/yyyy HH";
            case 2:
                return "dd/MM/yyyy";
            case 3:
                return "dd/MM/yyyy";
            case 4:
                return "MM/yyyy";
            case 5:
                return "yyyy";
            default:
                return "dd/MM/yyyy HH:mm";
        }
    }

    const calendarGranularity = (granularity) => {
        const id = granularity.granularity;
        let props_dict = {};
        switch (id) {
            case 0:
                props_dict["showTimeSelect"]=true;
                props_dict["timeFormat"]="HH:mm";
                props_dict["timeIntervals"]=parseInt(granularity.unit_delta);
                props_dict["timeCaption"]="Tempo";
                break;
            case 1:
                props_dict["showTimeSelect"]=true;
                props_dict["timeFormat"]="HH";
                props_dict["timeIntervals"]=parseInt(granularity.unit_delta);
                props_dict["timeCaption"]="Tempo";
                break;
            case 4:
                props_dict["showMonthYearPicker"]=true
                break;
            case 5:
                props_dict["showYearPicker"]=true;
                break;
            default:
                break
        }
        return props_dict
    }

    useEffect(() => {
        props.setValue(props.formikValue ? moment(props.formikValue).toDate() : calculateStartDate(props.startDate, props.granularity.granularity, props.isPrev));
    }, [])

    return (
        <DatePicker
            disabled={props.disabled}
            selected={props.formikValue ? moment(props.formikValue).toDate() : calculateStartDate(props.startDate, props.granularity.granularity, props.isPrev)}
            onChange={(date) => {
                props.setValue(date);
            }}
            locale="pt"
            dateFormat={props.granularity ? formatDate(props.granularity) : "dd/M/yyyy HH:m"}
            showYearDropdown
            {...calendarGranularity(props.granularity)}
        />
  );
};

export default FormikCalendar;