export const ARIMA = {
    name: "ARIMA",
    auto: true,
    searchOption: "stepwise",
    p: 0,
    d: 0,
    q: 0,
    P: 0,
    D: 0,
    Q: 0,
}

export const ETS = {
    name: "ETS"
}

export const Naive = {
    name: "Naive"
}

export const SeasonalNaive = {
    name: "Seasonal Naive"
}

export const NeuralNetworkAutoregression = {
    name: "Neural Network Autoregression"
}