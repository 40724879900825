import Button from 'components/UI/Button';
import { mapGranularityName, mapGranularityTimeFormat } from 'components/Plots/EChartsHelper';

const graphObjConstructor = chartsAux => [
    {
        type: "Completa", 
        aggregated: [
            {
                type: `Original - ${mapGranularityName(chartsAux.granularity)}`,
                graphIndex: 0
            },
            {
                type: "Horária",
                requiredGranularity: 0,
                aggregatedBy: [
                    {
                        type: "Soma", 
                        graphIndex: 1
                    },
                    {
                        type: `Estatísticas de ${chartsAux.unit_delta} em ${chartsAux.unit_delta} ${chartsAux.unit_name}`,
                        graphIndex: 2
                    }
                ]
            },
            {
                type: "Diária",
                requiredGranularity: 1,
                aggregatedBy: [
                    {
                        type: "Soma", 
                        graphIndex: 3
                    },
                    {
                        type: `Estatísticas de ${chartsAux.unit_delta} em ${chartsAux.unit_delta} ${chartsAux.unit_name}`,
                        graphIndex: 4
                    }
                ]
            }, 
            {
                type: "Mensal",
                requiredGranularity: 3,
                aggregatedBy: [
                    {
                        type: "Soma", 
                        graphIndex: 5
                    }
                ]
            },
            {
                type: "Anual",
                requiredGranularity: 4,
                aggregatedBy: [
                    {
                        type: "Soma", 
                        graphIndex: 6
                    }
                ]
            },
        ]
    },
    {
        type: "Anuais",
        requiredGranularity: 4,
        aggregated: [
            {
                type: `Original - ${mapGranularityName(chartsAux.granularity)}`,
                graphIndex: 7
            },
            {
                type: "Horária",
                requiredGranularity: 0,
                aggregatedBy: [
                    {
                        type: "Soma", 
                        graphIndex: 8
                    }
                ]
            },
            {
                type: "Diária",
                requiredGranularity: 1,
                aggregatedBy: [
                    {
                        type: "Soma", 
                        graphIndex: 9
                    },
                    {
                        type: `Estatísticas de ${chartsAux.unit_delta} em ${chartsAux.unit_delta} ${chartsAux.unit_name}`,
                        graphIndex: 10
                    }
                ]
            },
            {
                type: "Mensal",
                requiredGranularity: 3,
                aggregatedBy: [
                    {
                        type: "Soma", 
                        graphIndex: 11
                    },
                    {
                        type: "Estatísticas diárias",
                        graphIndex: 12
                    }
                ]
            },
        ]
    },
    {
        type: "Mensais",
        requiredGranularity: 3,
        aggregated: [
            {
                type: `Original - ${mapGranularityName(chartsAux.granularity)}`,
                graphIndex: 13
            },
            {
                type: "Horária",
                requiredGranularity: 0,
                aggregatedBy: [
                    {
                        type: "Soma", 
                        graphIndex: 14
                    }
                ]
            },
            {
                type: "Diária",
                requiredGranularity: 1,
                aggregatedBy: [
                    {
                        type: "Soma", 
                        graphIndex: 15
                    }
                ]
            },
        ]
    },
    {
        type: "Semanais",
        requiredGranularity: 2,
        aggregated: [
            {
                type: `Original - ${mapGranularityName(chartsAux.granularity)}`,
                graphIndex: 16
            },
            {
                type: "Horária",
                requiredGranularity: 0,
                aggregatedBy: [
                    {
                        type: "Soma", 
                        graphIndex: 17
                    }
                ]
            },
            {
                type: "Diária",
                requiredGranularity: 1,
                aggregatedBy: [
                    {
                        type: "Soma", 
                        graphIndex: 18
                    }
                ]
            },
        ]
    },
    {
        type: "Diárias",
        requiredGranularity: 1,
        aggregated: [
            {
                type: `Original - ${mapGranularityName(chartsAux.granularity)}`,
                graphIndex: 19
            },
            {
                type: "Horária",
                requiredGranularity: 0,
                aggregatedBy: [
                    {
                        type: "Soma", 
                        graphIndex: 20
                    }
                ]
            }
        ]
    }
]

const GraphSelector = (props) => {
    const selectedGraph = props.selectedGraph;
    const setSelectedGraph = props.setSelectedGraph;
    const selectedAggregation = props.selectedAggregation;
    const setSelectedAggregation = props.setSelectedAggregation;
    const selectedAggregationType = props.selectedAggregationType;
    const setSelectedAggregationType = props.setSelectedAggregationType;

    const selectedGraphHandler = (event) => {
        setSelectedAggregation(null);
        setSelectedAggregationType(null);
        setSelectedGraph(event.target.value);
    }

    const selectedAggregationHandler = (event) => {
        if (graphObj?.[selectedGraph]?.aggregated?.[event.target.value]?.aggregatedBy?.length === 1)
            setSelectedAggregationType(0);
        else
            setSelectedAggregationType(null);
        setSelectedAggregation(event.target.value);
    }

    const graphObj = graphObjConstructor(props.chartsAux);
    const aggregatedGraph = graphObj?.[selectedGraph]?.aggregated?.[selectedAggregation]?.graphIndex;
    const aggregatedSubGraph = graphObj?.[selectedGraph]?.aggregated?.[selectedAggregation]?.aggregatedBy?.[selectedAggregationType]?.graphIndex;

    return (
        <>
            <div style={{display: "flex"}}>
                <div style={{flexGrow: 1}}>
                    <h2>Curva(s)</h2>
                    <ul onClick={selectedGraphHandler} className="list-group">
                        {
                            graphObj.map((obj, i) => {
                                const selectedClass = (selectedGraph === i) ? "list-item-selected" : "";
                                if (obj.requiredGranularity === undefined || props.chartsAux.granularity <= obj.requiredGranularity)
                                    return <li value={i} key={obj.type} className={selectedClass}>{obj.type}</li>
                                else
                                    return null;
                            })
                        }
                    </ul>
                </div>
                <div style={{flexGrow: 1, marginLeft: 20}}>
                    <h2>Unidade de tempo</h2>
                    <ul onClick={selectedAggregationHandler} className="list-group">
                        {
                            // graphObj[selectedGraph]?.aggregated?.length > 0 ?
                            graphObj[selectedGraph]?.aggregated?.map((obj, i) => {
                                const selectedClass = (selectedAggregation === i) ? "list-item-selected" : "";
                                if (obj.requiredGranularity === undefined || props.chartsAux.granularity <= obj.requiredGranularity)
                                    return <li value={i} key={obj.type} className={selectedClass}>{obj.type}</li>
                                else
                                    return null;
                            }) 
                            // :
                            // <div className="border">
                            //     <p>Não</p>
                            // </div>
                        }
                    </ul>
                </div>
                <div style={{flexGrow: 1, marginLeft: 20}}>
                    <h2>Agregação dos dados</h2>
                    <ul onClick={event => setSelectedAggregationType(event.target.value)} className="list-group">
                        {
                            graphObj[selectedGraph]?.aggregated[selectedAggregation]?.aggregatedBy?.map((obj, i) => {
                                const selectedClass = (selectedAggregationType === i) ? "list-item-selected" : "";
                                if (obj.requiredGranularity === undefined || props.chartsAux.granularity <= obj.requiredGranularity)
                                    return <li value={i} key={obj.type} className={selectedClass}>{obj.type}</li>
                                else
                                    return null;
                            })
                        }
                    </ul>
                </div>
            </div>
            <div>
                {
                    (aggregatedGraph !== null) ? props.graphs[aggregatedGraph] : null
                }
                {
                    (aggregatedSubGraph !== null) ? props.graphs[aggregatedSubGraph] : null
                }
                {
                    ((aggregatedGraph != null) || (aggregatedSubGraph != null)) &&
                    <Button type="button" style={{marginTop: 0}} onClick={() => {
                        if (aggregatedGraph != null) {
                            props.setFixedGraph(aggregatedGraph);
                        }
                        if (aggregatedSubGraph != null) {
                            props.setFixedGraph(aggregatedSubGraph);
                        }
                    }}>Fixar gráfico</Button>
                }
                {/* {
                    (aggregatedGraph !== null || aggregatedSubGraph !== null) ? 
                    <CSVLink
                        data={formatTableData(props.tableData)} 
                        filename={"relatorio_contratacao.csv"}
                        className="blue-button"
                        style={{float: "right"}}>{"\u2913"} Arquivo CSV</CSVLink> : null
                } */}
            </div>
        </>
    );
}

export default GraphSelector;