import { useState, useEffect } from 'react';
import * as moment from 'moment';
import { BsFillTrashFill, BsFillEyeFill, BsGearFill } from "react-icons/bs";
import ReactPaginate from 'react-paginate';
import Info from 'components/UI/Info';
import Card from 'components/UI/Card';
import FullscreenModal from 'components/UI/FullscreenModal';
import ARIMASubForm from 'components/Forms/ModelSubForms/ARIMASubForm';

const mapNameToSubForm = (name, props) => {
    switch (name) {
        case "ARIMA":
            return <ARIMASubForm {...props}/>;
        default:
            return null;
    }
}

const ModelsTable = (props) => {
    const [offset, setOffset] = useState(0);
    const [perPage] = useState(10);
    const [pageCount, setPageCount] = useState(0);

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const newOffset = selectedPage * perPage;
        setOffset(newOffset);
    };

    useEffect(() => {
        if (props.modelsArray)
            setPageCount(Math.ceil(props.modelsArray.length / perPage));
    }, [props.modelsArray, perPage])    

    return (
        <>
        {
            props.modelsArray && props.modelsArray.length > 0 ?
            <Card style={props.style}>
                { props.title && <h3 className="first-label">{props.title}</h3> }
                {props.children}
                <ul className="list-group list-group-nohover">
                    {
                        props.modelsArray.slice(offset, offset + perPage).map((model, i) => {
                            return (
                                <li key={i}>
                                    <div style={{display: "flex"}}>
                                        <span style={{width: "100%"}}><strong>{model.name}</strong></span>
                                        
                                        <Info style={{float: "right"}}>
                                            {/* <p style={{color: "white"}}><strong>Informações sobre {seriesMeta.name}</strong></p>
                                            <p style={{color: "white"}}>Granularidade: {mapGranularityName(seriesMeta.granularity.granularity)}, de {seriesMeta.granularity.unit_delta} em {seriesMeta.granularity.unit_delta} {seriesMeta.granularity.unit_name}</p>
                                            <p style={{color: "white"}}>Data de início: {moment(seriesMeta.start_date).format("lll")}</p>
                                            <p style={{color: "white"}}>Data de fim: {moment(seriesMeta.end_date).format("lll")}</p>
                                            <p style={{color: "white"}}>Arquivo de origem: {seriesMeta.origin_file}</p> */}
                                        </Info>

                                        {   
                                            mapNameToSubForm(model.name, null) && 
                                            <FullscreenModal style={{float: "right", marginLeft: 20}} button={<BsGearFill style={{cursor: "pointer"}} />} buttonClass="gearButton">
                                                {
                                                    mapNameToSubForm(model.name, {
                                                        analysisIndex: props.analysisIndex,
                                                        disabled: props.disabled
                                                    })
                                                }
                                            </FullscreenModal>
                                        }
                                        {
                                            props.setModelsArray &&
                                            <BsFillTrashFill style={{cursor: "pointer", float: "right", marginLeft: 20}} onClick={() => {
                                                let updatedValues = [...props.modelsArray];
                                                updatedValues.splice(i, 1);
                                                props.setModelsArray(updatedValues);
                                            }} />
                                        }
                                    </div>
                                </li>
                            );
                        })
                    }
                </ul>
                <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}/>
            </Card> :
            <Card style={props.style}>
                <h3>{props.emptyText ? props.emptyText : "Você ainda não selecionou nenhum modelo."}</h3>
            </Card>
        }
        </>
    );
};

export default ModelsTable;