import * as actionTypes from 'store/actions';

const initialState = {
    series: [],
    charts: [],
    study_id: null,
    analyses: [],
    analysesResults: [],
    rerender: 0
};

function getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.STORE_SERIES:
            return {
                ...state,
                series: action.new_series
            };
        case actionTypes.STORE_OUTPUT:
            return {
                ...state,
                output: action.new_output
            }
        case actionTypes.STORE_CHARTS_AUX:
            return {
                ...state,
                charts: action.new_charts
            }
        case actionTypes.STORE_STUDY_ID:
            return {
                ...state,
                study_id: action.study_id
            }
        case actionTypes.STORE_ANALYSES:
            return {
                ...state,
                analyses: action.analyses
            }
        case actionTypes.STORE_ANALYSES_RESULTS:
            return {
                ...state,
                analysesResults: action.analysesResults
            }
        case actionTypes.TRIGGER_RERENDER:
            return {
                ...state,
                rerender: getRandomIntInclusive(1, 2000000)
            }
        default:
            return state;
    }
};

export default reducer;
