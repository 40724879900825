import React, {useRef, useEffect} from 'react';
import EChartsReact from 'echarts-for-react';
import * as ecStat  from 'echarts-stat';

function getHistogramFromSeries(series, attr={}) {
    const bins = ecStat.histogram(series);
    
    const plots = {
        datasetIndex: 0,
        type: 'bar',
        barWidth: '98%',        
        encode: { x: 0, y: 1, itemName: 4 },
        lineStyle: { color: 'rgba(0, 11, 113, 1)'},
        itemStyle: { color: 'rgba(0, 11, 113, 1)'},   
        ...attr
    };

    return {'dataset': bins.data, 'series': plots};
}

const Histogram = (props) => {
    const echartsRef = useRef();
    useEffect(() => {
        if (echartsRef.current) {
            const instance = echartsRef.current.getEchartsInstance();
           
            const dataUrl = instance.getDataURL({
                type: "jpeg",
                width:'700px',
                height: '200px',
                backgroundColor:'#fff',
                excludeComponents:['toolbox']
            });
            
            if (props.getReportImage) { 
                props.getReportImage( { data: dataUrl, format: 'jpg' });
            } 
        }
    }, [echartsRef]);
    const {dataset, series} = getHistogramFromSeries(props.series);

    return (
        <>
            <h3>{props.title}</h3>
            <EChartsReact
                style={props.report ? {width: 1000, height:300} : {}}
                ref={echartsRef} 
                option= {{
                    grid : { top: 30, right: 50, bottom: 50, left: 50, containLabel: true, },
                    dataset: [{
                        source: dataset
                    },],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        },
                        formatter: function(params) {
                            const obj = params[0];
                            return `Observações: ${obj.value[1]}<br> Intervalo: ${obj.name}<br>`
                        },
                    },
                    toolbox: {
                        right: 30,
                        feature: {
                            dataZoom: {
                                yAxisIndex: 'none'
                            },
                            saveAsImage: {}
                        }
                    },
                    xAxis : {
                        type: 'value',
                        scale: true,
                        axisTick: {
                            alignWithLabel: false
                        },
                        axisLabel: {  interval: 0, minInterval: 1, },
                    },
                    yAxis: props.yAxis ? props.yAxis : {
                            type: 'value',
                            scale: true,
                            name: '',
                            nameLocation: "middle",
                            nameTextStyle: {
                                padding: [15, 30, 15, 15]
                            },
                    },
                    legend: {
                        type: props.report ? 'plain' : 'scroll',
                        itemWidth: 10,
                        left: 'center',
                        data: series.name
                    },
                    series: series
                }}
            />
        </>
    );
}

export default Histogram;