import moment from "moment";

function timestamps_as_list(dict_series) {
    return dict_series.map(point => point['timestamp']);
}

function values_as_list(dict_series) {
    return dict_series.map(point => point['value']);
}

function series_as_list(dict_series) {
    return dict_series.map(point => {
        return [point['timestamp'], point['value']]
    });
}

function forecast_historic_csv(plot_dict) {
    const header = ["Timestamp", ...plot_dict.map(model => model.name)];

    const historic_timestamps = timestamps_as_list(plot_dict[0].historic.series);
    const forecast_timestamps = timestamps_as_list(plot_dict[0].forecast.series);
    const raw_timestamps = [...historic_timestamps, ...forecast_timestamps];
    const timestamps = raw_timestamps.map(ts => moment(ts).format("YYYY-MM-DD hh:mm"));

    const modelValues = plot_dict.map(model => {
        const historic_values = values_as_list(model.historic.series);
        const forecast_values = values_as_list(model.forecast.series);
        const values = [...historic_values, ...forecast_values];
        return values;
    });
    const rows = timestamps.map((ts, index) => [ts, ...modelValues.map(v => v[index])]);
    return [header, ...rows];
}

function forecast_csv(plot_dict) {
    const header = ["Timestamp", ...plot_dict.map(model => model.name)];

    const raw_timestamps =  timestamps_as_list(plot_dict[0].forecast.series);
    const timestamps = raw_timestamps.map(ts => moment(ts).format("YYYY-MM-DD hh:mm"));

    const modelValues = plot_dict.map(model => {
        const values = values_as_list(model.forecast.series);
        return values;
    });
    const rows = timestamps.map((ts, index) => [ts, ...modelValues.map(v => v[index])]);

    return [header, ...rows];
}

function forecast_quantiles_csv(model_dict) {
    const header = ["Timestamp", "Forecast", "5-quantile", "20-quantile", "80-quantile", "95-quantile"];

    const raw_timestamps = timestamps_as_list(model_dict.forecast.series);
    const timestamps = raw_timestamps.map(ts => moment(ts).format("YYYY-MM-DD hh:mm"));

    const forecast_values = values_as_list(model_dict.forecast.series);
    const lower95_values = values_as_list(model_dict.lower95.series);
    const lower80_values = values_as_list(model_dict.lower80.series); 
    const upper80_values = values_as_list(model_dict.upper80.series);
    const upper95_values = values_as_list(model_dict.upper95.series);
    const values = [
        forecast_values, 
        lower95_values,
        lower80_values, 
        upper80_values,
        upper95_values
    ];
    const rows = timestamps.map((ts, index) => [ts, ...values.map(v => v[index])]);
    return [header, ...rows];
}

export { 
    timestamps_as_list, 
    values_as_list,
    series_as_list,
    forecast_historic_csv,
    forecast_csv,
    forecast_quantiles_csv
}
