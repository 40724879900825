import { useState } from 'react';
import ReactCollapsible from 'react-collapsible';

const Collapsible = (props) => {
    const [opened, setOpened] = useState(props.open)
    const CustomTag = `${props.tagName}`

    const handleOpening = () => {
        setOpened(!opened);
    }

    return (
        <div style={{...props.style}}>
            <CustomTag style={{float: 'left',
                        transition: '0.4s ease',
                        transform: opened ? 'rotate(90deg)' : 'rotate(0deg)',
                        margin: 0,
                        cursor: "pointer"}}
                        onClick={handleOpening}>{"\u25B6"}</CustomTag>
            <ReactCollapsible {...props} 
                                triggerTagName={props.tagName}
                                triggerStyle={{ marginTop: 0, 
                                                marginLeft: '1.5em',
                                                marginBottom: 0, 
                                                cursor: "pointer",
                                            }}
                                onOpening={() => {setOpened(true)}}
                                onClosing={() => {setOpened(false)}}
                                open={opened}>
                {props.children}
            </ReactCollapsible>
        </div>
    );
};

export default Collapsible;