import * as echarts from 'echarts';
import { CSVLink } from 'react-csv';
import langPTBR from './langPTBR';
import * as moment from 'moment';
echarts.registerLocale("PTBR", langPTBR);

export const mapGranularityName = (id) => {
    switch (id) {
        case 0:
            return "Sub-horária";
        case 1:
            return "Horária";
        case 2:
            return "Diária";
        case 3:
            return "Semanal";
        case 4:
            return "Mensal";
        case 5:
            return "Anual";
        default:
            return "Outro";
    }
}

export const mapGranularityTimeFormat = (id) => {
    switch (id) {
        case 0:
            return "lll";
        case 1:
            return "lll";
        case 2:
            return "ll";
        case 3:
            return "ll";
        case 4:
            return "MMM YYYY";
        case 5:
            return "MMM YYYY";
        default:
            return "lll";
    }
}

export const dateToString = (granularity, date) => {
    const granularityFormat = mapGranularityTimeFormat(granularity);
    return moment(date).format(granularityFormat);
}

export const compareDates = (date1, date2, granularity=5, isPrev=false) => {
    let dateAux=date1;
    if (isPrev) {
        const gran = granularity.granularity;
        const granDiff = parseInt(granularity.interval);
        switch (gran) {
            case 5:
                dateAux = moment(date1).add(granDiff, 'years').toDate();
                break;
            case 4:
                dateAux = moment(date1).add(granDiff, 'months').toDate();
                break;
            case 1:
                dateAux = moment(date1).add(granDiff, 'hours').toDate();
                break;
            case 0:
                dateAux = moment(date1).add(granDiff, 'minutes').toDate();
                break
            default:
                dateAux = moment(date1).add(granDiff, 'days').toDate();
                break
        }
    }

    if (moment(dateAux).isBefore(date2)) return -1;
    if (moment(dateAux).isSame(date2)) return 0;
    if (moment(dateAux).isAfter(date2)) return 1;
}

function timestamps_as_list(dict_series) {
    return dict_series.map(point => point ? point['timestamp'] : null);
}

function values_as_list(dict_series) {
    return dict_series.map(point => point ? point['value'] : null);
}

function series_as_list(dict_series) {
    return dict_series.map(point => point ? [point['timestamp'], point['value']] : null);
}

function series_as_dict(name, timestamps, values) {
    return {
        name: name,
        series: timestamps.map((ts, i) => { 
            return { timestamp: ts, value: values[i] }
        })
    };
}

function series_as_list_with_name(dict_series, name) {
    return dict_series.map(point => point ? [point['timestamp'], point['value'], name] : null);
}

function jsonDataToCSV(json_series, col_names) {
    const timestamps = timestamps_as_list(json_series[0]);
    const values = json_series.map(series => values_as_list(series));
    const rows = timestamps.map((ts, index) => [ts, ...values.map(v => v[index])]);
    const headers = ["timestamps", ...col_names]
    return [headers, ...rows];
}

const ChartTitle = (props) => (
    <div style={{display: "flex", justifyContent: "space-between", ...props.style}}>
        {
            props.title && 
            <h2 style={{marginBottom: 0, marginTop: props.noMargin ? 0 : 20}}>{props.title}</h2>
        }
        {/* { props.data && props.csvName &&
            <CSVLink
                data={props.data} 
            data={props.data} 
                data={props.data} 
                filename={props.csvName}
                className="blue-button">{"\u2913"} Arquivo CSV</CSVLink>
        } */}
    </div>
);

const defaultOptions = (props) => {
    return {
        toolbox: {
            feature: {
                dataZoom: {
                    filterMode: 'none',
                    type: "inside"
                },
                restore: {},
                saveAsImage: {}
            },
            right: 50
        },
        yAxis: {
            type: "value",
            axisLabel: {
                formatter: function(value) {
                    return value.toLocaleString('pt-BR');
                }
            },
            scale: true
        },
        tooltip: {
            trigger: 'axis',
            formatter: function(params) {
                return `${moment(params[0].axisValue).format("lll")}<br />
                        ${params[0].marker} ${params[0].seriesName}: <strong>${params[0].value[1].toLocaleString('pt-BR', {maximumFractionDigits: 2})}</strong>`;
            }
        },
        xAxis: {
            type: "time",
            boundaryGap: false
        },
        series: props.series ? [{
            data: series_as_list(props.series),
            type: "line",
            showSymbol: false,
            lineStyle: {
                width: 1
            },
            emphasis: {
                lineStyle: {
                    width: 1
                }
            },
        }] : []
    }
};

export { timestamps_as_list, 
        values_as_list, 
        series_as_list, 
        series_as_dict,
        series_as_list_with_name,
        jsonDataToCSV,
        defaultOptions,
        ChartTitle };