import React, { useState, useRef } from 'react';
import { defaultOptions, series_as_list, series_as_list_with_name, jsonDataToCSV, ChartTitle } from './EChartsHelper';
import { aggregateMonths, aggregateDailyMeanMonths } from './MonthlySeriesPlot';
import { aggregateDays, aggregateHours, aggregateMeanDays } from './DailySeriesPlot';
import ReactECharts from 'echarts-for-react';
import * as moment from 'moment';

// Yearly Plot
function convertYearlyGranularity(series) {
    let seriesData = [];
    let current = [];
    let currentTime = moment(series[0]['timestamp'].slice(0, 10)).year();
    for (let i = 0; i < series.length; ++i) {
        if (moment(series[i]['timestamp'].slice(0, 10)).year() != currentTime) {
            seriesData.push(current);
            current = [];
            currentTime = moment(series[i]['timestamp'].slice(0, 10)).year();
        }
        current.push(series[i]);
    }
    seriesData.push(current);
    return seriesData;
}

function aggregateYears(series) {
    const yearSeries = convertYearlyGranularity(series);
    const aggregatedYearSeries = yearSeries.map(series => {
        return {timestamp: series[0]["timestamp"].slice(0,4), ...series.reduce((prev, current) => {
                return {value: prev["value"] + current["value"]};
            })
        };
    });
    return aggregatedYearSeries;
}

const YearlySeasonalitySeriesPlot = (props) => {
    let firstYear = moment(props.series[0]['timestamp']).year();
    const yearlySeries = convertYearlyGranularity(props.series).map(series => series.map(point => {
        return {...point, timestamp: firstYear+point["timestamp"].slice(4)}
    }));
    
    const seriesDict = yearlySeries.map((series, index) => {
        return {
            name: `${firstYear+index}`,
            data: series_as_list(series),
            type: "line",
            showSymbol: false,
            symbol: "none",
            silent: true,
            animation: false,
            lineStyle: {
                width: 1
            },
            emphasis: {
                lineStyle: {
                    width: 1
                }
            }
        };
    });
    const opt = {...defaultOptions(props), ...{
        animation: false,
        force: {
            layoutAnimation: false
        },
        tooltip: {
            trigger: 'axis',
            formatter: function(params) {
                const tooltipStrings = params.map(obj => obj.value ? `${obj.marker} ${obj.seriesName}: <strong>${obj.value[1].toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</strong><br />` : "").join("");
                return `${moment(params[0].axisValue).format("DD [de] MMMM [às] h:mm")}<br />` + tooltipStrings;
            }
        },
        legend: {
            data: seriesDict.map(series => series.name)
        },
        xAxis: {
            type: "time",
            boundaryGap: false,
            axisLabel: {
                formatter: function(ts) {
                    return moment(ts).format("DD [de] MMM");
                }
            }
        },
        series: seriesDict
    }}
    return (
        <div style={{width: "100%"}}>
            <ChartTitle 
                title="Curvas anuais" 
                csvName="curvas_anuais.csv"
                data={jsonDataToCSV(yearlySeries, seriesDict.map(series => series.name))} />
            <ReactECharts option={opt} 
                          style={{height: '300px'}} 
                          opts={{locale: 'PTBR'}} >
            </ReactECharts>
        </div>
    );
}

// Aggregated Monthly Plot
const YearlySeasonalityAggregatedMonthlyPlot = (props) => {
    let firstYear = moment(props.series[0]['timestamp']).year();
    const yearlySeries = convertYearlyGranularity(props.series).map(series => series.map(point => {
        return {...point, timestamp: firstYear+point["timestamp"].slice(4)}
    }));
    const yearlyAggregatedSeries = yearlySeries.map(series => aggregateMonths(series));
    const seriesDict = yearlyAggregatedSeries.map((series, index) => {
        return {
            name: `${firstYear+index}`,
            data: series_as_list(series),
            type: "line",
            showSymbol: false,
            symbol: "none",
        };
    });
    const opt = {...defaultOptions(props), ...{
        animation: false,
        force: {
            layoutAnimation: false
        },
        tooltip: {
            trigger: 'axis',
            formatter: function(params) {
                const tooltipStrings = params.map(obj => obj.value ? `${obj.marker} ${obj.seriesName}: <strong>${obj.value[1].toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</strong><br />` : "").join("");
                return `${moment(params[0].axisValue).format("MMMM")}<br />` + tooltipStrings;
            }
        },
        legend: {
            data: seriesDict.map(series => series.name)
        },
        xAxis: {
            type: "time",
            boundaryGap: false,
            axisLabel: {
                formatter: function(ts) {
                    return moment(ts).format("MMM");
                }
            }
        },
        series: seriesDict
    }}
    
    return (
        <div style={{width: "100%"}}>
            <ChartTitle 
                title="Curvas anuais agregadas mensalmente" 
                csvName="curvas_anuais_agregadas_mensalmente.csv"
                data={jsonDataToCSV(yearlyAggregatedSeries, seriesDict.map(series => series.name))} />
            <ReactECharts option={opt} 
                          style={{height: '300px'}} 
                          opts={{locale: 'PTBR'}} >
            </ReactECharts>
        </div>
    );
}

// Aggregated Daily Plot
const YearlySeasonalityAggregatedDailyPlot = (props) => {
    let firstYear = moment(props.series[0]['timestamp']).year();
    const yearlySeries = convertYearlyGranularity(props.series).map(series => series.map(point => {
        return {...point, timestamp: firstYear+point["timestamp"].slice(4)}
    }));
    const yearlyAggregatedSeries = yearlySeries.map(series => aggregateDays(series));
    const seriesDict = yearlyAggregatedSeries.map((series, index) => {
        return {
            name: `${firstYear+index}`,
            data: series_as_list(series),
            type: "line",
            showSymbol: false,
            symbol: "none",
        };
    });
    const opt = {...defaultOptions(props), ...{
        animation: false,
        force: {
            layoutAnimation: false
        },
        tooltip: {
            trigger: 'axis',
            formatter: function(params) {
                const tooltipStrings = params.map(obj => obj.value ? `${obj.marker} ${obj.seriesName}: <strong>${obj.value[1].toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</strong><br />` : "").join("");
                return `${moment(params[0].axisValue).format("DD [de] MMMM")}<br />` + tooltipStrings;
            }
        },
        legend: {
            data: seriesDict.map(series => series.name)
        },
        xAxis: {
            type: "time",
            boundaryGap: false,
            axisLabel: {
                formatter: function(ts) {
                    return moment(ts).format("DD [de] MMM");
                }
            }
        },
        series: seriesDict
    }}
    
    return (
        <div style={{width: "100%"}}>
            <ChartTitle 
                title="Curvas anuais agregadas diariamente" 
                csvName="curvas_anuais_agregadas_diariamente.csv"
                data={jsonDataToCSV(yearlyAggregatedSeries, seriesDict.map(series => series.name))} />
            <ReactECharts option={opt} 
                          style={{height: '300px'}} 
                          opts={{locale: 'PTBR'}} >
            </ReactECharts>
        </div>
    );
}

// Aggregated Hourly Plot
const YearlySeasonalityAggregatedHourlyPlot = (props) => {
    let firstYear = moment(props.series[0]['timestamp']).year();
    const yearlySeries = convertYearlyGranularity(props.series).map(series => series.map(point => {
        return {...point, timestamp: firstYear+point["timestamp"].slice(4)}
    }));
    const yearlyAggregatedSeries = yearlySeries.map(series => aggregateHours(series));
    const seriesDict = yearlyAggregatedSeries.map((series, index) => {
        return {
            name: `${firstYear+index}`,
            data: series_as_list(series),
            type: "line",
            showSymbol: false,
            symbol: "none",
            lineStyle: {
                width: 1
            },
            emphasis: {
                lineStyle: {
                    width: 1
                }
            },
        };
    });
    const opt = {...defaultOptions(props), ...{
        animation: false,
        force: {
            layoutAnimation: false
        },
        tooltip: {
            trigger: 'axis',
            formatter: function(params) {
                const tooltipStrings = params.map(obj => obj.value ? `${obj.marker} ${obj.seriesName}: <strong>${obj.value[1].toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</strong><br />` : "").join("");
                return `${moment(params[0].axisValue).format("DD [de] MMMM [às] h:mm")}<br />` + tooltipStrings;
            }
        },
        legend: {
            data: seriesDict.map(series => series.name)
        },
        xAxis: {
            type: "time",
            boundaryGap: false,
            axisLabel: {
                formatter: function(ts) {
                    return moment(ts).format("DD [de] MMM");
                }
            }
        },
        series: seriesDict
    }}
    
    return (
        <div style={{width: "100%"}}>
            <ChartTitle 
                title="Curvas anuais agregadas de hora em hora" 
                csvName="curvas_anuais_agregadas_de_hora_em_hora.csv"
                data={jsonDataToCSV(yearlyAggregatedSeries, seriesDict.map(series => series.name))} />
            <ReactECharts option={opt} 
                          style={{height: '300px'}} 
                          opts={{locale: 'PTBR'}} >
            </ReactECharts>
        </div>
    );
}

const Checkbox = ({ label, value, onChange }) => {
    return (
        <label>
            <input type="checkbox" checked={value} onChange={onChange} />
            {label}
        </label>
    );
};

// Mean Std Daily Plot
const InternalYearlySeasonalityMeanMinMaxDailyPlot = (props) => {
    let firstYear = moment(props.series[0]['timestamp']).year();
    const echartsRef = useRef(null);
    const yearlySeries = convertYearlyGranularity(props.series).map(series => series.map(point => {
        return {...point, timestamp: firstYear+point["timestamp"].slice(4)}
    }));
    
    const yearlyAggregatedSeries = yearlySeries.map(series => aggregateMeanDays(series));
    const seriesDictMean = props.shouldDisplayMean ? yearlyAggregatedSeries.map((seriesMeanMinMax, index) => {
        return {
                name: `${firstYear+index}`,
                data: series_as_list(seriesMeanMinMax[0]),
                type: "line",
                showSymbol: false,
                symbol: "none",
        };
    }) : null;
    const seriesDictMin = props.shouldDisplayMin ? yearlyAggregatedSeries.map((seriesMeanMinMax, index) => {
        return {
                name: `${firstYear+index}`,
                data: series_as_list(seriesMeanMinMax[1]),
                type: "line",
                showSymbol: false,
                symbol: "none",
                lineStyle: {
                    type: "dashed"
                }
        };
    }) : null;
    const seriesDictMax = props.shouldDisplayMax ? yearlyAggregatedSeries.map((seriesMeanMinMax, index) => {
        return {
                name: `${firstYear+index}`,
                data: series_as_list(seriesMeanMinMax[2]),
                type: "line",
                showSymbol: false,
                symbol: "none",
                lineStyle: {
                    type: "dashed"
                }
        };
    }) : null;

    const seriesDict = [seriesDictMean, seriesDictMin, seriesDictMax].flat().filter(el => el !== null);
    
    const opt = {...defaultOptions(props), ...{
        animation: false,
        force: {
            layoutAnimation: false
        },
        tooltip: {
            trigger: 'axis',
            formatter: function(params) {
                const tooltipStrings = params.map(obj => obj.value ? `${obj.marker} ${obj.seriesName}: <strong>${obj.value[1].toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</strong><br />` : "").join("");
                return `${moment(params[0].axisValue).format("DD [de] MMMM")}<br />` + tooltipStrings;
            }
        },
        legend: {
            data: seriesDict.map(series => series.name)
        },
        xAxis: {
            type: "time",
            boundaryGap: false,
            axisLabel: {
                formatter: function(ts) {
                    return moment(ts).format("DD [de] MMM");
                }
            }
        },
        series: seriesDict
    }}
    const refreshChart = () => {
        if (echartsRef.current) {
            const instance = echartsRef.current.getEchartsInstance();
            instance.setOption(opt, {
                notMerge: true
            });
        }
    }

    refreshChart();
    return (
        <div style={{width: "100%"}}>
            <ChartTitle 
                title={`Estatísticas de ${props.chartsAux.unit_delta} em ${props.chartsAux.unit_delta} ${props.chartsAux.unit_name} das curvas anuais a cada dia`}
                csvName="curvas_anuais_agregadas_diariamente.csv"
                data={jsonDataToCSV(yearlyAggregatedSeries, seriesDict.map(series => series.name))} />
            <ReactECharts option={opt} 
                          ref={echartsRef}
                          style={{height: '300px'}} 
                          opts={{locale: 'PTBR'}} >
            </ReactECharts>
        </div>
    );
}

const YearlySeasonalityMeanMinMaxDailyPlot = (props) => {
    const [shouldDisplayMean, setShouldDisplayMean] = useState(true);
    const [shouldDisplayMin, setShouldDisplayMin] = useState(false);
    const [shouldDisplayMax, setShouldDisplayMax] = useState(false);

    return (
        <div>
            <InternalYearlySeasonalityMeanMinMaxDailyPlot {...props} 
                shouldDisplayMean={shouldDisplayMean} 
                shouldDisplayMin={shouldDisplayMin}
                shouldDisplayMax={shouldDisplayMax} />
            <Checkbox label="Média" value={shouldDisplayMean} onChange={() => { setShouldDisplayMean(!shouldDisplayMean); }} />
            <Checkbox label="Mínimo" value={shouldDisplayMin} onChange={() => { setShouldDisplayMin(!shouldDisplayMin); }} />
            <Checkbox label="Máximo" value={shouldDisplayMax} onChange={() => { setShouldDisplayMax(!shouldDisplayMax); }} />
        </div>
    );
}

// Mean Std Daily Plot
const InternalYearlySeasonalityMeanMinMaxMonthlyPlot = (props) => {
    let firstYear = moment(props.series[0]['timestamp']).year();
    const echartsRef = useRef(null);
    const yearlySeries = convertYearlyGranularity(props.series).map(series => series.map(point => {
        return {...point, timestamp: firstYear+point["timestamp"].slice(4)}
    }));
    
    const yearlyAggregatedSeries = yearlySeries.map(series => aggregateDailyMeanMonths(series));
    const seriesDictMean = props.shouldDisplayMean ? yearlyAggregatedSeries.map((seriesMeanMinMax, index) => {
        return {
                name: `${firstYear+index}`,
                data: series_as_list_with_name(seriesMeanMinMax[0], `Média ${firstYear+index}`),
                encode: { x: 0, y: 1, itemName: 2 },
                type: "line",
                showSymbol: false,
                symbol: "none",
        };
    }) : null;
    const seriesDictMin = props.shouldDisplayMin ? yearlyAggregatedSeries.map((seriesMeanMinMax, index) => {
        return {
                name: `${firstYear+index}`,
                data: series_as_list_with_name(seriesMeanMinMax[1], `Mínimo ${firstYear+index}`),
                encode: { x: 0, y: 1, itemName: 2 },
                type: "line",
                showSymbol: false,
                symbol: "none",
                lineStyle: {
                    type: "dashed"
                },
        };
    }) : null;
    const seriesDictMax = props.shouldDisplayMax ? yearlyAggregatedSeries.map((seriesMeanMinMax, index) => {
        return {
                name: `${firstYear+index}`,
                data: series_as_list_with_name(seriesMeanMinMax[2], `Máximo ${firstYear+index}`),
                encode: { x: 0, y: 1, itemName: 2 },
                type: "line",
                showSymbol: false,
                symbol: "none",
                lineStyle: {
                    type: "dashed"
                },
        };
    }) : null;

    const seriesDict = [seriesDictMean, seriesDictMin, seriesDictMax].flat().filter(el => el !== null);
    
    const opt = {...defaultOptions(props), ...{
        animation: false,
        force: {
            layoutAnimation: false
        },
        tooltip: {
            trigger: 'axis',
            formatter: function(params) {
                const tooltipStrings = params.map(obj => obj.value ? `${obj.marker} ${obj.name}: <strong>${obj.value[1].toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</strong><br />` : "").join("");
                return `${moment(params[0].axisValue).format("MMMM")}<br />` + tooltipStrings;
            }
        },
        legend: {
            data: seriesDict.map(series => series.name)
        },
        xAxis: {
            type: "time",
            boundaryGap: false,
            axisLabel: {
                formatter: function(ts) {
                    return moment(ts).format("MMM");
                }
            }
        },
        series: seriesDict
    }}
    const refreshChart = () => {
        if (echartsRef.current) {
            const instance = echartsRef.current.getEchartsInstance();
            instance.setOption(opt, {
                notMerge: true
            });
        }
    }

    refreshChart();
    return (
        <div style={{width: "100%"}}>
            <ChartTitle 
                title="Estatísticas diárias das curvas anuais mês a mês" 
                csvName="estatisticas_diarias_mensalmente_de_curvas_anuais.csv"
                data={jsonDataToCSV(yearlyAggregatedSeries, seriesDict.map(series => series.name))} />
            <ReactECharts option={opt} 
                          ref={echartsRef}
                          style={{height: '300px'}} 
                          opts={{locale: 'PTBR'}} >
            </ReactECharts>
        </div>
    );
}

const YearlySeasonalityMeanMinMaxMonthlyPlot = (props) => {
    const [shouldDisplayMean, setShouldDisplayMean] = useState(true);
    const [shouldDisplayMin, setShouldDisplayMin] = useState(false);
    const [shouldDisplayMax, setShouldDisplayMax] = useState(false);

    return (
        <div>
            <InternalYearlySeasonalityMeanMinMaxMonthlyPlot {...props} 
                shouldDisplayMean={shouldDisplayMean} 
                shouldDisplayMin={shouldDisplayMin}
                shouldDisplayMax={shouldDisplayMax} />
            <Checkbox label="Média" value={shouldDisplayMean} onChange={() => { setShouldDisplayMean(!shouldDisplayMean); }} />
            <Checkbox label="Mínimo" value={shouldDisplayMin} onChange={() => { setShouldDisplayMin(!shouldDisplayMin); }} />
            <Checkbox label="Máximo" value={shouldDisplayMax} onChange={() => { setShouldDisplayMax(!shouldDisplayMax); }} />
        </div>
    );
}

export { convertYearlyGranularity, 
            aggregateYears,
            YearlySeasonalitySeriesPlot,
            YearlySeasonalityAggregatedMonthlyPlot,
            YearlySeasonalityAggregatedDailyPlot,
            YearlySeasonalityMeanMinMaxDailyPlot,
            YearlySeasonalityMeanMinMaxMonthlyPlot,
            YearlySeasonalityAggregatedHourlyPlot };
