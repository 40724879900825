import React from 'react';
import Backdrop from 'components/UI/Backdrop';

const Modal = (props) => (
    <>
        <Backdrop show={props.show} onClick={props.modalClosed}/>
        <div 
            className={props.className ? props.className : "Modal"}
            style={{
                transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
                opacity: props.show ? '1' : '0',
                ...props.style
            }}>
            {props.children}
        </div>
    </>
);

export default Modal;