import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import moment from "moment";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import { date } from "yup/lib/locale";
import pt from 'date-fns/locale/pt';
registerLocale('pt', pt)

const Calendar = (props) => {
    const formatDate = (granularity) => {
        const id = granularity.granularity
        switch (id) {
            case 0:
                return "dd/MM/yyyy HH:mm";
            case 1:
                return "dd/MM/yyyy HH";
            case 2:
                return "dd/MM/yyyy";
            case 3:
                return "dd/MM/yyyy";
            case 4:
                return "MM/yyyy";
            case 5:
                return "yyyy";
            default:
                return "dd/MM/yyyy HH:mm";
        }
    }

    const calendarGranularity = (granularity) => {
        const id = granularity.granularity;
        let props_dict = {};
        switch (id) {
            case 0:
                props_dict["showTimeSelect"]=true;
                props_dict["timeFormat"]="HH:mm";
                props_dict["timeIntervals"]=parseInt(granularity.unit_delta);
                props_dict["timeCaption"]="Tempo";
                break;
            case 1:
                props_dict["showTimeSelect"]=true;
                props_dict["timeFormat"]="HH";
                props_dict["timeIntervals"]=parseInt(granularity.unit_delta);
                props_dict["timeCaption"]="Tempo";
                break;
            case 4:
                props_dict["showMonthYearPicker"]=true
                break;
            case 5:
                props_dict["showYearPicker"]=true;
                break;
            default:
                break
        }
        return props_dict
    }

    return (
        <DatePicker
            disabled={props.disabled}
            // selected={props.value ? moment(props.value).toDate() : calculateStartDate(props.isPrev)}
            selected={props.value}
            onChange={(date) => {
                props.setValue(date);
            }}
            locale="pt"
            dateFormat={props.granularity ? formatDate(props.granularity) : "dd/M/yyyy HH:m"}
            showYearDropdown
            {...calendarGranularity(props.granularity)}
        />
  );
};

export default Calendar;