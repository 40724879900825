import { useState, useEffect } from 'react';
import * as moment from 'moment';
import { BsFillEyeFill } from "react-icons/bs";
import { BsFillTrashFill } from "react-icons/bs";
import { IoMdAddCircle } from "react-icons/io";
import ReactPaginate from 'react-paginate';
import Info from 'components/UI/Info';
import Card from 'components/UI/Card';
import { mapGranularityName, mapGranularityTimeFormat, compareDates } from 'components/Plots/EChartsHelper';
import SeriesStatus from './SeriesStatus';

const SeriesTable = (props) => {
    const [offset, setOffset] = useState(0);
    const [perPage] = useState(10);
    const [pageCount, setPageCount] = useState(0);

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const newOffset = selectedPage * perPage;
        setOffset(newOffset);
    };

    useEffect(() => {
        if (props.seriesArray)
            setPageCount(Math.ceil(props.seriesArray.length / perPage));
    }, [props.seriesArray, perPage])
    
    const RemoveIcon = props.removeIcon ? props.removeIcon : BsFillTrashFill;
    return (
        <>
        {
            props.seriesArray && props.seriesArray.length > 0 ?
            <Card style={props.style}>
                <h3 className="first-label">{props.title}</h3>
                {props.children}
                <ul className="list-group list-group-nohover">
                    {
                        props.seriesArray.slice(offset, offset + perPage).map((seriesObj, i) => {
                            const seriesMeta = seriesObj.seriesMeta;
                            const higherFrequencyGranularity = ((props.isExplanatorySelection && seriesMeta.granularity.granularity < props.algorithmDates.granularity));
                            const hasNoPrediction = (props.isExplanatorySelection && (compareDates(seriesMeta.end_date, props.algorithmDates.endEstimation) < 1 || (compareDates(seriesMeta.start_date, props.algorithmDates.endForecast) > -1)));
                            const cutsPredictionBeginning = (props.isExplanatorySelection && (compareDates(seriesMeta.start_date,props.algorithmDates.endForecast) == 1));
                            const cutsPredictionEnd = (props.isExplanatorySelection && (compareDates(seriesMeta.end_date,props.algorithmDates.endForecast) == -1));
                            const hasNoEstimation = (props.isExplanatorySelection && (compareDates(seriesMeta.start_date, props.algorithmDates.endEstimation) > -1 || compareDates(seriesMeta.end_date, props.algorithmDates.startEstimation) < 1));
                            const cutsEstimationBeginning = (props.isExplanatorySelection && compareDates(seriesMeta.start_date,props.algorithmDates.startEstimation) == 1);
                            const cutsEstimationEnd = (props.isExplanatorySelection && (compareDates(seriesMeta.end_date,props.algorithmDates.endEstimation) == -1));
                            return (
                                <li key={i}>
                                    <div style={{display: "flex", alignItems: 'center'}}>
                                        {
                                            props.editable ? 
                                            <input type="text" style={{width: "20%", height: "30%", fontWeight: "bold"}} defaultValue={seriesMeta.name} onChange={e => seriesMeta.name = e.target.value}/> :
                                            <span style={{width: "30%"}}><strong>{seriesMeta.name}</strong></span>
                                        }
                                        <span style={{width: "30%", textAlign: "right"}}>{moment(seriesMeta.start_date).format(mapGranularityTimeFormat(seriesMeta.granularity.granularity))}</span>
                                        <span style={{width: "5%", textAlign: "center"}}> - </span>
                                        <span style={{width: "30%", textAlign: "left"}}>{moment(seriesMeta.end_date).format(mapGranularityTimeFormat(seriesMeta.granularity.granularity))}</span>
                                        {
                                            props.isExplanatorySelection &&
                                            <SeriesStatus
                                                hasNoPrediction={hasNoPrediction}
                                                cutsPredictionBeginning={cutsPredictionBeginning}
                                                cutsPredictionEnd={cutsPredictionEnd}
                                                hasNoEstimation={hasNoEstimation}
                                                cutsEstimationBeginning={cutsEstimationBeginning}
                                                cutsEstimationEnd={cutsEstimationEnd}
                                                dependentInfo={props.algorithmDates}
                                                seriesInfo={seriesMeta}
                                            />
                                        }
                                        
                                        {/* <IoMdAddCircle style={{cursor: "pointer", float: "right", marginLeft: 20}} /> */}
                                        <Info style={{float: "right", verticalAlign: "middle"}}>
                                            <p style={{color: "white"}}><strong>Informações sobre {seriesMeta.name}</strong></p>
                                            <p style={{color: "white"}}>Granularidade: {mapGranularityName(seriesMeta.granularity.granularity)}, de {seriesMeta.granularity.unit_delta} em {seriesMeta.granularity.unit_delta} {seriesMeta.granularity.unit_name}</p>
                                            <p style={{color: "white"}}>Data de início: {moment(seriesMeta.start_date).format("lll")}</p>
                                            <p style={{color: "white"}}>Data de fim: {moment(seriesMeta.end_date).format("lll")}</p>
                                            <p style={{color: "white"}}>Arquivo de origem: {seriesMeta.origin_file}</p>
                                        </Info>
                                        {
                                            props.setVisualizeSeries &&                                            
                                            <BsFillEyeFill style={{cursor: "pointer", float: "right", marginLeft: 20}} onClick={() => props.setVisualizeSeries(seriesMeta)} />
                                        }
                                        {
                                            props.setSeriesArray &&
                                            <RemoveIcon style={{cursor: "pointer", float: "right", marginLeft: 20}} onClick={() => {
                                                let updatedValues = [...props.seriesArray];
                                                updatedValues.splice(i, 1);
                                                props.setSeriesArray(updatedValues);
                                                if (props.removeSeriesHandler)
                                                    props.removeSeriesHandler();
                                            }} />
                                        }
                                        {   
                                            props.select && props.checked &&
                                            <input type="checkbox" style={{float: "right", marginLeft: 20}}
                                                onChange={ () => props.select(seriesMeta.series_id) }
                                                checked={props.checked[i]}
                                                disabled={hasNoPrediction}
                                            ></input>
                                        }
                                        {
                                            props.radioSelect && 
                                            <input type="radio" style={{float: "right", marginLeft: 20}}
                                                onChange={ () => props.radioSelect(seriesMeta.series_id) }
                                                checked={props.checked[i]}
                                            ></input>
                                        }
                                    </div>
                                </li>
                            );
                        })
                    }
                </ul>
                <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}/>
            </Card> :
            <Card style={props.style}>
                <h3>{props.emptyText ? props.emptyText : "Você ainda não importou nenhuma série."}</h3>
            </Card>
        }
        </>
    );
};

export default SeriesTable;