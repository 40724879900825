import React, { useState } from 'react';
import Modal from 'components/UI/Modal';
import graphImage from 'assets/images/landing2.jpg';
import Button from 'components/UI/Button';
import TermsAndConditions from 'components/UI/TermsAndConditions';
import { Link, useHistory } from 'react-router-dom';
import { warmupAlgorithm } from 'helper/DataHelper';

const Landing = (props) => {
    const [agree, setAgree] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const history = useHistory();

    return (
        <div style={{marginTop: 30, marginBottom: 60, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <div style={{display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                <div style={{width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <img style={{width: "100%", height: '100%', borderRadius: 20}} src={graphImage} alt="" />
                    <p style={{fontSize: "1.5em", fontWeight: "bold"}}>Nossa ferramenta possibilita previsão e análise exploratória de diferentes séries temporais comparando múltiplos modelos com o uso ou não de variáveis explicativas.</p>
                </div>
                {/* <div style={{width: '30%', display: 'flex', flexDirection: 'column', marginLeft: 30}}> */}
                    <Button style={{marginTop: 30, width: "300px"}} onClick={() => {
                        setOpenModal(true);
                    }}>Iniciar</Button>
                    {/* <Link style={{marginTop: 10}} to='/meus-estudos'><Button style={{width: "300px"}}>Meus estudos</Button></Link> */}
                    {/* <Link style={{marginTop: 30}} to='/model'><Button className="SmallButton" style={{width: "300px"}}>Consultar base de séries</Button></Link> */}
                    {/* <Link style={{marginTop: 10}} to='/model'><Button className="SmallButton" style={{width: "300px"}}>Consultar vegetativo</Button></Link> */}
                {/* </div> */}
                <Modal show={openModal} modalClosed={() => setOpenModal(false)}>
                    <h2 style={{marginTop: 10}}>Termos e condições de uso</h2>
                    <TermsAndConditions />
                    <div style={{marginBottom: 30, marginLeft: 10}}>
                        <input type="checkbox" id="agree" onChange={() => setAgree(!agree)} />
                        <label htmlFor="agree"> Eu li e aceito <b>os termos e condições</b></label>
                    </div>
                    <Button onClick={() => {
                        setOpenModal(false);
                        setTimeout(() => {
                            history.push(props.newStudy);
                            warmupAlgorithm();
                        }, 10);
                    }} disabled={!agree} style={{marginLeft: 10, marginBottom: 10}}>Prosseguir</Button>
                </Modal>
            </div>
        </div>
    );
};

export default Landing;