import { useAuth0 } from '@auth0/auth0-react';
import Card from 'components/UI/Card';
import Button from 'components/UI/Button';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import * as moment from 'moment';
import { useHistory } from 'react-router-dom';
import SelectField from 'components/UI/SelectField';
import api, { createStudy } from 'apis/api';
import { connect } from 'react-redux';
import * as actionTypes from 'store/actions';

const validation = Yup.object().shape({
    name: Yup.string()
});

const StudyCreation = (props) => {
    const history = useHistory();
    const { user, isAuthenticated, loginWithPopup } = useAuth0();
    const options = [
        { value: 'energia', label: 'Energia' },
        { value: 'financas', label: 'Finanças' },
        { value: 'oleoegas', label: 'Óleo e Gás' },
        { value: 'previsao', label: 'Previsão' },
        { value: 'backtest', label: 'Backtest' }
    ]

    const initialValue = `Estudo de ${moment().format('LLL')}`;
    return (
        <Card title="Criação do estudo">
            {
                !isAuthenticated ?
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <p>Você precisa estar logado para criar o estudo.</p>
                    <Button onClick={() => loginWithPopup()}><span>Log in</span></Button>
                </div> :
                <Formik
                    initialValues={{
                        name: initialValue,
                        description: "",
                        tags: []
                    }}
                    validationSchema={validation}
                    onSubmit={ values => {
                        const completion = (data) => {
                            props.setStudyID(data.study_id);
                            toast.success("Estudo criado com sucesso");
                        }
                        const errorOccurred = (message) => {
                            const options = {
                                autoClose: false,
                                hideProgressBar: false,
                                position: toast.POSITION.TOP_RIGHT,
                            };
                            toast.error(message, options);
                        }
                        createStudy(values, user.sub, completion, errorOccurred);
                        history.push("importacao");
                    }}
                >
                    <Form>
                        <ToastContainer />
                        {/* <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between"}}> */}
                        <div style={{display: "flex", flexFlow: "column"}}>
                            <label htmlFor="name">Nome do estudo</label>
                            <Field name="name" type="text"/>
                            <ErrorMessage name="name" component="div" className={"error"} />

                            <label htmlFor="description">Descrição do estudo</label>
                            <Field name="description" component="textarea"/>
                            <ErrorMessage name="description" component="div" className={"error"} />
                            
                            <label htmlFor="tags">Tags</label>
                            <Field name="tags" component={SelectField} options={options} props={{placeholder: "Selecione uma ou mais tags"}} />
                            <ErrorMessage name="tags" component="div" className={"error"} />

                            <Button type="submit" style={{marginTop: 20, alignSelf: "center", width: "300px"}}>Prosseguir</Button>
                        </div>
                        {/* </div> */}
                    </Form>
                </Formik>
            }
        </Card>
    );
}


const mapDispatchToProps = dispatch => {
    return {
        setStudyID: (study_id) => dispatch({type: actionTypes.STORE_STUDY_ID, study_id: study_id}),
    }
}

export default connect(null, mapDispatchToProps)(StudyCreation);
