import React, { useEffect } from 'react';
import { useModal, Modal } from 'react-morphing-modal';
import Button from 'components/UI/Button';
import 'react-morphing-modal/dist/ReactMorphingModal.css';

export const CloseModalButton = (props) => {
    return (
        <Button onClick={() => props.closeModal()} style={{...props.style}}>{props.children}</Button>
    );
}

const FullscreenModal = (props, ref) => {
    const { modalProps, close, getTriggerProps } = useModal({
        background: "white"
    });

    const childrenWithProps = React.Children.map(props.children, child => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { closeModal: close });
        }
        return child;
    });

    const buttonClass = props.buttonClass ? props.buttonClass : "Button";
    return (
        <>
            <div style={props.style}>
                <a type="button" className={buttonClass} {...getTriggerProps()}>{props.button}</a>
                <Modal {...modalProps} closeButton={false}>
                    {childrenWithProps}
                </Modal>
            </div>
        </>
    );
};

export default FullscreenModal;