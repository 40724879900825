import { defaultOptions, series_as_list, jsonDataToCSV, ChartTitle } from './EChartsHelper';
import ReactECharts from 'echarts-for-react';
import * as moment from 'moment';

// Comparative plot between two forecasts
// props: historic, forecast, lower80, upper80, lower95, upper95
const CompareTwoForecastsPlot = (props) => {
    const series = [
        {
            name: `Intervalo de previsão 95% ${props.model1.name}`,
            type: 'line',
            data: series_as_list(props.model1.upper95.series),
            z: -1,
            lineStyle: {
                type: 'dashed',
                color: 'rgb(238, 103, 102)'
            },
            itemStyle: {
                color: 'rgb(238, 103, 102)'
            },
            // areaStyle: {
            //     color: 'rgb(238, 103, 102)',
            // },
            symbol: 'none'
        }, 
        {
            name: `Previsão ${props.model1.name}`,
            show: false,
            data: series_as_list(props.model1.forecast.series),
            type: "line",
            showSymbol: false,
            lineStyle: {
                color: 'rgb(238, 103, 102)'
            },
            itemStyle: {
                color: 'rgb(238, 103, 102)'
            }
        }, 
        {
            name: `Intervalo de previsão 95% ${props.model1.name}`,
            type: 'line',
            data: series_as_list(props.model1.lower95.series),
            z: -1,
            lineStyle: {
                type: 'dashed',
                color: 'rgb(238, 103, 102)'
            },
            itemStyle: {
                color: 'rgb(238, 103, 102)'
            },
            // areaStyle: {
            //     color: 'white',
            //     opacity: 1
            // },
            symbol: 'none'
        },
        {
            name: `Intervalo de previsão 95% ${props.model2.name}`,
            type: 'line',
            data: series_as_list(props.model2.upper95.series),
            z: -1,
            lineStyle: {
                type: 'dashed',
                color: 'rgb(250, 200, 88)'
            },
            itemStyle: {
                color: 'rgb(250, 200, 88)'
            },
            // areaStyle: {
            //     color: 'rgb(250, 200, 88)',
            // },
            symbol: 'none'
        }, 
        {
            name: `Previsão ${props.model2.name}`,
            data: series_as_list(props.model2.forecast.series),
            type: "line",
            showSymbol: false,
            lineStyle: {
                color: 'rgb(250, 200, 88)'
            },
            itemStyle: {
                color: 'rgb(250, 200, 88)'
            }
        }, 
        {
            name: `Intervalo de previsão 95% ${props.model2.name}`,
            type: 'line',
            data: series_as_list(props.model2.lower95.series),
            z: -1,
            lineStyle: {
                type: 'dashed',
                color: 'rgb(250, 200, 88)'
            },
            itemStyle: {
                color: 'rgb(250, 200, 88)'
            },
            // areaStyle: {
            //     color: 'white',
            //     opacity: 1,
            // },
            symbol: 'none'
        },
        {
            name: "Histórico",
            data: series_as_list(props.model1.historic.series),
            type: "line",
            showSymbol: false,
            lineStyle: {
                color: 'rgba(0, 11, 113, 1)'
            },
            itemStyle: {
                color: 'rgba(0, 11, 113, 1)'
            },
        },
    ];
    const opt = {...defaultOptions(props), ...{
        series: series,
        legend: {
            data: series.map(s => s.name)
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                animation: false,
                label: {
                    backgroundColor: '#ccc',
                    borderColor: '#aaa',
                    borderWidth: 1,
                    shadowBlur: 0,
                    shadowOffsetX: 0,
                    shadowOffsetY: 0,
                    color: '#222',
                    formatter: function(params) {
                        if (params["axisDimension"] === "x")
                            return moment(params["value"]).format('lll');
                        return params["value"].toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2});
                    }
                }
            },
            formatter: function(params) {
                const tooltipStrings = params.map(obj => obj.value ? `${obj.marker} ${obj.seriesName}: <strong>${obj.value[1].toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</strong><br />` : "").join("");
                return `${moment(params[0].axisValue).format("lll")}<br />` + tooltipStrings;
            }
        },
    }}
    return (
        <div style={{width: "100%"}}>
            <ChartTitle 
                style={{marginTop: 30}}
                // title="Comparação de previsões de modelos"
                // csvName="estatísticas_de_30_em_30_minutos_diariamente_da_série_completa.csv"
                // data={jsonDataToCSV([meanSeries, minSeries, maxSeries], ["Média", "Mínimo", "Máximo"])} 
                />
            <ReactECharts option={opt} 
                          style={{height: '300px'}} 
                          opts={{renderer: 'svg', 
                          locale: 'PTBR'}} >
            </ReactECharts>
        </div>
    );
}

// Comparative plot between many forecasts
const CompareAllForecastPlot = (props) => {
    const forecasts = props.forecastInfo.map(info => {
        return {
            name: `${info.name}`,
            show: false,
            data: series_as_list(info[props.comparisonAttr].series),
            type: "line",
            showSymbol: false,
        }
    })
    const series = [
        ...forecasts,
        {
            name: "Histórico",
            data: series_as_list(props.forecastInfo[0].historic.series),
            type: "line",
            showSymbol: false,
            lineStyle: {
                color: 'rgba(0, 11, 113, 1)'
            },
            itemStyle: {
                color: 'rgba(0, 11, 113, 1)'
            },
        },
    ];
    const opt = {...defaultOptions(props), ...{
        series: series,
        legend: {
            data: series.map(s => s.name)
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                animation: false,
                label: {
                    backgroundColor: '#ccc',
                    borderColor: '#aaa',
                    borderWidth: 1,
                    shadowBlur: 0,
                    shadowOffsetX: 0,
                    shadowOffsetY: 0,
                    color: '#222',
                    formatter: function(params) {
                        if (params["axisDimension"] === "x")
                            return moment(params["value"]).format('lll');
                        return params["value"].toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2});
                    }
                }
            },
            formatter: function(params) {
                const tooltipStrings = params.map(obj => obj.value ? `${obj.marker} ${obj.seriesName}: <strong>${obj.value[1].toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</strong><br />` : "").join("");
                return `${moment(params[0].axisValue).format("lll")}<br />` + tooltipStrings;
            }
        },
    }}
    return (
        <div style={{width: "100%"}}>
            <ChartTitle 
                title={`${props.comparisonAttrName} das previsões`}
                // csvName="estatísticas_de_30_em_30_minutos_diariamente_da_série_completa.csv"
                // data={jsonDataToCSV([meanSeries, minSeries, maxSeries], ["Média", "Mínimo", "Máximo"])} 
                />
            <ReactECharts option={opt} 
                          style={{height: '300px'}} 
                          opts={{renderer: 'svg', 
                          locale: 'PTBR'}} >
            </ReactECharts>
        </div>
    );
}

export { CompareTwoForecastsPlot, CompareAllForecastPlot };