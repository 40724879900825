import React, { useState, useEffect } from 'react';
import { connect, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import Loader from 'react-loader-spinner';
import Card from 'components/UI/Card';
import SelectField from 'components/UI/SelectField';
import Collapsible from 'components/UI/Collapsible';
import FullscreenModal from 'components/UI/FullscreenModal';
import ExplanatorySeriesSelectSubForm from 'components/Forms/ExplanatorySeriesSelectSubForm';
import SeriesTable from 'components/UI/SeriesTable';
import Modal from 'components/UI/Modal';
import Button from 'components/UI/Button';
import FormikCalendar from 'components/UI/FormikCalendar';
import { compareDates, dateToString, mapGranularityTimeFormat } from 'components/Plots/EChartsHelper';
import SeriesIndividualVisualization from 'components/UI/SeriesIndividualVisualization';
import ModelSelectionSubForm from 'components/Forms/ModelSelectionSubForm';
import { AiOutlineClose } from "react-icons/ai";
import { v4 as uuidv4 } from 'uuid';
import { runAnalysis } from 'apis/api';
import moment from 'moment';
import DependentSeriesSelectSubForm from 'components/Forms/DependentSeriesSelectSubForm';

// export const validation = yup.object().shape({
//     series: yup
//         .object()
//         .required("Arquivo CSV da série é obrigatório.")
// })

export const createEmptyAnalysis = (index) => {
    return {
        name: `Análise ${index+1}`,
        id: uuidv4(),
        dependentSeries: [],
        explanatorySeries: [],
        startEstimation: "",
        endEstimation: "",
        endForecast: "",
        models: [],
    };
}

export const createEmptyAnalysisResults = (id) => {
    return {
        id: id,
        hasRun: false,
        results: null,
    };
}

const AnalysisSubForm = (props) => {
    const [loading, setLoading] = useState(false);
    const index = (props.index !== undefined) ? `[${props.index}]` : '';
    const fieldPrefix = `${props.name}${index}.`;
    const [visualizeSeries, setVisualizeSeries] = useState(null);
    const [updateDatesInfo, setUpdateDatesInfo] = useState(null);
    const [openDatesModal, setOpenDatesModal] = useState(false);
    const [editing, setEditing] = useState(false);
    const [dateModalText, setDatesModalText] = useState([]);
    const [incompatibleDateError, setIncompatibleDatesError] = useState(false);
    const [isFirstTime, setFirstTime] = useState(true);
    const [seriesIdsToDelete, setSeriesIdsToDelete] = useState([]);
    
    const options = props.series.map(series => {
        return { value: series, label: series.seriesMeta.name };
    });
    let analysisValues = props.formik.values[props.name][props.index];
    const startedRunning = props.analysesResults[props.index].hasRun;
    const finishedRunning = props.analysesResults[props.index].hasRun && props.analysesResults[props.index].results !== null;
    const editable = !startedRunning || editing;
    let analysisResultsValues = props.analysesResults[props.index];

    const checkIncompatibleDates = (date, type) => {
        const dependentSeries = analysisValues.dependentSeries;
        if (dependentSeries.length === 0) return false;
        const granularity = dependentSeries[0].seriesMeta.granularity.granularity;
        
        if (type === "startEstimation" && compareDates(analysisValues.endEstimation, date) < 1) {
            dateModalText.push(<p> A data escolhida para o início do período de estimação ({dateToString(granularity,date)}) é igual ou posterior ao fim desse período ({dateToString(granularity,analysisValues.endEstimation)}) </p>);
            return true; 
        }

        if (type === "endEstimation" && (compareDates(analysisValues.startEstimation, date) > -1 || compareDates(analysisValues.endForecast , date) < 1)) {
            if (compareDates(analysisValues.startEstimation, date) > -1) {
                dateModalText.push(<p> A data escolhida para o fim do período de estimação ({dateToString(granularity,date)}) é igual ou anterior ao início desse período ({dateToString(granularity,analysisValues.startEstimation)}) </p>);
            } else {
                dateModalText.push(<p> A data escolhida para o fim do período de estimação ({dateToString(granularity,date)}) é igual ou posterior ao fim do período de previsão ({dateToString(granularity,analysisValues.endForecast)}) </p>);
            }
            return true; 
        }
        if (type === "endForecast" && (compareDates(analysisValues.endEstimation, date) > -1 )) {
            dateModalText.push(<p> A data escolhida para o fim do período de previsão ({dateToString(granularity,date)}) é igual ou anterior ao fim do período de estimação({dateToString(granularity,analysisValues.endEstimation)}) </p>);
            return true; 
        }
    }

    const checkIncompatibleSeries = (date, type) => {
        const dependentSeries = analysisValues.dependentSeries;
        const explanatorySeries = analysisValues.explanatorySeries;
        const idsToDelete = [];
        if (dependentSeries.length === 0) return idsToDelete;
        const granularity = dependentSeries[0].seriesMeta.granularity.granularity;
        
        dependentSeries.forEach(el => {
            if (type === "endForecast") return;
            if (compareDates(el.seriesMeta.start_date, date) === 1) {
                idsToDelete.push(el.seriesMeta.series_id);
                dateModalText.push(<p> A data escolhida ({dateToString(granularity,date)}) é anterior ao início da série dependente: {el.seriesMeta.name} ({dateToString(granularity,el.seriesMeta.start_date)}) </p>);
            }

            if (compareDates(el.seriesMeta.end_date,date) === -1) {
                idsToDelete.push(el.seriesMeta.series_id);
                dateModalText.push(<p> A data escolhida ({dateToString(granularity,date)}) é posterior ao fim da série dependente: {el.seriesMeta.name} ({dateToString(granularity,el.seriesMeta.end_date)}) </p>);
            }
        });

        if (idsToDelete.length !== 0) {
            dateModalText.push(<h4>OBS: A exclusão da série dependente acarretará na exclusão das explicativas</h4>);
            explanatorySeries.forEach(el => { idsToDelete.push(el.seriesMeta.series_id)});
            return idsToDelete;
        }

        explanatorySeries.forEach(el => {
            if (compareDates(el.seriesMeta.start_date,date) === 1) {
                idsToDelete.push(el.seriesMeta.series_id);
                dateModalText.push(<p> A data escolhida ({dateToString(granularity,date)}) é anterior ao início da série explicativa: {el.seriesMeta.name} ({dateToString(granularity,el.seriesMeta.start_date)}) </p>);
            }

            if (compareDates(el.seriesMeta.end_date,date) === -1) {
                idsToDelete.push(el.seriesMeta.series_id);
                dateModalText.push(<p> A data escolhida ({dateToString(granularity,date)}) é posterior ao fim da série explicativa: {el.seriesMeta.name} ({dateToString(granularity,el.seriesMeta.end_date)}) </p>);
            }
        });
        return idsToDelete;
    }

    const removeSeriesHandler = () => {
        analysisValues = props.formik.values[props.name][props.index];
        setFirstTime(true);
    }

    return (
        <>
            <Card>
                {
                    props.deleteHandler ? 
                    <span className="x-button" 
                            style={{float: "right", marginLeft: "20px", marginTop: "-5px"}}
                            onClick={() => {props.deleteHandler(props.name)}}>{'\u2715'}
                    </span> : null
                }
                {
                    props.duplicateHandler ? 
                    <Button type="button" className="SmallButton" style={{float: "right", marginLeft: "20px", marginTop: "-10px"}}
                            onClick={() => {props.duplicateHandler()}}>Duplicar
                    </Button> : null
                }
                <h3 className="first-label">{analysisValues.name}</h3>
                <label htmlFor={fieldPrefix + "name"} className="first-label">Nome da análise</label>
                <Field name={fieldPrefix + "name"} type="text" disabled={!editable} />
                <ErrorMessage name={fieldPrefix + "name"} component="div" className={"error"} />
                
                <Collapsible trigger="Séries dependentes" tagName="h3" style={{marginTop: 30}}>
                    {
                        editable && 
                        <FullscreenModal button="Escolher séries dependentes" style={{marginTop: 30}}>
                            <DependentSeriesSelectSubForm 
                                index={props.index} 
                                name={'dependentSeries'} 
                                type="-" 
                                title="Seleção de série dependente" 
                                series={props.series} 
                                isSelection={true}
                            />
                        </FullscreenModal>
                    }
                    
                    <SeriesTable 
                        style={{marginTop: 20, boxShadow: "0 0 0.2rem rgba(105, 105, 105, 0.3)"}} 
                        title="Séries dependentes" 
                        seriesArray={analysisValues.dependentSeries}
                        setVisualizeSeries={setVisualizeSeries}
                        setSeriesArray={(seriesArray) => {
                            props.formik.setFieldValue(`${props.name}[${props.index}].dependentSeries`, seriesArray);
                        }}
                        removeIcon={AiOutlineClose}
                        emptyText="Você ainda não selecionou nenhuma série dependente."
                        removeSeriesHandler={removeSeriesHandler} />
                </Collapsible>

                <Collapsible trigger="Definição das datas de estimação e previsão" tagName="h3" style={{marginTop: 30}}>
                    {
                        analysisValues.dependentSeries.length > 0 ?
                        <div className="AdvancedParametersContainer">
                            <div className="AdvancedParametersItem">
                                <label htmlFor={fieldPrefix + "startEstimation"}>Data de início do período de estimação</label>
                                <Field 
                                    name={fieldPrefix + "startEstimation"} 
                                    component={FormikCalendar} 
                                    setValue={(date) => {
                                        if (isFirstTime) {
                                            props.formik.setFieldValue(fieldPrefix + "startEstimation", date); 
                                            setFirstTime(false);
                                            return;
                                        }
                                        if (checkIncompatibleDates(date,"startEstimation")) {
                                            setDatesModalText(dateModalText);
                                            setIncompatibleDatesError(true);
                                            setOpenDatesModal(true);
                                            return;
                                        }
                                        const delIds = checkIncompatibleSeries(date,"startEstimation");
                                        if (delIds.length !== 0) {
                                            setDatesModalText(dateModalText);
                                            setSeriesIdsToDelete(delIds);
                                            setIncompatibleDatesError(false);
                                            setUpdateDatesInfo(["startEstimation", date]);
                                            setOpenDatesModal(true);
                                            return; 
                                        } 

                                        props.formik.setFieldValue(fieldPrefix + "startEstimation", date);
                                    }}
                                    granularity={analysisValues.dependentSeries[0].seriesMeta.granularity} 
                                    formikValue={analysisValues.startEstimation}
                                    startDate={analysisValues.dependentSeries[0].seriesMeta.start_date}
                                    disabled={!editable} />
                                <ErrorMessage name={fieldPrefix + "startEstimation"} component="div" className={"error"} />
                            </div>

                            <div className="AdvancedParametersItem">
                                <label htmlFor={fieldPrefix + "endEstimation"}>Data de fim do período de estimação</label>
                                <Field 
                                    name={fieldPrefix + "endEstimation"} 
                                    component={FormikCalendar} 
                                    setValue={(date) => {
                                        if (isFirstTime) { 
                                            props.formik.setFieldValue(fieldPrefix + "endEstimation", date);
                                            setFirstTime(false);
                                            return;
                                        }
                                        if (checkIncompatibleDates(date,"endEstimation")) {
                                            setDatesModalText(dateModalText);
                                            setIncompatibleDatesError(true);
                                            setOpenDatesModal(true);
                                            return;
                                        }
                                        const delIds = checkIncompatibleSeries(date, "endEstimation");
                                        if (delIds.length !== 0) {
                                            setDatesModalText(dateModalText);
                                            setSeriesIdsToDelete(delIds);
                                            setIncompatibleDatesError(false);
                                            setUpdateDatesInfo(["endEstimation", date]);
                                            setOpenDatesModal(true);
                                            return; 
                                        }
                                        props.formik.setFieldValue(fieldPrefix + "endEstimation", date);
                                    }}
                                    granularity={analysisValues.dependentSeries[0].seriesMeta.granularity} 
                                    formikValue={analysisValues.endEstimation}
                                    startDate={analysisValues.dependentSeries[0].seriesMeta.end_date}
                                    disabled={!editable} />
                                <ErrorMessage name={fieldPrefix + "endEstimation"} component="div" className={"error"} />
                            </div>
                            <div style={{paddingLeft:10, paddingRight:10}}>
                                <label htmlFor={fieldPrefix + "endForecast"}>Data de fim do período de previsão</label>
                                <Field 
                                    name={fieldPrefix + "endForecast"} 
                                    component={FormikCalendar} 
                                    setValue={(date) => {
                                        if (isFirstTime) { 
                                            setFirstTime(false);
                                            props.formik.setFieldValue(fieldPrefix + "endForecast", date);
                                            return;
                                        }
                                        if (checkIncompatibleDates(date,"endForecast")) {
                                            setDatesModalText(dateModalText);
                                            setIncompatibleDatesError(true);
                                            setOpenDatesModal(true);
                                            return;
                                        }
                                        const delIds = checkIncompatibleSeries(date, "endForecast");
                                        if (delIds.length !== 0) {
                                            setDatesModalText(dateModalText);
                                            setSeriesIdsToDelete(delIds);
                                            setIncompatibleDatesError(false);
                                            setUpdateDatesInfo(["endForecast", date]);
                                            setOpenDatesModal(true);
                                            return; 
                                        }
                                        props.formik.setFieldValue(fieldPrefix + "endForecast", date);
                                    }}
                                    granularity={analysisValues.dependentSeries[0].seriesMeta.granularity} 
                                    formikValue={analysisValues.endForecast}
                                    startDate={analysisValues.dependentSeries[0].seriesMeta.end_date} 
                                    isPrev={true} 
                                    disabled={!editable} />
                                <ErrorMessage name={fieldPrefix + "endForecast"} component="div" className={"error"} />
                            </div>
                        </div> :
                        <div>
                            <h3> É necessário importar a série dependente primeiro</h3>
                        </div>
                    }
                </Collapsible>

                <Collapsible trigger="Séries explicativas" tagName="h3" style={{marginTop: 30}}>
                    
                    {
                        analysisValues.dependentSeries.length > 0 && editable && 
                        <FullscreenModal button="Escolher séries explicativas" style={{marginTop: 30}}>
                            <ExplanatorySeriesSelectSubForm 
                                index={props.index}
                                name={'explanatorySeries'} 
                                type="-" 
                                title="Seleção de séries explicativas" 
                                series={props.series} 
                                isSelection={true}
                            />
                        </FullscreenModal>
                    }
                    {
                        analysisValues.dependentSeries.length > 0 ?
                        <SeriesTable 
                            style={{marginTop: 20, boxShadow: "0 0 0.2rem rgba(105, 105, 105, 0.3)"}} 
                            title="Séries explicativas" 
                            seriesArray={analysisValues.explanatorySeries}
                            setVisualizeSeries={setVisualizeSeries}
                            setSeriesArray={(seriesArray) => {
                                props.formik.setFieldValue(`${props.name}[${props.index}].explanatorySeries`, seriesArray)
                            }}
                            removeIcon={AiOutlineClose}
                            emptyText="Você ainda não selecionou nenhuma série explicativa." /> :
                        <div>
                            <h3> É necessário importar a série dependente primeiro</h3>
                        </div>
                    }
                </Collapsible>
                
                <Collapsible trigger="Escolha de modelos" tagName="h3" style={{marginTop: 30}}>
                    <ModelSelectionSubForm fieldPrefix={fieldPrefix} analysisIndex={props.index} analysisValues={analysisValues} disabled={!editable} />
                </Collapsible>
                <div style={{display: "flex", justifyContent: "flex-end"}}>
                {
                    !props.analysesResults[props.index].hasRun &&
                    <Button type="button" disabled={ analysisValues.dependentSeries.length === 0 || analysisValues.models.length === 0}
                            style={{marginLeft: 20, marginRight: 20}}
                            onClick={() => {
                                setEditing(false);
                                props.analysesResults[props.index].hasRun = true;
                                props.triggerRerender();
                                runAnalysis(analysisValues, (data) => {
                                            props.analysesResults[props.index].results = data;
                                            props.setAnalysesResults(props.analysesResults);
                                            props.triggerRerender();
                                }, () => {
                                    props.analysesResults[props.index].hasRun = false;
                                    props.triggerRerender();
                                })
                            }}>
                        Rodar análise
                    </Button>
                }
                {
                   props.analysesResults[props.index].hasRun && props.analysesResults[props.index].results === null &&
                   <Loader 
                        type="Oval" 
                        color="rgba(0, 11, 67, 1)"
                        height={40} 
                        width={40}
                        style={{marginLeft: "20px", marginTop: "30px"}} /> 
                }
                </div>
                {
                    finishedRunning && !editing &&
                    <div style={{marginTop: 30, display: "flex", justifyContent: "space-between"}}>
                        <Button onClick={() => {
                            props.analysesResults[props.index].hasRun = false;
                            props.analysesResults[props.index].results = null;
                            props.setAnalysesResults(props.analysesResults);
                            setEditing(true);
                        }}>Editar análise</Button>
                        <h3>Resultados já disponíveis!</h3>
                    </div>
                }

                <div style={{display: 'flex', justifyContent: 'center'}}>
                    {
                        loading['explanatory'] && <Loader 
                        type="Oval" 
                        color="rgba(0, 11, 67, 1)"
                        height={40} 
                        width={40}
                        style={{marginLeft: "20px", marginTop: "30px"}} />
                    }
                </div>
            </Card>
            {
                <Modal className="Modal BigModal" show={visualizeSeries !== null} modalClosed={() => setVisualizeSeries(null)}>
                    {
                        visualizeSeries && <SeriesIndividualVisualization seriesMeta={visualizeSeries} />
                    }
                </Modal>
            }
            <Modal className="Modal SmallModal" show={openDatesModal}>
                <h3 className="first-label"> Alteração de Datas </h3>
                <div>{dateModalText}</div>
                {
                    incompatibleDateError &&
                    <Button onClick={()=>{
                        setDatesModalText([]);
                        setOpenDatesModal(false);
                        setIncompatibleDatesError(false);
                    }} >Entendi</Button>
                }
                {
                    !incompatibleDateError &&
                    <>
                        <Button onClick={()=>{
                            analysisValues.dependentSeries = analysisValues.dependentSeries.filter(el => !seriesIdsToDelete.includes(el.seriesMeta.series_id));
                            analysisValues.explanatorySeries = analysisValues.explanatorySeries.filter(el => !seriesIdsToDelete.includes(el.seriesMeta.series_id));
                            setSeriesIdsToDelete([]);
                            setDatesModalText([]);
                            setOpenDatesModal(false);
                            setIncompatibleDatesError(false);
                            props.formik.setFieldValue(`${props.name}[${props.index}].dependentSeries`, analysisValues.dependentSeries);
                            props.formik.setFieldValue(`${props.name}[${props.index}].explanatorySeries`, analysisValues.explanatorySeries);
                            props.formik.setFieldValue(fieldPrefix + updateDatesInfo[0], updateDatesInfo[1]);
                        }} >Concordo</Button>
                        <Button style={{marginLeft: 20}} onClick={() => {
                            setDatesModalText([]);
                            setSeriesIdsToDelete([]);
                            setOpenDatesModal(false);
                            setIncompatibleDatesError(false);
                        }}>Cancelar</Button>
                    </>
                }
                
            </Modal>
        </>
    );
};

export default connect(AnalysisSubForm);