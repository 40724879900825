import { defaultOptions, series_as_list, jsonDataToCSV, ChartTitle } from './EChartsHelper';
import ReactECharts from 'echarts-for-react';
import * as moment from 'moment';

// Daily Plot
function convertDayGranularity(series) {
    let seriesData = [];
    let current = [];
    let currentTime = series[0]['timestamp'].slice(0, 10);
    for (let i = 0; i < series.length; ++i) {
        if (series[i]['timestamp'].slice(0, 10) != currentTime) {
            seriesData.push(current);
            current = [];
            currentTime = series[i]['timestamp'].slice(0, 10);
        }
        current.push(series[i]);
    }
    seriesData.push(current);
    return seriesData;
}

function sumDailySeries(series) {    
    return {timestamp: series[0]["timestamp"].slice(0,10), ...series.reduce((prev, current) => {
            return {value: prev["value"] + current["value"]};
        })
    };
}

function aggregateDays(series) {
    const dailySeries = convertDayGranularity(series);
    const aggregatedDailySeries = dailySeries.map(sumDailySeries);
    return aggregatedDailySeries;
}

function convertHourGranularity(series) {
    let seriesData = [];
    let current = [];
    let currentTime = series[0]['timestamp'].slice(0, 13);
    for (let i = 0; i < series.length; ++i) {
        if (series[i]['timestamp'].slice(0, 13) != currentTime) {
            seriesData.push(current);
            current = [];
            currentTime = series[i]['timestamp'].slice(0, 13);
        }
        current.push(series[i]);
    }
    seriesData.push(current);
    return seriesData;
}

function sumHourlySeries(series) {
    return {timestamp: series[0]["timestamp"].slice(0,13), ...series.reduce((prev, current) => {
            return {value: prev["value"] + current["value"]};
        })
    };
}

function aggregateHours(series) {
    const hourlySeries = convertHourGranularity(series);
    const aggregatedHourlySeries = hourlySeries.map(sumHourlySeries);
    return aggregatedHourlySeries;
}

function aggregateMeanDays(series) {
    const dailySeries = convertDayGranularity(series);
    const meanSeries = aggregateDays(series).map((s, i) => {
        return {...s, value: s["value"] / dailySeries[i].length}
    });
    const minSeries = meanSeries.map((mean, i) => {
        return dailySeries[i].reduce((prev, cur) => {
            return {...mean, value: Math.min(prev["value"], cur["value"])};
        });
    });
    const maxSeries = meanSeries.map((mean, i) => {
        return dailySeries[i].reduce((prev, cur) => {
            return {...mean, value: Math.max(prev["value"], cur["value"])};
        });
    });
    return [meanSeries, minSeries, maxSeries];
}

function aggregateMeanHours(series) {
    const hourlySeries = convertHourGranularity(series);
    const meanSeries = aggregateHours(series).map((s, i) => {
        return {...s, value: s["value"] / hourlySeries[i].length}
    });
    const minSeries = meanSeries.map((mean, i) => {
        return hourlySeries[i].reduce((prev, cur) => {
            return {...mean, value: Math.min(prev["value"], cur["value"])};
        });
    });
    const maxSeries = meanSeries.map((mean, i) => {
        return hourlySeries[i].reduce((prev, cur) => {
            return {...mean, value: Math.max(prev["value"], cur["value"])};
        });
    });
    return [meanSeries, minSeries, maxSeries];
}

const DailySeasonalitySeriesPlot = (props) => {
    let firstYearMonthDay = props.series[0]['timestamp'].slice(0, 10);
    const dailySeries = convertDayGranularity(props.series).map(series => series.map(point => {
        return {...point, timestamp: firstYearMonthDay+point["timestamp"].slice(10)}
    }));
    const seriesDict = dailySeries.map((series, index) => {
        return {
            data: series_as_list(series),
            type: "line",
            showSymbol: false,
            symbol: "none",
            silent: true,
            animation: false,
            lineStyle: {
                width: 1,
                opacity: 0.3
            },
            emphasis: {
                lineStyle: {
                    width: 1
                }
            }
        };
    });
    const opt = {...defaultOptions(props), ...{
        animation: false,
        force: {
            layoutAnimation: false
        },
        tooltip: {
            trigger: 'none',
            triggerOn: 'click',
            axisPointer: {
                show: true,
                type: 'cross',
                lineStyle: {
                    type: 'dashed',
                    width: 1
                },
                label: {
                    formatter: function(params) {
                        if (params["axisDimension"] === "x")
                            return moment(params["value"]).format('h:mm');
                        return params["value"].toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2});
                    }
                }
            },
        },
        xAxis: {
            type: "time",
            boundaryGap: false,
            axisLabel: {
                formatter: function(ts) {
                    return moment(ts).format("h:mm");
                }
            }
        },
        series: seriesDict
    }}
    
    return (
        <div style={{width: "100%"}}>
            <ChartTitle 
                title="Curvas diárias" 
                csvName="curvas_diarias.csv"
                data={jsonDataToCSV(dailySeries, seriesDict.map(series => series.name))} />
            <ReactECharts option={opt} 
                          style={{height: '300px'}} 
                          opts={{locale: 'PTBR'}} >
            </ReactECharts>
        </div>
    );
}

// Daily Plot Aggregated Hourly
const DailySeasonalityAggregatedHourlyPlot = (props) => {
    let firstYearMonthDay = props.series[0]['timestamp'].slice(0, 10);
    const dailySeries = convertDayGranularity(props.series).map(series => series.map(point => {
        return {...point, timestamp: firstYearMonthDay+point["timestamp"].slice(10)}
    }));
    const dailyAggregatedSeries = dailySeries.map(series => aggregateHours(series));
    const seriesDict = dailyAggregatedSeries.map((series, index) => {
        return {
            data: series_as_list(series),
            type: "line",
            showSymbol: false,
            symbol: "none",
            silent: true,
            animation: false,
            lineStyle: {
                width: 1,
                opacity: 0.3
            },
            emphasis: {
                lineStyle: {
                    width: 1
                }
            }
        };
    });
    const opt = {...defaultOptions(props), ...{
        animation: false,
        force: {
            layoutAnimation: false
        },
        tooltip: {
            trigger: 'none',
            triggerOn: 'click',
            axisPointer: {
                show: true,
                type: 'cross',
                lineStyle: {
                    type: 'dashed',
                    width: 1
                },
                label: {
                    formatter: function(params) {
                        if (params["axisDimension"] === "x")
                            return moment(params["value"]).format('h:mm');
                        return params["value"].toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2});
                    }
                }
            },
        },
        xAxis: {
            type: "time",
            boundaryGap: false,
            axisLabel: {
                formatter: function(ts) {
                    return moment(ts).format("h:mm");
                }
            }
        },
        series: seriesDict
    }}
    
    return (
        <div style={{width: "100%"}}>
            <ChartTitle 
                title="Curvas diárias agregadas de hora em hora" 
                csvName="curvas_diarias_agregadas_de_hora_em_hora.csv"
                data={jsonDataToCSV(dailyAggregatedSeries, seriesDict.map(series => series.name))} />
            <ReactECharts option={opt} 
                          style={{height: '300px'}} 
                          opts={{locale: 'PTBR'}} >
            </ReactECharts>
        </div>
    );
}

export { convertDayGranularity, 
        aggregateDays, 
        aggregateHours, 
        aggregateMeanDays, 
        aggregateMeanHours,
        DailySeasonalitySeriesPlot,
        DailySeasonalityAggregatedHourlyPlot };