import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ScrollToTop from 'components/UI/ScrollToTop';
import Navbar from 'components/UI/Navbar';
import Landing from 'pages/Landing';
import StudyCreation from 'pages/StudyCreation';
import MyStudies from 'pages/MyStudies';
import SeriesInput from 'pages/SeriesInput';
import CreateAnalysis from 'pages/CreateAnalysis';
import AnalysesResults from 'pages/AnalysesResults';
import { useAuth0 } from '@auth0/auth0-react';

const Layout = (props) => {
    const { user } = useAuth0();

    return (
    <>
        <Navbar />
        <div className="LayoutContainer">
            <ScrollToTop />
            <Switch>
                <Route path='/' exact>
                    <div style={{display: "flex", justifyContent: "center"}}><h2 style={{marginTop: 30, marginBottom: 30, fontSize: "3.5em"}}><strong>Ferramenta de previsão</strong></h2></div>
                </Route>
                <Route path='/'>
                    <div style={{display: "flex"}}><h2 style={{marginTop: 30, marginBottom: 30, fontSize: "2.0em"}}><strong>Ferramenta de previsão</strong></h2></div>
                </Route>
            </Switch>
            <Switch>
                <Route path='/resultados-analises'>
                    <AnalysesResults prevPage="analises"/>
                </Route>
                <Route path='/analises'>
                    <CreateAnalysis prevPage="importacao" nextPage="resultados-analises"/>
                </Route>
                <Route path='/importacao'>
                    <SeriesInput prevPage="novo-estudo" nextPage="analises" />
                </Route>
                <Route path='/meus-estudos'>
                    <MyStudies />
                </Route>
                <Route path='/novo-estudo'>
                    <StudyCreation />
                </Route>
                <Route path='/' exact>
                    <Landing newStudy="importacao"/>
                </Route>
            </Switch>
        </div>
        
        <main>{props.children}</main>
    </>
    );
}

export default Layout;