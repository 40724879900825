import React, { useState, useRef, useEffect } from 'react';
import Button from 'components/UI/Button';
import { Redirect, useHistory } from 'react-router-dom';
import { Formik, Form, FieldArray, validateYupSchema } from 'formik';
import * as yup from 'yup';
import 'react-toastify/dist/ReactToastify.css';
import * as actionTypes from 'store/actions';
import { connect } from 'react-redux';
import AnalysisSubForm, { createEmptyAnalysis, createEmptyAnalysisResults } from 'components/Forms/AnalysisSubForm';
import { v4 as uuidv4 } from 'uuid';
import { runAnalysis } from 'apis/api';

const CreateAnalysis = (props) => {
    const [submitted, setSubmitted] = useState(false);
    const [isFirstTime, setFirstTime] = useState(true);
    const formikRef = useRef();

    const history = useHistory();
    const initialValues = {
        analyses: props.analyses.length !== 0 ? props.analyses : []
    };
    
    const maxNumberOfAnalyses = 5;
    const numberOfAnalyses = formikRef.current ? formikRef.current.values.analyses.length : 0;
    const canRunAnalyses = () => {
        if (!formikRef?.current) return true;
        return !props.analysesResults.map((analysis) => {
            if (analysis.hasRun) return true;
            const auxAnalysis = formikRef?.current.values.analyses.find(a => a.id === analysis.id);
            if (auxAnalysis === undefined) return true;
            if (auxAnalysis.dependentSeries.length > 0 && auxAnalysis.models.length > 0) return false;
        }).includes(false);
    }

    const runPossibleAnalysis = () => {
        formikRef?.current.values.analyses.forEach((el,i) => {
            if (el.dependentSeries.length > 0 && el.models.length > 0 && !props.analysesResults[i].hasRun) {
                props.analysesResults[i].hasRun = true;
                props.triggerRerender();
                runAnalysis(el, (data) => {
                    props.analysesResults[i].results = data;
                    props.setAnalysesResults(props.analysesResults);
                    props.triggerRerender();
                }, () => {
                    props.analysesResults[i].hasRun = false;
                    props.triggerRerender();
                })
            }
        })
    }
    
    // if (props.analyses.length === 0 && isFirstTime) {
    //     setFirstTime(false);
    //     props.setAnalyses(initialValues.analyses);
    //     props.analysesResults.push(createEmptyAnalysisResults(initialAnalysis.id));
    //     props.setAnalysesResults(props.analysesResults);
    // }
  
    const duplicateHandler = (analysis, values, arrayHelpers) => {
        const newAnalysis = {...analysis};
        const analysisNames = values.analyses.map(analysis => analysis.name);
        let name = newAnalysis.name;
        let number = 1;
        while (analysisNames.includes(name)) {
            name = newAnalysis.name + ` (${number})`;
            number += 1;
        }
        newAnalysis.name = name;
        newAnalysis.id = uuidv4();
        arrayHelpers.push(newAnalysis, values.analyses.length);
        setTimeout(() => {
            if (formikRef?.current)
                props.setAnalyses(formikRef.current.values.analyses);
        }, 10);
        props.analysesResults.push(createEmptyAnalysisResults(newAnalysis.id));
        props.setAnalysesResults(props.analysesResults);
    }

    return (submitted ? <Redirect push to={props.nextPage} /> : 
        <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            // validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={(values) => {
                props.setSeries(values.series);
                props.setCharts(values.charts);
                setSubmitted(true);
            }}
            enableReinitialize
        >
        { ({ values, isValid }) => 
            <Form>
                <FieldArray
                    name="analyses"
                    render={arrayHelpers => (
                        <>
                            {
                                values.analyses && values.analyses.length > 0 ? (
                                    <>
                                        {
                                            values.analyses.map((analysis, index) => {
                                                return (
                                                    <AnalysisSubForm {...props} key={index} analysesResults={props.analysesResults} setAnalysesResults={props.setAnalysesResults} name="analyses" index={index} 
                                                        deleteHandler={() => {
                                                            arrayHelpers.remove(index);
                                                            setTimeout(() => {
                                                                if (formikRef?.current)
                                                                    props.setAnalyses(formikRef.current.values.analyses);
                                                            }, 10);
                                                            props.analysesResults.splice(index,1);
                                                            props.setAnalysesResults(props.analysesResults);
                                                        }}
                                                        duplicateHandler={
                                                            values.analyses && values.analyses.length < maxNumberOfAnalyses ?
                                                            () => duplicateHandler(analysis, values, arrayHelpers) :
                                                            undefined
                                                        }
                                                    />
                                                )
                                            })
                                        }
                                        {
                                            values.analyses && values.analyses.length < maxNumberOfAnalyses &&
                                            <Button type="button" onClick={() => {
                                                    const analysis = createEmptyAnalysis(values.analyses.length);
                                                    arrayHelpers.push(analysis);
                                                    setTimeout(() => {
                                                        if (formikRef?.current)
                                                            props.setAnalyses(formikRef.current.values.analyses);
                                                    }, 10);
                                                    props.analysesResults.push(createEmptyAnalysisResults(analysis.id));
                                                    props.setAnalysesResults(props.analysesResults);
                                                    // props.setAnalyses(values.analyses);
                                            }}>+</Button>
                                        }
                                        <Button 
                                            type="button"
                                            style={{float: "left", marginRight: 20}}
                                            onClick={() => {
                                                setTimeout(() => {
                                                    if (formikRef?.current)
                                                        props.setAnalyses(formikRef.current.values.analyses);
                                                    history.push(props.prevPage);
                                                }, 10);
                                            }}>
                                                Voltar
                                        </Button>
                                        <Button type="button" 
                                                style={{float: "right", marginLeft: 20}}
                                                onClick={()=> {
                                                    setTimeout(() => {
                                                        if (formikRef?.current)
                                                            props.setAnalyses(formikRef.current.values.analyses);
                                                        history.push(props.nextPage);
                                                    }, 10);
                                                }}>
                                            Visualizar resultados
                                        </Button>
                                        <Button type="button" 
                                                disabled={canRunAnalyses()}
                                                style={{float: "right"}}
                                                onClick={()=>runPossibleAnalysis()}>
                                            Rodar todas análises
                                        </Button>
                                    </>
                                ) : (
                                    <Button type="button" onClick={() => {
                                        const analysis = createEmptyAnalysis(0);
                                        arrayHelpers.push(analysis);
                                        setTimeout(() => {
                                            if (formikRef?.current)
                                                props.setAnalyses(formikRef.current.values.analyses);
                                        }, 10);
                                        props.analysesResults.push(createEmptyAnalysisResults(analysis.id));
                                        props.setAnalysesResults(props.analysesResults);
                                    }}>
                                        Criar nova análise
                                    </Button>
                                )
                            }
                        </>
                    )} 
                />
            </Form>
        }
        </Formik>
    );
}

const mapStateToProps = state => {
    return {
        series: state.series,
        analyses: state.analyses,
        analysesResults: state.analysesResults,
        rerender: state.rerender
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setAnalyses: (analyses) => dispatch({type: actionTypes.STORE_ANALYSES, analyses: analyses}),
        setAnalysesResults: (results) => dispatch({type: actionTypes.STORE_ANALYSES_RESULTS, analysesResults: results}),
        triggerRerender: () => dispatch({type: actionTypes.TRIGGER_RERENDER}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAnalysis);
