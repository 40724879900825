import React, { useState } from 'react';
import { connect, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import Button from 'components/UI/Button';
import Card from 'components/UI/Card';

const ARIMASubForm = (props) => {
    const [modelsInitialState, setModelsInitialState] = useState([...props.formik.values.analyses[props.analysisIndex].models]);
    const modelIndex = props.formik.values.analyses[props.analysisIndex].models.findIndex(model => {
        return model.name === "ARIMA"
    });
    const model = props.formik.values.analyses[props.analysisIndex].models[modelIndex];
    const fieldPrefix = `analyses[${props.analysisIndex}].models[${modelIndex}].`;
    
    return (
        <>
            <h2>Configuração do modelo ARIMA</h2>
            <Card>
                <label className="first-label" htmlFor={fieldPrefix + "auto"}>Auto ARIMA</label>
                <Field name={fieldPrefix + "auto"} type="checkbox" disabled={props.disabled}/>
                <ErrorMessage name={fieldPrefix + "auto"} component="div" className={"error"} />

                {
                    model.auto ?
                    <div>
                        <label htmlFor={fieldPrefix + "searchOption"}>Modo de busca de parâmetros</label>
                        <Field name={fieldPrefix + "searchOption"} as="select" disabled={props.disabled}>
                            <option value="stepwise">Stepwise</option>
                            <option value="fullsearch">Busca exaustiva</option>
                        </Field>
                        <ErrorMessage name={fieldPrefix + "searchOption"} component="div" className={"error"} />
                    </div> : 
                    <div className="AdvancedParametersContainer">
                        <div className="AdvancedParametersItem">
                            <label htmlFor={fieldPrefix + "p"}>p</label>
                            <Field name={fieldPrefix + "p"} type="number" disabled={props.disabled}/>
                            <ErrorMessage name={fieldPrefix + "p"} component="div" className={"error"} />
                        </div>

                        <div className="AdvancedParametersItem">
                            <label htmlFor={fieldPrefix + "P"}>P</label>
                            <Field name={fieldPrefix + "P"} type="number" disabled={props.disabled}/>
                            <ErrorMessage name={fieldPrefix + "P"} component="div" className={"error"} />
                        </div>

                        <div className="AdvancedParametersItem">
                            <label htmlFor={fieldPrefix + "d"}>d</label>
                            <Field name={fieldPrefix + "d"} type="number" disabled={props.disabled}/>
                            <ErrorMessage name={fieldPrefix + "d"} component="div" className={"error"} />
                        </div>

                        <div className="AdvancedParametersItem">
                            <label htmlFor={fieldPrefix + "D"}>D</label>
                            <Field name={fieldPrefix + "D"} type="number" disabled={props.disabled}/>
                            <ErrorMessage name={fieldPrefix + "D"} component="div" className={"error"} />
                        </div>

                        <div className="AdvancedParametersItem">
                            <label htmlFor={fieldPrefix + "q"}>q</label>
                            <Field name={fieldPrefix + "q"} type="number" disabled={props.disabled}/>
                            <ErrorMessage name={fieldPrefix + "q"} component="div" className={"error"} />
                        </div>

                        <div className="AdvancedParametersItem">
                            <label htmlFor={fieldPrefix + "Q"}>Q</label>
                            <Field name={fieldPrefix + "Q"} type="number" disabled={props.disabled}/>
                            <ErrorMessage name={fieldPrefix + "Q"} component="div" className={"error"} />
                        </div>
                    </div>
                }
            </Card>
            
            <div>
                <Button type="button" onClick={() => {
                    props.closeModal();
                }}>Confirmar</Button>
                <Button type="button" style={{marginLeft: 30}} onClick={() => {
                    props.formik.setFieldValue(`analyses[${props.analysisIndex}].models`, modelsInitialState);
                    props.closeModal();
                }}>Cancelar</Button>
            </div>
        </>
    );
};

export default connect(ARIMASubForm);