import React, { useState } from 'react';
import { connect, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import series from 'assets/examples/series.csv';
import Modal from 'components/UI/Modal';
import SeriesIndividualVisualization from 'components/UI/SeriesIndividualVisualization';
import Button from 'components/UI/Button';
import SeriesTable from 'components/UI/SeriesTable';
import * as actionTypes from 'store/actions';
import { calculateStartDate } from 'components/UI/FormikCalendar';
import { connect as connectRedux } from 'react-redux';
import moment from 'moment';
import { useEffect } from 'react';

export const validation = yup.object().shape({
    series: yup
        .object()
        .required("Arquivo CSV da série é obrigatório.")
})

const DependentSeriesSelectSubForm = (props) => {
    let [csvData, setCsvData] = useState("");
    const currentAnalysis = props.formik.values.analyses[props.index];
    const [selectedSeries, setSelectedSeries] = useState([...currentAnalysis.dependentSeries]);

    const selectedSeriesIds = selectedSeries.map(s => s.seriesMeta.series_id);
    const selectedArray = props.series.map(s => selectedSeriesIds.includes(s.seriesMeta.series_id));

    const falseArray = [...Array(props.series.length)].map(_ => false);
    const [checked, setChecked] = useState(selectedArray);
    const [visualizeSeries, setVisualizeSeries] = useState(null);
    const [loading, setLoading] = useState(false);
    const dependentSeriesGranularity = currentAnalysis.dependentSeries.length > 0 ? currentAnalysis.dependentSeries[0].seriesMeta.granularity : { granularity: 10 };
    const [predAndEstDates, setPredAndEstDates] = useState({
        granularity: dependentSeriesGranularity,
        startEstimation: currentAnalysis.startEstimation,
        endEstimation: currentAnalysis.endEstimation,
        endForecast: currentAnalysis.endForecast
    });

    useEffect(() => {
        setPredAndEstDates({
            granularity: dependentSeriesGranularity,
            startEstimation: currentAnalysis.startEstimation,
            endEstimation: currentAnalysis.endEstimation,
            endForecast: currentAnalysis.endForecast
        });
    }, [currentAnalysis.startEstimation, currentAnalysis.endEstimation, currentAnalysis.endForecast]);

    fetch(series).then((response) => {
        return response.text();
    }).then((data) => {
        setCsvData(data);
    });

    const toggleSelection = (id) => {
        const newSelectedSeries = props.series.find(series => series.seriesMeta.series_id === id);
        setSelectedSeries([newSelectedSeries]);

        let findGeneral = props.series.findIndex(series => series.seriesMeta.series_id === id);
        let checkedArray = [...falseArray];
        checkedArray[findGeneral] = true;
        setChecked(checkedArray);

        props.triggerRerender();
    }

    return (
        <>
            <h2>{props.title}</h2>
            <p>Selecione uma série dependente</p>
            <SeriesTable title="Séries disponíveis" algorithmDates={predAndEstDates} radioSelect={toggleSelection} checked={checked} seriesArray={props.series} setVisualizeSeries={setVisualizeSeries}></SeriesTable>
            <div>
                <Button type="button" onClick={() => {
                    let currentValues = props.formik.values.analyses[props.index][props.name];
                    props.formik.setFieldValue(`analyses[${props.index}][${props.name}]`, [...selectedSeries]);

                    const dependentSeries = selectedSeries[0];
                    props.formik.setFieldValue(`analyses[${props.index}][startEstimation]`, moment(dependentSeries.seriesMeta.start_date).toDate());
                    props.formik.setFieldValue(`analyses[${props.index}][endEstimation]`, moment(dependentSeries.seriesMeta.end_date).toDate());
                    props.formik.setFieldValue(`analyses[${props.index}][endForecast]`, calculateStartDate(moment(dependentSeries.seriesMeta.end_date).toDate(), dependentSeries.seriesMeta.granularity.granularity, true));
                    setTimeout(() => {
                        setLoading(false);
                        props.formik.validateForm();
                        setSelectedSeries([]);
                        setChecked(selectedArray);
                        props.closeModal();
                    }, 10);
                }}>Confirmar</Button>
                <Button type="button" style={{marginLeft: 30}} onClick={() => {
                    setSelectedSeries([]);
                    setChecked(selectedArray);
                    setPredAndEstDates(predAndEstDates);
                    props.closeModal();
                }}>Cancelar</Button>
            </div>
            {
                <Modal className="Modal BigModal" show={visualizeSeries !== null} modalClosed={() => setVisualizeSeries(null)}>
                    {
                        visualizeSeries && <SeriesIndividualVisualization seriesMeta={visualizeSeries} />
                    }
                </Modal>
            } 
        </>
    );
};

const mapStateToProps = state => {
    return {
        rerender: state.rerender
    }
};

const mapDispatchToProps = dispatch => {
    return {
        triggerRerender: () => dispatch({type: actionTypes.TRIGGER_RERENDER}),
    }
}

export default connectRedux(mapStateToProps, mapDispatchToProps)(connect(DependentSeriesSelectSubForm));