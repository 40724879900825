import api, { localAPI } from 'apis/api';

function postCSV(name, file, completion, onFailure) {
    let formData = new FormData();
    formData.append(name, file)
    api.post('upload_csv/', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then(response => {
        completion(response.data);
    }).catch(error => {
        if (error?.response?.data) {
            const message = `Erro: ${error.response.data}`
            onFailure(message);
        }
    });
}

function postInput(input, completion, onFailure) {
    localAPI.post('forecast/', input, {
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(response => {
        completion(response.data);
    }).catch(error => {
        if (error?.response?.data) {
            const message = `Erro: ${error.response.data}`
            onFailure(message);
        }
    });
}

function warmupAlgorithm() {
    api.post('algorithms/', {});
}

export {
    postCSV,
    postInput,  
    warmupAlgorithm
};
