import { FieldProps } from 'formik'
import React from 'react'
import Select, { Option, ReactSelectProps } from 'react-select'
import CreatableSelect from 'react-select/creatable'

const customStyles = {
  control: (provided, state) => ({ 
    ...provided, 
    borderRadius: "20px",
    padding: "5px 5px",
    boxSizing: "border-box",
    color: "rgb(99, 99, 99, 1)",
    fontSize: "medium"
  })
}

const SelectField = ({
  options,
  field,
  form,
  props,
}) => {
  const fieldValueNames = field.value.map(el => el.name);
  const opt = options.filter(option => fieldValueNames.includes(option.value.name));
  
  return (
    <CreatableSelect
      options={options}
      styles={customStyles}
      placeholder={props.placeholder}
      name={field.name}
      value={props.models ? opt : undefined}
      onChange={(options) => {
          const values = options.map(option => option.value);
          form.setFieldValue(field.name, values)
      }}
      onBlur={field.onBlur}
      isMulti
    />
  );
}

export default SelectField;