import { defaultOptions, series_as_list, jsonDataToCSV, ChartTitle } from './EChartsHelper';
import ReactECharts from 'echarts-for-react';
import * as moment from 'moment';

// Forecast plot
// props: historic, forecast, lower80, upper80, lower95, upper95
const ForecastPlot = (props) => {
    const opt = {...defaultOptions(props), ...{
        series: [
            {
                name: 'Intervalo de previsão 95%',
                type: 'line',
                data: series_as_list(props.upper95.series),
                z: -1,
                lineStyle: {
                    type: 'dashed',
                    color: '#ccc'
                },
                itemStyle: {
                    color: '#ccc'
                },
                areaStyle: {
                    color: '#ccc',
                },
                symbol: 'none'
            }, 
            {
                name: 'Intervalo de previsão 80%',
                type: 'line',
                data: series_as_list(props.upper80.series),
                z: -1,
                lineStyle: {
                    type: 'dashed',
                    color: '#aaa'
                },
                itemStyle: {
                    color: '#aaa'
                },
                areaStyle: {
                    color: '#aaa',
                },
                symbol: 'none'
            }, 
            {
                name: "Previsão",
                data: series_as_list(props.forecast.series),
                type: "line",
                showSymbol: false,
                lineStyle: {
                    color: 'rgba(254, 121, 56, 1)'
                },
                itemStyle: {
                    color: 'rgba(254, 121, 56, 1)'
                }
            }, 
            {
                name: 'Intervalo de previsão 80%',
                type: 'line',
                data: series_as_list(props.lower80.series),
                z: -1,
                lineStyle: {
                    type: 'dashed',
                    color: '#aaa'
                },
                itemStyle: {
                    color: '#aaa'
                },
                areaStyle: {
                    color: '#ddd',
                },
                symbol: 'none'
            },
            {
                name: 'Intervalo de previsão 95%',
                type: 'line',
                data: series_as_list(props.lower95.series),
                z: -1,
                lineStyle: {
                    type: 'dashed',
                    color: '#ccc'
                },
                itemStyle: {
                    color: '#ccc'
                },
                areaStyle: {
                    color: 'white',
                    opacity: 1,
                },
                symbol: 'none'
            },
            {
                name: "Histórico",
                data: series_as_list(props.historic.series),
                type: "line",
                showSymbol: false,
                lineStyle: {
                    color: 'rgba(0, 11, 113, 1)'
                },
                itemStyle: {
                    color: 'rgba(0, 11, 113, 1)'
                },
            },
        ],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                animation: false,
                label: {
                    backgroundColor: '#ccc',
                    borderColor: '#aaa',
                    borderWidth: 1,
                    shadowBlur: 0,
                    shadowOffsetX: 0,
                    shadowOffsetY: 0,
                    color: '#222',
                    formatter: function(params) {
                        if (params["axisDimension"] === "x")
                            return moment(params["value"]).format('lll');
                        return params["value"].toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2});
                    }
                }
            },
            formatter: function(params) {
                const tooltipStrings = params.map(obj => obj.value ? `${obj.marker} ${obj.seriesName}: <strong>${obj.value[1].toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</strong><br />` : "").join("");
                return `${moment(params[0].axisValue).format("lll")}<br />` + tooltipStrings;
            }
        },
    }}
    return (
        <div style={{width: "100%"}}>
            <ChartTitle 
                title="Previsão" 
                // csvName="estatísticas_de_30_em_30_minutos_diariamente_da_série_completa.csv"
                // data={jsonDataToCSV([meanSeries, minSeries, maxSeries], ["Média", "Mínimo", "Máximo"])} 
                />
            <ReactECharts option={opt} 
                          style={{height: '300px'}} 
                          opts={{renderer: 'svg', 
                          locale: 'PTBR'}} >
            </ReactECharts>
        </div>
    );
}

export { ForecastPlot };