import React, { useState } from 'react';
import { connect, Field, ErrorMessage } from 'formik';
import { connect as connectRedux } from 'react-redux';
import * as yup from 'yup';
import FileUploader from 'components/UI/FileUploader';
import { postCSV } from 'helper/DataHelper';
import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';
import Card from 'components/UI/Card';
import series from 'assets/examples/series.csv';
import Info from 'components/UI/Info';
import Modal from 'components/UI/Modal';
import SeriesIndividualVisualization from 'components/UI/SeriesIndividualVisualization';
import Button from 'components/UI/Button';
import SeriesTable from 'components/UI/SeriesTable';
import { seriesCSVtoJSON } from 'apis/api';
import { useAuth0 } from '@auth0/auth0-react';
import * as actionTypes from 'store/actions';
import ExampleCSVComponent from 'components/UI/ExampleCSVComponent';
import { mapGranularityName } from 'components/Plots/EChartsHelper';

export const validation = yup.object().shape({
    series: yup
        .object()
        .required("Arquivo CSV da série é obrigatório.")
})

const SeriesInputSubForm = (props) => {
    let [csvData, setCsvData] = useState("");
    const [importedSeries, setImportedSeries] = useState([]);
    const [visualizeSeries, setVisualizeSeries] = useState(null);
    const [importedFiles, setImportedFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openExampleCSV, setOpenExampleCSV] = useState(false);
    const { user } = useAuth0();

    fetch(series).then((response) => {
        return response.text();
    }).then((data) => {
        setCsvData(data);
    });
    
    const checkImportedSeries = (series) => {
        // if (importedSeries.length === 0) { return };
        const options = {
            autoClose: false,
            hideProgressBar: true,
            position: toast.POSITION.TOP_RIGHT,
        };

        function onlyUnique(value, index, self) {
            return self.indexOf(value) === index;
        }

        let unsupportedFiles = [];
        series = series.filter(el => {
            if (el.seriesMeta.granularity.granularity < 2) {
                unsupportedFiles.push(el.seriesMeta.origin_file);
                return false;
            }
            return true;
        });
        unsupportedFiles = unsupportedFiles.filter(onlyUnique);
        // toast.error(`A série ${el.seriesMeta.name} possui granularidade ${mapGranularityName(el.seriesMeta.granularity.granularity).toLowerCase()} a qual não é suportada nessa versão`,options);
        unsupportedFiles.forEach(file => {
            toast.error(`O arquivo ${file} possui séries de granularidade não suportada nessa versão. Apenas séries de granularidade diária ou maior são suportadas.`, options);
        })
        const series_ids = {};
        const seriesExtended = [...props.series, ...series]
        seriesExtended.forEach((el, i) => {
            if (series_ids[el.seriesMeta.series_id] === undefined) {
                series_ids[el.seriesMeta.series_id] = [i];
            } else {
                series_ids[el.seriesMeta.series_id].push(i);
            }
        });

        let remove_indexes = [];
        for (const [key, value] of Object.entries(series_ids)) {
            if (value.length > 1) {
                // mandar toast 
                const firstSeriesName = seriesExtended[value[0]].seriesMeta.name;
                value.slice(1).forEach(el => {
                    toast.warning(`Série ${seriesExtended[el].seriesMeta.name} é igual a série ${firstSeriesName} que já foi adicionada. Por isso, ela será removida.`, options);
                })

                remove_indexes.push.apply(remove_indexes, value.slice(1));
            }
        }
        // Deleting duplicate metas
        const importedIds = props.series.map(el => el.seriesMeta.series_id)
        return seriesExtended.filter((_,i) => !remove_indexes.includes(i)).filter(el => {
            return !importedIds.includes(el.seriesMeta.series_id);
        });
    }

    const seriesCompletion = (files) => {
        setImportedFiles([...importedFiles, ...files]);
        const errorOccurred = (message) => {
            const options = {
                autoClose: false,
                hideProgressBar: false,
                position: toast.POSITION.TOP_RIGHT,
            };
            toast.error(message, options);
        }

        const completion = (data) => {
            const newSeriesMeta = data.metadata.map((seriesMeta, i) => {
                const newSeriesMeta = {
                    seriesMeta: seriesMeta
                }
                return newSeriesMeta;
            });
            const seriesImported = checkImportedSeries([...importedSeries, ...newSeriesMeta]);
            setImportedSeries(seriesImported);
            setLoading(false);
            props.formik.validateForm();
        }

        // TODO: change to actual user
        seriesCSVtoJSON(files, "guest_user", props.study_id, completion, errorOccurred);
    }

    return (
        <>
            <h2>{props.title}</h2>
            <p>Insira um ou mais arquivos CSV que contenham uma ou mais séries cada.</p>
            <Card>
                <Button onClick={() => setOpenExampleCSV(true)} 
                        className="blue-button"
                        type="button"
                        style={{
                            float: "right", 
                            marginTop: "-10px", 
                            padding: '5px 20px', 
                            fontSize: 'small',
                            border: '1px solid rgba(0, 11, 67, 1)'
                        }}>
                    Exemplo CSV
                </Button>
                <div>
                    <label className="first-label" htmlFor="file">CSV da série*</label>
                    <Info><p style={{color: "white"}}>Formato:<br /> timestamp,values</p></Info>
                    <Field name="file" 
                            uploadName={props.name} 
                            component={FileUploader} 
                            singleFile={props.singleFile}
                            completion={seriesCompletion} />
                    <ErrorMessage name="file" component="div" className={"error"} />
                </div>
                {
                    importedFiles.length > 0 && 
                    <div>
                        <p>Arquivos importados:</p>
                        <ul>
                            {
                                importedFiles.map(file => <li key={file.name}>{file.name} - {file.size} bytes</li>)
                            }
                        </ul>
                    </div>
                }
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    {
                        loading['explanatory'] && <Loader 
                        type="Oval" 
                        color="rgba(0, 11, 67, 1)"
                        height={40} 
                        width={40}
                        style={{marginLeft: "20px", marginTop: "30px"}} />
                    }
                </div>
            </Card>
            <SeriesTable title="Séries a serem importadas" editable={true} seriesArray={importedSeries} setSeriesArray={setImportedSeries} setVisualizeSeries={setVisualizeSeries} />
            <div>
                <Button type="button" disabled={importedSeries.length === 0} onClick={() => {
                    let currentValues = props.formik.values[props.name];
                    props.formik.setFieldValue(props.name, [...currentValues, ...importedSeries]);
                    setTimeout(() => {
                        setLoading(false);
                        props.formik.validateForm();
                        props.setSeries([...props.series,...importedSeries]);
                        setImportedSeries([]);
                        setImportedFiles([]);
                        props.closeModal();
                    }, 10);
                }}>Importar</Button>
                <Button type="button" style={{marginLeft: 30}} onClick={() => {
                    setImportedSeries([]);
                    setImportedFiles([]);
                    props.closeModal();
                }}>Cancelar</Button>
            </div>
            {
                <Modal className="Modal BigModal" show={visualizeSeries !== null} modalClosed={() => setVisualizeSeries(null)}>
                    {
                        visualizeSeries && <SeriesIndividualVisualization seriesMeta={visualizeSeries} />
                    }
                </Modal>
            }
            <Modal className="Modal BigModal" style={{height: '80%'}} show={openExampleCSV} modalClosed={() => setOpenExampleCSV(false)}>
                <ExampleCSVComponent rerender={props.rerender} triggerRerender={props.triggerRerender} closeModal={() => setOpenExampleCSV(false)} />
            </Modal>
        </>
    );
};

const mapStateToProps = state => {
    return {
        study_id: state.study_id,
        series: state.series
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setSeries: (series) => dispatch({type: actionTypes.STORE_SERIES, new_series: series}),
    }
};

export default connectRedux(mapStateToProps, mapDispatchToProps)(connect(SeriesInputSubForm));